import React, { useContext } from 'react';
import { func } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Container, Col, Row, Button } from 'reactstrap';

// actions
import { resetCocPortal } from '../../../actions/cocPortalActions';

// reducers
import { Context } from '../../../reducers/store';
import { getDocument } from '../../../reducers/cocPortalReducer';

// components
import Header from './Header';
import DocumentDetails from './DocumentDetails';
import NameValidation from './NameValidation';
import SignatureInformation from './SignatureInformation/SignatureInformation';

const DocumentView = ({ t }) => {
  const [state, dispatch] = useContext(Context);
  const history = useHistory();
  const document = getDocument(state);

  // if no document in state, redirect to login page
  if (!Object.keys(document).length) {
    history.push('/verify');
  }

  const onExitClick = () => {
    resetCocPortal({ dispatch });
    history.push('/verify');
  };

  return (
    <>
      <Header />
      <Container fluid>
        <Col className="document-view">
          <Row className="header-text">
            <h5>{t('cocPortal:verificationInstructions')}</h5>
          </Row>
          <DocumentDetails />
          <NameValidation />
          <SignatureInformation />
          <Row className="button-row">
            <Button onClick={onExitClick} size="lg">
              {t('exit')}
            </Button>
          </Row>
        </Col>
      </Container>
    </>
  );
};

DocumentView.propTypes = {
  t: func.isRequired,
};

export default withTranslation()(DocumentView);
