import reducerTypes from './reducerTypes';
import cocPortalReducer from './cocPortalReducer';

const rootReducer = (state, action) => {
  switch (action.reducer) {
    case reducerTypes.cocPortal:
      return cocPortalReducer(state, action);
    default:
  }
};

export default rootReducer;
