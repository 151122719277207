import React, { Component } from 'react';

import { utils } from '@champtitles/utilities';

import './style.css';

class RadioButton extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  keyPress = (e) => {
    if (e.which === 32) {
      e.preventDefault();
      this.props.handler(this.props.index);
    }
  };

  handleClick = () => {
    if (!this.props.disableInputs) this.props.handler(this.props.index);
  };

  render() {
    return (
      <div
        className={'radio-btn-group '}
        onClick={this.handleClick}
        onKeyPress={this.keyPress}
      >
        <div
          className={
            (this.props.isChecked ? 'radiobtn checked' : 'radiobtn unchecked') +
            (this.props.disableInputs ? ' disabled' : '')
          }
          data-value={this.props.value}
          name={this.props.name}
          tabIndex={0}
        >
          {this.props.text}
        </div>
      </div>
    );
  }
}

class RadioButtonGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: null,
      selectedValue: null,
      options: props.options,
    };
  }

  toggleRadioBtn = (index) => {
    if (!this.props.disableInputs) {
      const dataset = utils.buildDataset(this.props);

      this.setState(
        {
          selectedIndex: index,
          selectedValue: this.state.options[index],
          options: this.state.options,
        },
        this.props.onChange({
          target: {
            value: this.state.options[index].toUpperCase(),
            name: this.props.validatedAttribute,
            dataset: {
              ...dataset,
            },
          },
        }),
      );
    }
  };

  render() {
    const { validated, validatedAttribute, details, name } = this.props;

    return (
      <div
        className={
          'radios-grouped ' +
          (validated[validatedAttribute] != null
            ? validated[validatedAttribute]
              ? ''
              : 'is-invalid'
            : '')
        }
      >
        {this.state.options.map((option, i) => {
          return (
            <RadioButton
              key={i}
              isChecked={
                this.state.selectedIndex === i ||
                option.toUpperCase() === details[validatedAttribute]
              }
              text={option}
              value={option}
              index={i}
              handler={this.toggleRadioBtn}
              validated={this.props.validated}
              disableInputs={this.props.disableInputs}
              name={name}
            />
          );
        })}
      </div>
    );
  }
}

export default RadioButtonGroup;
