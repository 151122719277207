import React, { Component } from 'react';
import { motion } from 'framer-motion';
import { Nav } from 'reactstrap';
import DefaultHeaderDropdown from './DefaultHeaderDropdown';

class DefaultHeader extends Component {
  render() {
    // eslint-disable-next-line
    const { t, children, logo, ...attributes } = this.props;
    const { action, delays } = logo;

    return (
      <>
        <div className="header-text-label">
          <motion.img
            src={logo.path}
            width="120"
            alt={t('insurerLogo')}
            className="header-logo center-absolute"
            initial={{ x: '-200%', y: '-50%', opacity: 0 }}
            animate={action.img.animate}
            transition={{ ...action.img.transition, delay: delays.img }}
          />
          <motion.div
            className="d-inline-block header-text"
            initial={{ x: 0 }}
            animate={action.label.animate}
            transition={{ ...action.label.transition, delay: delays.label }}
          >
            {t('header')}
          </motion.div>
        </div>
        <div className="clearfix">
          <Nav className="float-right" navbar>
            <DefaultHeaderDropdown accnt {...this.props} />
          </Nav>
        </div>
      </>
    );
  }
}

export default DefaultHeader;
