import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

class KeycloakSession extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };

    this.keycloak = props.keycloak;
  }

  checkExpiration = () => {
    const expires = this.keycloak.tokenParsed.exp * 1000 - new Date().getTime();

    if (!this.timer) {
      this.timer = setTimeout(() => {
        this.setState({ show: true });
      }, expires);
    }
  };

  lastCall = () => {
    const fullExpire = this.keycloak.refreshTokenParsed.exp * 1000;
    const refreshTimeLeft = fullExpire - new Date().getTime();

    this.timer2 = setTimeout(() => {
      this.keycloak.logout();
    }, refreshTimeLeft);
  };

  close = () => {
    this.keycloak.updateToken(-1).then((res) => {
      this.timer = clearTimeout(this.timer);
      this.timer2 = clearTimeout(this.timer2);
      this.setState({ show: false });
    });
  };

  shouldComponentUpdate() {
    if (
      !this.state.show &&
      !this.keycloak.isTokenExpired(this.keycloak.timeSkew)
    ) {
      this.keycloak.updateToken(-1).then((res) => {
        this.timer = clearTimeout(this.timer);
        this.timer2 = clearTimeout(this.timer2);
        this.checkExpiration();
      });
    } else {
      this.checkExpiration();
    }

    return true;
  }

  componentDidUpdate() {
    if (this.state.show) {
      this.lastCall();
    }
  }

  componentDidMount() {
    this.checkExpiration();
  }

  render() {
    const { t } = this.props;

    return (
      <Modal isOpen={this.state.show}>
        <ModalHeader>{t('keycloakSession:expireWarning')}</ModalHeader>
        <ModalBody>{t('keycloakSession:stayLoggedIn')}</ModalBody>
        <ModalFooter>
          <Button color="link" onClick={() => this.keycloak.logout()}>
            {t('logout')}
          </Button>
          <Button color="primary" onClick={() => this.close()}>
            {t('keycloakSession:keepMeHere')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default KeycloakSession;
