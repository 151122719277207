const ownerStepTransition = {
  actions: {
    animate: 'in',
    exit: 'out',
    initial: 'initial',
  },
  variants: {
    mainContainer: {
      initial: {
        x: 1000,
        opacity: 0,
      },
      in: {
        x: 0,
        opacity: 1,
        transition: {
          x: { stiffness: 1, velocity: 0.1 },
          duration: 0.5,
        },
      },
      out: {
        x: -1000,
        opacity: 0,
        transition: {
          x: { stiffness: 1, velocity: 0.1 },
          delay: 0.15,
        },
      },
    },
  },
};

const ownerStepContainer = {
  actions: {
    animate: 'open',
    exit: 'closed',
    initial: 'closed',
  },
  variants: {
    mainContainer: {
      open: {
        y: 0,
        opacity: 1,
        transition: {
          y: { stiffness: 1, velocity: 0.1 },
        },
      },
      closed: {
        y: 50,
        opacity: 0,
      },
    },
    table: {
      open: {
        y: 0,
        opacity: 1,
        transition: {
          y: { stiffness: 1, velocity: 0.1 },
        },
        scale: 1,
      },
      closed: {
        y: 50,
        opacity: 0,
        transition: {
          y: { stiffness: 1 },
        },
        scale: 0.8,
      },
    },
  },
};

const esignStepTransition = {
  actions: {
    animate: 'in',
    exit: 'out',
    initial: 'initial',
  },
  variants: {
    mainContainer: {
      initial: {
        opacity: 0,
      },
      in: {
        opacity: 1,
        transition: {
          duration: 0.5,
        },
      },
      out: {
        opacity: 0,
        transition: {
          duration: 0.5,
        },
      },
    },
  },
};

const documentStepTransition = {
  actions: {
    animate: 'in',
    exit: 'out',
    initial: 'initial',
  },
  variants: {
    mainContainer: {
      initial: {
        x: 1000,
        opacity: 0,
      },
      in: {
        x: 0,
        opacity: 1,
        transition: {
          x: { stiffness: 1, velocity: 0.1 },
          duration: 0.5,
        },
      },
      out: {
        x: -1000,
        opacity: 0,
        transition: {
          x: { stiffness: 1, velocity: 0.1 },
          delay: 0.15,
        },
      },
    },
  },
};

const buttons = {
  hover: { scale: 0.95 },
  tap: { scale: 0.85 },
};

export {
  ownerStepContainer,
  ownerStepTransition,
  esignStepTransition,
  buttons,
  documentStepTransition,
};
