import OwnerRequestContainer from './views/OwnerRequestContainer';

function routes(t) {
  return [
    {
      path: '/claims',
      name: t('nav:pending'),
      component: OwnerRequestContainer,
      exact: true,
      access: ['ROLE_USER'],
    },
  ];
}

export default routes;
