import React, { Component, Fragment } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { ErrorModal } from '../components';

import VehicleClaimTile from './VehicleClaimTile';
import OwnerStepContainer from './PendingRequests/OwnerSteps/OwnerStepContainer';
import CompletedRequestDetails from './CompletedRequests/CompletedRequestDetails';

import { PageLoader, Tiles } from '../hooks/components';

class OwnerRequestContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTransferAgreement: null,
      isLoading: true,
      transferAgreements: [],
      checkForClaims: false,
    };
    this.auth = props.auth;
    this.keycloak = props.keycloak;
    this.intervalID = null;
  }

  selectTransferAgreement = (selected) => {
    localStorage.setItem('scrollY', window.scrollY);
    this.setState(
      {
        selectedTransferAgreement: selected,
      },
      this.props.updateHeaderLogo(
        selected.branding.brandingAssets.find(
          (asset) => asset.name && asset.name.toLowerCase() === 'insurerlogo',
        ).s3FileName,
      ),
    );
  };

  cancel = () => {
    this.setState(
      {
        selectedTransferAgreement: null,
        isLoading: true,
      },
      () => {
        this.props.updateHeaderLogo('');
        this.getUpdatedList();
      },
    );
  };

  getUpdatedList = async () => {
    try {
      const transferAgreements = await this.auth.fetch(`/transferagreements`);

      this.setState(
        {
          transferAgreements,
          isLoading: false,
        },
        () => {
          if (this.state.checkForClaims && transferAgreements.length) {
            this.stopClaimCheck();
          } else if (!this.state.checkForClaims && !transferAgreements.length) {
            this.startClaimCheck();
          }
        },
      );
      this.scrollToLastPosition();
      return transferAgreements;
    } catch {
      this.setState({ loadError: true });
    }
  };

  startClaimCheck = () => {
    this.intervalID = setInterval(this.checkClaims, 30000);
    this.setState({ checkForClaims: true });
  };

  stopClaimCheck = () => {
    clearInterval(this.intervalID);
    this.setState({ checkForClaims: false });
  };

  scrollToLastPosition = () => {
    const lastScrollPos = localStorage.getItem('scrollY');

    if (lastScrollPos) {
      window.scroll(0, lastScrollPos);
      localStorage.removeItem('scrollY');
    }
  };

  checkClaims = async () => {
    if (!this.keycloak.isTokenExpired()) {
      const transferAgreements = await this.getUpdatedList();

      if (transferAgreements && transferAgreements.length === 1) {
        this.selectTransferAgreement(transferAgreements[0]);
      }
    }
  };

  async componentDidMount() {
    this.checkClaims();
  }

  render() {
    const { t } = this.props;

    const {
      isLoading,
      loadError,
      transferAgreements,
      selectedTransferAgreement,
    } = this.state;

    if (loadError) return <ErrorModal />;
    if (isLoading) return <PageLoader />;

    return (
      <div>
        {!selectedTransferAgreement ? (
          <Fragment>
            {transferAgreements.length ? (
              <Tiles>
                {transferAgreements.map((transferAgreement, idx) => (
                  <VehicleClaimTile
                    {...this.props}
                    key={idx}
                    t={t}
                    transferAgreement={transferAgreement}
                    action={this.selectTransferAgreement}
                  />
                ))}
              </Tiles>
            ) : (
              <Row noGutters className="d-flex justify-content-center">
                <Col className="step-card no-titles">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col className="text-center font-weight-bold">
                          <div className="detail-header">
                            {t('noClaimsToShow')}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Fragment>
        ) : (
          <Fragment>
            {!selectedTransferAgreement.ownerReviewCompleteDate ? (
              <OwnerStepContainer
                {...this.props}
                transferAgreement={selectedTransferAgreement}
                cancel={this.cancel}
              />
            ) : (
              <CompletedRequestDetails
                {...this.props}
                transferAgreement={selectedTransferAgreement}
                cancel={this.cancel}
              />
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

export default OwnerRequestContainer;
