import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';

import UploadList from './UploadList';
import UploadModal from './UploadModal';

import { ErrorModal } from '../../../../components';

import {
  ActionButton,
  PageLoader,
  withOpenCv,
} from '../../../../hooks/components';

class UploadsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingCv: !props.cv,
      documents: [],
      currentDocument: null,
      showUploadModal: false,
      uploadedAttachments: [],
      isLoading: true,
      dataError: false,
      verifiedDocuments: [],
      showDeleteWarning: false,
    };

    this.auth = props.auth;
  }

  addVerifiedDocument = (document) => {
    let { verifiedDocuments } = this.state;

    verifiedDocuments.push(document.attachmentType);

    this.setState({
      verifiedDocuments,
    });
  };

  removeVerifiedDocument = (document) => {
    let { verifiedDocuments } = this.state;
    const filteredVerified = verifiedDocuments.filter(
      (verifiedDocument) => verifiedDocument !== document.attachmentType,
    );

    this.setState({
      verifiedDocuments: filteredVerified,
    });
  };

  setCurrentDocument = (document) => {
    this.setState({
      currentDocument: document,
      showUploadModal: true,
    });
  };

  updateUploadedAttachments = (uploads) => {
    this.setState({
      uploadedAttachments: uploads,
    });
  };

  closeUploadModal = () => {
    this.setState({
      showUploadModal: false,
      showDeleteWarning: false,
    });
  };

  continue = () => {
    this.props.updateStep();
  };

  previous = () => {
    this.props.previousStep();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.cv !== this.props.cv) {
      this.setState({
        isLoadingCv: false,
      });
    }
  }

  async componentDidMount() {
    const { transferAgreement, documents } = this.props;
    const filteredDocuments = documents.filter(
      (document) => document.type === 'UPLOAD',
    );
    try {
      const attachments = await this.auth.fetch(
        `/transferagreements/${transferAgreement.id}/attachments`,
      );

      let uploadedAttachments = attachments.reduce((acc, attachment) => {
        const found = filteredDocuments.find(
          (document) => attachment.attachmentType === document.attachmentType,
        );
        if (found) acc.push(attachment);
        return acc;
      }, []);

      this.setState({
        documents: filteredDocuments,
        currentDocument: filteredDocuments.length ? filteredDocuments[0] : null,
        uploadedAttachments,
        isLoading: false,
      });
    } catch (e) {
      this.setState({
        dataError: true,
      });
    }
  }

  render() {
    const { t } = this.props;

    const {
      documents,
      showUploadModal,
      currentDocument,
      uploadedAttachments,
      dataError,
      isLoading,
      verifiedDocuments,
      isLoadingCv,
    } = this.state;

    const isCompleted = documents.length === uploadedAttachments.length;

    if (dataError) return <ErrorModal />;
    if (isLoading || isLoadingCv) return <PageLoader />;

    return (
      <div id="uploadContainer">
        <Row>
          <Col sm="12" md={{ size: 8, offset: 2 }}>
            <Card>
              <CardBody>
                <UploadList
                  {...this.props}
                  documents={documents}
                  uploadedAttachments={uploadedAttachments}
                  setCurrentDocument={this.setCurrentDocument}
                />
                <Row className="mt-3 text-left">
                  <Col xl={{ size: 10, offset: 1 }}>
                    <span className="font-weight-bold">
                      {' '}
                      {t('common:note')}:{' '}
                    </span>
                    {t('documentUploadInstructions')}
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="text-center">
                    <Button color="link" onClick={this.props.cancel}>
                      {t('buttons:cancel')}
                    </Button>
                    <Button
                      color="secondary"
                      className="m-2"
                      onClick={this.previous}
                    >
                      {t('buttons:previousStep')}
                    </Button>
                    <ActionButton
                      className="btn-primary"
                      onClick={this.continue}
                      disabled={!isCompleted}
                    >
                      {t('buttons:continue')}
                    </ActionButton>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {showUploadModal && (
          <UploadModal
            {...this.props}
            documents={documents}
            uploadedAttachments={uploadedAttachments}
            close={this.closeUploadModal}
            currentDocument={currentDocument}
            updateUploadedAttachments={this.updateUploadedAttachments}
            isVerified={
              !!verifiedDocuments.find(
                (verified) => verified === currentDocument.attachmentType,
              )
            }
            removeVerifiedDocument={this.removeVerifiedDocument}
            addVerifiedDocument={this.addVerifiedDocument}
          />
        )}
      </div>
    );
  }
}

export default withTranslation('titleUpload')(withOpenCv(UploadsContainer));
