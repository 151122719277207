import i18next from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { uploadComponentEN } from '@champtitles/upload';
import commonEN from './locales/en/common.json';
import translationEN from './locales/en/translation.json';
import policyEN from './locales/en/policy.json';

// the translations
const resources = {
  en: {
    ...translationEN,
    common: commonEN,
    policy: policyEN,
    uploadComponent: uploadComponentEN,
  },
};

i18next
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: ['common'],
    defaultNS: 'common',
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      wait: true,
      withRef: true,
    },
  });

export default i18next;
