removeInfoConsoleLogs();

function noop() {}

function removeInfoConsoleLogs() {
  console.info = noop;
}

const { REACT_APP_API_URL,
  REACT_APP_API_PORT,
  REACT_APP_API_ENTRY_POINT,
  REACT_APP_API_VERSION,
  REACT_APP_KC_REALM,
  REACT_APP_KC_URL,
  REACT_APP_KC_CLIENTID } = window['runConfig'];

export const API_ROOT = `${REACT_APP_API_URL}${REACT_APP_API_PORT ? ':'+REACT_APP_API_PORT : ''}${REACT_APP_API_ENTRY_POINT}${REACT_APP_API_VERSION}`;

export const ENV = REACT_APP_KC_CLIENTID;

export const KC_CONFIG = {
	"realm": REACT_APP_KC_REALM,
	"url": REACT_APP_KC_URL,
	"clientId": REACT_APP_KC_CLIENTID,
	"enable-cors" : true, 
	"cors-max-age" : 10000, 
	"cors-allowed-methods" : "POST, PUT, DELETE, GET, HEAD", 
	"cors-allowed-headers" : "Access-Control-Allow-Origin, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
	"always-refresh-token": true
};
