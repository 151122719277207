import React from 'react';
import PropTypes, { node, object, oneOfType, string } from 'prop-types';
import { motion } from 'framer-motion';

import { ResponseAnimations } from '../../../components';

import LoadingSpinner from './LoadingSpinner';

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  submitSuccess: PropTypes.bool,
  submitError: PropTypes.bool,
  disabled: PropTypes.bool,
  children: oneOfType([node, string, object]),
  className: oneOfType([string, object]),
};

ActionButton.defaultProps = {
  isLoading: false,
  submitSuccess: false,
  submitError: false,
  disabled: false,
};

export default function ActionButton(props) {
  const statusClasses = {
    submitSuccess: ' checkmark-visible btn-outline-success background-white',
    submitError: ' cross-visible btn-outline-danger background-white',
    isLoading: ' spinner-visible background-white border-white',
    showLabel: ' label-visible',
  };

  const checkDisabled = () => {
    return (
      props.disabled ||
      props.isLoading ||
      props.submitSuccess ||
      props.submitError
    );
  };

  const getStatus = () => {
    return (
      (props.submitSuccess && 'submitSuccess') ||
      (props.submitError && 'submitError') ||
      (props.isLoading && 'isLoading') ||
      'showLabel'
    );
  };

  return (
    <motion.button
      id={props.id}
      className={
        'btn ' +
        props.className +
        ' action-button animated-scale' +
        statusClasses[getStatus()]
      }
      onClick={props.onClick}
      disabled={checkDisabled()}
      whileHover={
        checkDisabled()
          ? { scale: 1 }
          : props.whileHover
          ? props.whileHover
          : { scale: 0.95 }
      }
      whileTap={props.whileTap}
    >
      <span className="show-spinner">
        <LoadingSpinner isLoading={props.isLoading} />
      </span>
      <span className="show-check">
        <ResponseAnimations.ButtonSuccess
          showSuccess={props.submitSuccess}
          onAnimationComplete={props.onSubmitComplete}
        />
      </span>
      <span className="show-cross">
        <ResponseAnimations.ButtonError
          showError={props.submitError}
          onAnimationComplete={props.onSubmitComplete}
        />
      </span>
      <span className="show-label">{props.children}</span>
    </motion.button>
  );
}
