import React, { Component } from 'react';
import { motion } from 'framer-motion';

import { Button, Card, CardBody, Col, Row } from 'reactstrap';

import { Trans } from 'react-i18next';

import { SignatureHelpModal } from '../../../components';

import { ownerStepTransition, buttons } from '../pendingRequestsPageAnimations';

const { actions, variants } = ownerStepTransition;

const { hover, tap } = buttons;

class LandingPage1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showHelpModal: false,
    };

    this.auth = props.auth;
  }

  showHelpModal = () => {
    this.setState({
      showHelpModal: true,
    });
  };

  closeHelpModal = () => {
    this.setState({
      showHelpModal: false,
    });
  };

  render() {
    const { trackerSteps, t } = this.props;
    const { showHelpModal } = this.state;

    return (
      <motion.div
        id="landingPage1"
        {...actions}
        variants={variants.mainContainer}
      >
        <Row noGutters className="d-flex justify-content-center">
          <Col className="step-card">
            <Card>
              <CardBody className="landing-card-body px-lg-2 px-xl-5">
                <Row className="my-2" noGutters>
                  <Col>
                    <p>{t('landingPage:stepInstructions')}</p>
                    <p className="font-weight-bold mb-1 ml-3">
                      <span>1. </span>
                      {t(`landingPage:steps.verifyId`)}
                    </p>
                    {trackerSteps.map((step, idx) => (
                      <p
                        key={idx}
                        className={
                          'font-weight-bold mb-1 ml-3' +
                          (idx === trackerSteps.length - 1
                            ? ' d-inline-block'
                            : '')
                        }
                      >
                        <span>{`${idx + 2}. `}</span>
                        {t(`landingPage:steps.${step.title}`)}
                      </p>
                    ))}
                    <Trans i18nKey="landingPage:additionalInstructions">
                      <p className="mt-3"> </p>
                      <p> </p>
                      <p> </p>
                    </Trans>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <Button color="link" onClick={this.props.cancel}>
                      {t('buttons:cancel')}
                    </Button>
                    <motion.button
                      className="btn btn-primary px-4"
                      onClick={this.props.loadNextPage}
                      whileHover={hover}
                      whileTap={tap}
                    >
                      {t('buttons:next')}
                    </motion.button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <SignatureHelpModal
          isOpen={showHelpModal}
          close={this.closeHelpModal}
          t={t}
        />
      </motion.div>
    );
  }
}

export default LandingPage1;
