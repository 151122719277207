import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { utils } from '../../../../../components';

import { PageLoader } from '../../../../../hooks/components';

import DocumentSign from './DocumentSign';

import { documentStepTransition } from '../../../pendingRequestsPageAnimations';

const { actions, variants } = documentStepTransition;

const FONT_SIZE_SM = '1.5rem';
const FONT_SIZE_LG = '2rem';

const DocumentStepsContainer = (props) => {
  const [documents, setDocuments] = useState([]);
  const [transferAgreementDocumentId, setTransferAgreementDocumentId] =
    useState(null);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [documentStep, setDocumentStep] = useState(0);
  const [signaturePreview, setSignaturePreview] = useState(null);
  const [font, setFont] = useState({
    name: 'DANCING_SCRIPT',
    previewFont: 'font-dancing-script',
    maxChars: 50,
    fontSize: '2rem',
  });

  const getTransferAgreementDocumentId = (transferAgreement, documentSpec) => {
    var transferAgreementDocumentId;
    for (const taDoc of transferAgreement.attachments) {
      if (taDoc.documentGenerationId === documentSpec.documentGenerationId) {
        transferAgreementDocumentId = taDoc.id;
        break;
      }
    }
    return transferAgreementDocumentId;
  };

  const updateDocumentStep = () => {
    if (documentStep < documents.length - 1) {
      const { transferAgreement } = props;

      const nextDocumentStep = documentStep + 1;
      const nextDocument = documents[nextDocumentStep];
      nextDocument.isFinalDocument = nextDocumentStep === documents.length - 1;

      const nextTransferAgreementDocumentId = getTransferAgreementDocumentId(
        transferAgreement,
        nextDocument,
      );

      setCurrentDocument(nextDocument);
      setDocumentStep(nextDocumentStep);
      setTransferAgreementDocumentId(nextTransferAgreementDocumentId);
    } else {
      props.updateStep();
    }
  };

  const getFontSize = (txt, font) => {
    if (txt.length > font.maxChars) {
      return FONT_SIZE_SM;
    } else {
      return FONT_SIZE_LG;
    }
  };

  const getPreview = (preview, previewFont) => {
    if (!previewFont) previewFont = font;
    const fontSize = getFontSize(preview, previewFont);

    setSignaturePreview(preview);
    setFont({ ...previewFont, fontSize });
  };

  useEffect(() => {
    const { documents, transferAgreement } = props;
    let filteredDocuments = documents.filter(
      (document) => document.type === 'SIGNED_FORM',
    );

    if (utils.getValue('owner.ordinal', transferAgreement) > 0) {
      filteredDocuments = filteredDocuments.map((document) => {
        const signatureFields = utils
          .getValue('documentSignatureSpecs', document)
          .reduce((acc, cur) => {
            if (!acc[cur.field]) {
              acc[cur.field] = [];
            }
            acc[cur.field].push(cur);
            return acc;
          }, {});

        const ownerSignatureSpecIdx =
          utils.getValue('owner.ordinal', transferAgreement) - 1;

        const signatureSpecs = Object.keys(signatureFields).reduce(
          (acc, cur) => {
            acc.push(
              utils.getValue(
                `${cur}.${ownerSignatureSpecIdx}`,
                signatureFields,
              ),
            );
            return acc;
          },
          [],
        );

        const updatedDocument = utils.buildNestedObject(
          document,
          'documentSignatureSpecs',
          signatureSpecs,
        );
        return updatedDocument;
      });
    }

    const nextDocument = filteredDocuments[documentStep];
    const nextTransferAgreementDocumentId = getTransferAgreementDocumentId(
      transferAgreement,
      nextDocument,
    );

    nextDocument.isFinalDocument = documentStep === filteredDocuments.length - 1;

    setDocuments(filteredDocuments);
    setCurrentDocument(nextDocument);
    setTransferAgreementDocumentId(nextTransferAgreementDocumentId);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <PageLoader />;

  return (
    <motion.div
      id="documentStepContainer"
      {...actions}
      variants={variants.mainContainer}
    >
      <AnimatePresence exitBeforeEnter>
        <DocumentSign
          {...props}
          key={documentStep}
          updateDocumentStep={updateDocumentStep}
          allDocuments={documents}
          document={currentDocument}
          documentStep={documentStep}
          transferAgreementDocumentId={transferAgreementDocumentId}
          signaturePreview={signaturePreview}
          getPreview={getPreview}
          font={font}
        />
      </AnimatePresence>
    </motion.div>
  );
};

export default DocumentStepsContainer;
