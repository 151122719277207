import ups from '../assets/VendorLogos/UPS-logo.png';
import fedex from '../assets/VendorLogos/Fedex-logo.png';

// TODO: Move this to somewhere better
const providers = {
  UPS: {
    logo: ups,
    url: 'https://www.ups.com/dropoff',
  },
  FEDEX: {
    logo: fedex,
    url: 'https://fedex.com/locate',
  },
  default: {
    logo: 'undefined',
    url: 'undefined',
  },
};

const getShippingCarrierInfo = (providerName) =>
  providers[providerName] || providers.default;

export default getShippingCarrierInfo;
