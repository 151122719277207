import React from 'react';

import { Card, Col, Row } from 'reactstrap';

import { withTranslation, Trans } from 'react-i18next';

const Terms = (props) => {
  const { t } = props;
  return (
    <>
      <Row noGutters className="d-flex justify-content-center">
        <Col>
          <Card className="p-2 policy">
            <Row noGutters>
              <Col className="detail-header mb-2 font-weight-bold ml-2 ml-lg-3">
                <h1> {t('policy:termsHeader')} </h1>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="mx-md-2 mx-lg-3">
                <p>{t('policy:modifyDate')}</p>
                <Trans i18nKey="policy:useServices">
                  <p> </p>
                  <p> </p>
                  <p className="font-weight-bold"> </p>
                  <span> </span>
                  <p> </p>
                  <span> </span>
                  <p> </p>
                  <p> </p>
                  <ol>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                  </ol>
                </Trans>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="mx-md-2 mx-lg-3">
                <Trans i18nKey="policy:account">
                  <span> </span>
                  <p> </p>
                  <p>
                    {' '}
                    <a
                      className="text-secondary"
                      href="mailto: infosec@champtitles.com"
                    >
                      {' '}
                    </a>{' '}
                  </p>
                  <span className="text-decoration-underline"> </span>
                  <span> </span>
                  <ol>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                  </ol>
                  <p> </p>
                  <p> </p>
                  <span> </span>
                  <p> </p>
                  <span> </span>
                  <p> </p>
                  <span> </span>
                  <p>
                    {' '}
                    <a
                      className="text-secondary"
                      href="/policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                    </a>
                    {''}
                    <a
                      className="text-secondary"
                      href="/policy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                    </a>
                    {''}
                  </p>
                  <span> </span>
                  <p> </p>
                </Trans>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="mx-md-2 mx-lg-3">
                <Trans i18nKey="policy:services">
                  <span> </span>
                  <p> </p>
                  <p> </p>
                  <span> </span>
                  <p> </p>
                  <p> </p>
                  <p> </p>
                  <p> </p>
                  <p> </p>
                  <ol>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li>
                      {''}
                      <address> </address>
                    </li>
                  </ol>
                  <p>
                    {' '}
                    <a
                      className="text-secondary"
                      href="mailto: legal@champtitles.com"
                    >
                      {' '}
                    </a>{' '}
                  </p>
                  <p> </p>
                  <p> </p>
                  <ol>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                  </ol>
                  <span> </span>
                  <p> </p>
                  <span> </span>
                  <p> </p>
                  <span> </span>
                  <p> </p>
                  <span> </span>
                  <p> </p>
                  <span> </span>
                  <p> </p>
                </Trans>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="mx-md-2 mx-lg-3">
                <Trans i18nKey="policy:copyright">
                  <span> </span>
                  <p> </p>
                  <p> </p>
                  <span> </span>
                  <p> </p>
                  <p> </p>
                  <span> </span>
                  <p> </p>
                  <span> </span>
                  <p> </p>
                </Trans>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="mx-md-2 mx-lg-3">
                <Trans i18nKey="policy:aboutTerms">
                  <span> </span>
                  <ol>
                    <li className="ml-5">
                      {' '}
                      <p> </p>
                    </li>
                    <li className="ml-5">
                      {' '}
                      <p>
                        {' '}
                        <a
                          className="text-secondary"
                          href="mailto: legal@champtitles.com"
                        >
                          {' '}
                        </a>
                        {''}
                      </p>
                    </li>
                    <li className="ml-5">
                      {' '}
                      <p>
                        {' '}
                        <span className="font-weight-bold"> </span>{' '}
                      </p>
                    </li>
                  </ol>
                  <span> </span>
                  <p> </p>
                  <span> </span>
                  <p> </p>
                  <span> </span>
                  <p> </p>
                  <span> </span>
                  <p> </p>
                  <span> </span>
                  <p>
                    {' '}
                    <a
                      className="text-secondary"
                      href="mailto: legal@champtitles.com"
                    >
                      {' '}
                    </a>{' '}
                  </p>
                </Trans>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="mx-md-2 mx-lg-3">
                <p>{t('policy:footer')}</p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default withTranslation('policy')(Terms);
