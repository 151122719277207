import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col } from 'reactstrap';

const UploadList = (props) => {
  const { documents, uploadedAttachments, t } = props;

  return (
    <Fragment>
      <Row>
        <Col className="mb-5">
          <div className="detail-header font-weight-bold">
            {t('titleUpload:uploadDocuments')}
          </div>
          <div>{t('titleUpload:uploadInstructions')}</div>
        </Col>
      </Row>
      {documents.map((document, idx) => {
        const uploaded = uploadedAttachments.find(
          (attachment) => attachment.attachmentType === document.attachmentType,
        );

        return (
          <Row key={idx}>
            {idx !== 0 && <hr className="style-detail" />}
            <Col className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
              <Row
                key={idx}
                className="align-items-center mb-4 mb-md-1 mr-0 mr-lg-2 ml-0 ml-lg-5"
              >
                {uploaded ? (
                  <i
                    className="fas fa-check-circle pr-3 text-primary upload-icon"
                    aria-hidden="true"
                  />
                ) : (
                  <i
                    className="fas fa-file-import pr-3 upload-icon medium-gray"
                    aria-hidden="true"
                  />
                )}
                <Col>
                  <div className="detail-header font-weight-bold">
                    {document.displayName}
                  </div>
                  <div>
                    {uploaded ? (
                      <span className="text-primary font-weight-bold">
                        {t('titleUpload:uploaded')}
                      </span>
                    ) : (
                      <span className="font-weight-bold medium-gray">
                        {t('titleUpload:required')}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Button
                className="mr-0 mr-lg-3"
                color={uploaded ? 'secondary' : 'primary'}
                onClick={() => props.setCurrentDocument(document)}
              >
                {uploaded ? t('buttons:view') : t('buttons:upload')}
              </Button>
            </Col>
          </Row>
        );
      })}
    </Fragment>
  );
};

UploadList.propTypes = {
  documents: PropTypes.array.isRequired,
  uploadedAttachments: PropTypes.array.isRequired,
  setCurrentDocument: PropTypes.func.isRequired,
};

export default UploadList;
