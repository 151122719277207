const headerLogo = {
  showHeaderLogo: {
    show: true,
    img: {
      animate: {
        x: '-175%',
        y: '-50%',
        opacity: 1,
      },
      transition: {
        duration: 0.3,
      },
    },
    label: {
      animate: {
        x: 100,
      },
      transition: {
        duration: 0.3,
      },
    },
  },
  showHeaderLogoMobile: {
    show: true,
    img: {
      animate: {
        x: '-150%',
        y: '-50%',
        opacity: 1,
      },
      transition: {
        duration: 0.3,
      },
    },
    label: {
      animate: {
        x: 40,
      },
      transition: {
        duration: 0.3,
      },
    },
  },
  hideHeaderLogo: {
    show: false,
    img: {
      animate: {
        x: '-225%',
        y: '-50%',
        opacity: 0,
      },
      transition: {
        duration: 0.3,
      },
    },
    label: {
      animate: {
        x: 0,
      },
      transition: {
        duration: 0.3,
      },
    },
  },
};

export { headerLogo };
