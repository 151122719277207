import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Col, Row } from 'reactstrap';

import { DocumentModal } from '../Modals';

import PrintIcon from './PrintIcon';

import { printButtons } from './printMenuAnimations.js';

import { utils } from '@champtitles/utilities';
import { getStateConfig } from '../../state-configs/GetStateConfig';
import { isNil } from 'lodash';

const { hover, tap } = printButtons;

class PrintMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewImage: false,
      relativeUrl: null,
      attachmentType: '',
      attachmentId: null,
      documentsToShow: [],
    };
  }

  determineType = (type) => {
    return this.state.imageFileTypes.find(
      (imageType) => type.indexOf(imageType) > 0,
    );
  };

  showDocumentModal = (e) => {
    const {
      currentTarget: {
        dataset: { click },
      },
    } = e;
    const {
      transferAgreement,
      transferAgreement: { attachments },
    } = this.props;
    const attachment = attachments.find(
      (attachment) => attachment.id === click,
    );
    const attachmentId = attachment.id;
    const attachmentName = attachment.userFileName;
    const relativeUrl = `/transferagreements/${transferAgreement.id}/attachments/${attachmentId}/download`;
    const attachType = attachment.mimeType;

    this.setState({
      viewImage: true,
      relativeUrl,
      attachmentName,
      attachmentType: attachType,
    });
  };

  closeModal = () => {
    this.setState({
      viewImage: false,
    });
  };

  formatAttachmentName = (attachment) => {
    const splitName = attachment.userFileName.split('_');
    splitName.pop();

    let translatedName = this.props.t(`common:attachmentTypes.${splitName[0]}`);

    let result;
    if (translatedName.includes('attachmentTypes.')) {
      result = attachment.userFileName;
    } else if (splitName.length > 1) {
      result = translatedName + ' - ' + splitName[1];
    } else {
      result = translatedName;
    }

    return result;
  };

  handleError = () => {
    this.setState({
      viewImage: false,
    });
  };

  getValue = (path) => utils.getValue(path, this.props.transferAgreement);
  getStateDocuments = () => {
    const stateConfig = getStateConfig(this.getValue('stateCode'));
    if (!isNil(stateConfig) && !isNil(stateConfig.shownDocuments)) {
      this.setState({ documentsToShow: stateConfig.shownDocuments });
    }
  };

  componentDidMount() {
    this.getStateDocuments();
  }

  render() {
    const {
      viewImage,
      relativeUrl,
      attachmentName,
      attachmentType,
      documentsToShow,
    } = this.state;

    let attachments = this.getValue('attachments') || [];

    if (documentsToShow.length > 0) {
      attachments = attachments.filter((x) =>
        this.state.documentsToShow.includes(x.attachmentType),
      );
    }

    return (
      <div id="printMenu" data-testid="printMenu">
        <Row className="text-center">
          <Col>
            <Row className="justify-content-center">
              {attachments.map((attachment, idx) => {
                return (
                  <Col xs={12} sm={4} className="mb-4" key={idx}>
                    <motion.button
                      className="btn btn-link print py-4"
                      onClick={this.showDocumentModal}
                      data-click={attachment.id}
                      whileHover={hover}
                      whileTap={tap}
                      data-testid={`attachment: ${attachment.attachmentType}`}
                    >
                      <PrintIcon />
                      <div className="mt-2">
                        {this.formatAttachmentName(attachment)}
                      </div>
                    </motion.button>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
        <DocumentModal
          {...this.props}
          viewImage={viewImage}
          relativeUrl={relativeUrl}
          documentName={attachmentName}
          closeModal={this.closeModal}
          handleError={this.handleError}
          attachmentType={attachmentType}
        />
      </div>
    );
  }
}

PrintMenu.propTypes = {
  transferAgreement: PropTypes.object.isRequired,
};

export default PrintMenu;
