import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';

import { utils } from '@champtitles/utilities';

const getFormat = (dateFormat) => dateFormat.replace(/[^/]/g, '#');

const getMasking = (dateFormat) =>
  dateFormat
    .split('/')
    .join('')
    .split('')
    .map((char) => char.toUpperCase());

const DateSelectorWithIcon = (props) => {
  const {
    validated,
    dateFormat,
    minDate,
    maxDate,
    parentObject,
    showYearDropdown,
    showMonthDropdown,
    dropdownMode,
    name,
    label,
    id,
    actions: { onDateChange, onDateSelect },
  } = props;

  const { t } = useTranslation('dateSelectorComponent');

  const [selectedDate, setSelectedDate] = useState(null);
  const [wasSelected, setWasSelected] = useState(false);

  const getValue = (path) => {
    const value = utils.getValue(path, parentObject);

    // DTO returns `YYYY-MM-DD`, so need to convert it to a value the datepicker recognizes
    const date = moment(value, 'YYYY-MM-DD').format('MM-DD-YYYY');

    return value && value.length === 10 && date !== 'Invalid date'
      ? date.split('-').join('')
      : value;
  };

  const onValueChange = (values) => {
    if (!wasSelected) {
      const { value } = values;
      const date = moment(value, 'MMDDYYYY').format('YYYY-MM-DD');

      // Make sure there's a value, and that it's 8 chars long. If `date` is valid, use it, otherwise stick with the other format
      const valueToPassIn =
        value && value.length === 8 && date !== 'Invalid date' ? date : value;

      // Determine validate check. If manually entered, need to go against `YYYYMMDD`, especially if the date is not valid. Otherwise, revalidate against the `MM/dd/yyyy` format
      const dateFormatCheck =
        value.length === 8 && date !== 'Invalid date' ? 'YYYYMMDD' : dateFormat;

      // Actual generated date if date is valid for the calendar to update
      const actualDate =
        value && value.length === 8 && date !== 'Invalid date'
          ? moment(value, 'MM/DD/YYYY').toDate()
          : null;

      onDateChange(name, valueToPassIn, dateFormatCheck);
      setSelectedDate(actualDate);
    } else {
      setWasSelected(false);
    }
  };

  const dateSelected = (date) => {
    // date value to use for the DTO
    const dateValue = moment(date, 'MM-DD-YYYY').format('YYYY-MM-DD');

    // date to validate against with `MM/dd/yyyy`
    const formattedDateForValidation = moment(date).format('MM-DD-YYYY');

    setWasSelected(true);
    onDateSelect(name, dateValue, formattedDateForValidation, dateFormat);
  };

  return (
    <InputGroup>
      <NumberFormat
        value={getValue(name)}
        name={name}
        className={`form-control ${utils.checkFieldValidation(
          name,
          validated,
        )}`}
        onValueChange={onValueChange}
        format={getFormat(dateFormat)}
        mask={getMasking(dateFormat)}
        required
      />
      <Label className="form-control-placeholder">{label}</Label>
      <InputGroupAddon addonType="append">
        <DatePicker
          id={id}
          selected={selectedDate}
          name={name}
          autoComplete="off"
          dateFormat={dateFormat}
          minDate={minDate}
          maxDate={maxDate}
          onChange={dateSelected}
          showYearDropdown={showYearDropdown}
          showMonthDropdown={showMonthDropdown}
          dropdownMode={dropdownMode}
          customInput={
            <Button>
              <i className="far fa-calendar-alt" />
            </Button>
          }
        />
      </InputGroupAddon>
      <FormFeedback>
        {t(`formValidation:${validated[name].error}`)}
      </FormFeedback>
    </InputGroup>
  );
};

export default DateSelectorWithIcon;
