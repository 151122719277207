import React from 'react';

export default function MouseIcon() {
  return (
    <svg className="svg-icon" height={100} width={100} viewBox="0 0 48 48">
      <g
        fill="none"
        stroke="#000"
        strokeWidth={1}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      >
        <path d="M29.815 33.916c.84-1.025.764-2.489-.169-3.252-.932-.762-2.381-.544-3.22.482l-3.424 4.186-.46-.457 4.317-5.28c.84-1.027.763-2.49-.168-3.251-.934-.763-2.381-.545-3.221.481l-4.049 4.952-.46-.457 4.476-5.477c.84-1.025.764-2.489-.168-3.251-.932-.761-2.38-.545-3.221.482l-4.208 5.148-.469-.467c.029-.031.063-.058.091-.092l10.56-12.913c.839-1.027.761-2.491-.171-3.252-.931-.761-2.38-.545-3.219.482L12.207 24.731l-.347-.283 2.217-5.945c.463-1.241-.113-2.615-1.281-3.05-1.168-.436-2.502.224-2.966 1.466L4.827 30.337h0l-.001.002-.824 2.21a2.537 2.537 0 00-.149 1.031c-.92 4.139-.049 6.203-.049 6.203L12.631 47c7.086-1.212 11.644-6.307 11.644-6.307v-.001h0l5.54-6.776z" />
        <path d="M19.232 16.136c2.113-6.185 6.714-10.471 12.051-10.471 7.338 0 13.286 8.104 13.286 18.102s-5.948 18.102-13.286 18.102c-2.102 0-4.089-.664-5.854-1.848M31.283 12.134v6.734" />
        <path d="M31.212 5.493c.038-3.011-3.099-4.461-5.645-4.492-2.992-.039-4.858 1.808-6.768 3.93-1.808 2.007-2.828 3.43-5.579 4.139-1.37.352-3.012.496-4.44.324-3.368-.405-4.257-3.872-5.349-6.62" />
      </g>
    </svg>
  );
}
