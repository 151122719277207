import React from 'react';
import { func } from 'prop-types';
import { withTranslation } from 'react-i18next';

const PoweredByChamp = ({ t }) => {
  const year = new Date().getFullYear();

  return (
    <span style={{ fontSize: 12, fontWeight: 'bold' }}>
      {t('poweredBy')}: &copy;{year} CHAMPtitles
    </span>
  );
};

PoweredByChamp.propTypes = {
  t: func.isRequired,
};

export default withTranslation()(PoweredByChamp);
