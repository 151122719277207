import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';

import { DocumentModal, ResponseAnimations } from '../components';

class VehicleClaimTile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transferAgreement: props.transferAgreement || {},
      logo: props.transferAgreement.branding.brandingAssets.find(
        (asset) => asset.name.toLowerCase() === 'insurerlogo',
      ),
      showPrintOwnerShippingLabel: false,
      ownerShippingLabel: props.transferAgreement.attachments?.find(
        (attachment) => attachment.attachmentType === 'OWNER_SHIPPING_LABEL',
      ),
    };
  }

  viewClaim = () => {
    this.props.action(this.state.transferAgreement);
  };

  viewPrint = () => {
    this.setState({
      showPrintOwnerShippingLabel: true,
    });
  };

  closeShippingLabelModal = () => {
    this.setState({
      showPrintOwnerShippingLabel: false,
    });
  };

  handleShippingModalError = () => {
    this.setState({
      showPrintOwnerShippingLabel: false,
    });
  };

  render() {
    const { t } = this.props;
    const {
      transferAgreement,
      logo,
      showPrintOwnerShippingLabel,
      ownerShippingLabel,
    } = this.state;

    return (
      <div className="tile-wrapper">
        <Row className="px-3 pt-3">
          <Col className="font-80-rem bold">
            <Row noGutters>{transferAgreement.year}</Row>
            <Row noGutters>{transferAgreement.make}</Row>
            <Row noGutters>{transferAgreement.model}</Row>
            <Row noGutters>{transferAgreement.vin}</Row>
          </Col>
          <Col className="text-right">
            <img
              src={logo.s3FileName}
              className="logo-width"
              alt={t('insurerLogo')}
            />
          </Col>
        </Row>
        <hr style={{ margin: '10px' }} />
        <Row className="font-80-rem px-3 pb-3 status-text">
          <Col>
            <Row>
              <Col>
                {transferAgreement.ownerReviewCompleteDate ? (
                  <span className="smaller-checkmark">
                    <span className="label mr-2">{t('status')}:</span>
                    <ResponseAnimations.Checkmark className="svg-class" />
                    <span className="label success ml-2">{t('completed')}</span>
                  </span>
                ) : (
                  <Fragment>
                    <div className="py-2">
                      <span>
                        <span className="mr-2">{t('status')}:</span>
                        <span className="pending mr-2">
                          {t('pendingCompletion')}
                        </span>
                      </span>
                    </div>
                    <div>{t('claimTile:nonCompletedText')}</div>
                  </Fragment>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          className={
            'button-bottom ' +
            (transferAgreement.ownerReviewCompleteDate
              ? 'text-left'
              : 'text-right')
          }
        >
          <Col>
            {transferAgreement.ownerReviewCompleteDate ? (
              <>
                {ownerShippingLabel && (
                  <Button
                    color="secondary"
                    size="sm"
                    className="mb-1 same-size-button font-90-rem"
                    onClick={this.viewPrint}
                  >
                    {t('buttons:printShippingLabel')}
                  </Button>
                )}
                <Button
                  color="secondary"
                  size="sm"
                  className="same-size-button font-90-rem"
                  onClick={this.viewClaim}
                >
                  {t('buttons:viewDocs')}
                </Button>
              </>
            ) : (
              <Button
                color="primary"
                size="sm"
                className="same-size-button font-90-rem"
                onClick={this.viewClaim}
              >
                {t('buttons:continue')}
              </Button>
            )}
          </Col>
          {ownerShippingLabel && (
            <DocumentModal
              {...this.props}
              viewImage={showPrintOwnerShippingLabel}
              relativeUrl={`/transferagreements/${transferAgreement.id}/attachments/${ownerShippingLabel.id}/download`}
              documentName={ownerShippingLabel.userFileName}
              closeModal={this.closeShippingLabelModal}
              handleError={this.handleShippingModalError}
              attachmentType={ownerShippingLabel.mimeType}
            />
          )}
        </Row>
      </div>
    );
  }
}

VehicleClaimTile.propTypes = {
  action: PropTypes.func.isRequired,
};

export default VehicleClaimTile;
