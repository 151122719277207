import React, { useState, useRef, useContext } from 'react';
import { func, string } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useRouteMatch, useHistory } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  FormText,
  Alert,
} from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';

// actions
import {
  fetchAccessToken,
  fetchDocumentById,
  fetchDocumentError,
} from '../../../actions/cocPortalActions';

// reducers
import { Context } from '../../../reducers/store';
import {
  getAccessTokenError,
  getDocumentError,
  getAccessTokenLoading,
  getDocumentLoading,
  getLoginError,
} from '../../../reducers/cocPortalReducer';

// components
import PoweredByChamp from '../../../components/PoweredByChamp/PoweredByChamp';
import PageLoader from '../../../hooks/components/LoadingAnimations/PageLoader';

// utils
import { getLoginErrorText } from '../helpers';

const Login = ({ t, queryId, queryZip }) => {
  const [state, dispatch] = useContext(Context);
  const { url } = useRouteMatch();
  const history = useHistory();
  const recaptchaRef = useRef(null);

  const [documentId, setDocumentId] = useState(queryId || '');
  const [zipCode, setZipCode] = useState(queryZip || '');
  const [recaptcha, setRecaptcha] = useState('');
  const [recaptchaExpired, setRecaptchaExpired] = useState(false);

  const accessTokenError = getAccessTokenError(state);
  const documentError = getDocumentError(state);
  const loginError = getLoginError(state);
  const isLoadingAccessToken = getAccessTokenLoading(state);
  const isLoadingDocument = getDocumentLoading(state);

  const isEnabled = !recaptchaExpired && recaptcha && documentId && zipCode;

  const onSubmit = async () => {
    const body = {
      zipcode: zipCode.trim(),
    };

    const docId = documentId.trim();

    const token = await fetchAccessToken(dispatch, body, docId);

    if (token) {
      const transferAgreement = await fetchDocumentById(dispatch, docId, token);

      if (transferAgreement) {
        history.push(`${url}/document`);
      }
    }
  };

  const onCaptchaChange = (value) => {
    setRecaptcha(value);
    setRecaptchaExpired(false);
  };

  const onDocumentIdChange = ({ target }) => {
    const { value } = target;
    setDocumentId(value);
  };

  const onZipChange = ({ target }) => {
    const { value } = target;
    setZipCode(value);
  };

  const onErrorDismiss = () => {
    fetchDocumentError({
      dispatch,
      payload: false,
    });
  };

  const getErrorMessage = () => {
    if (loginError) {
      return getLoginErrorText(loginError, t);
    }
    if (accessTokenError) {
      return t('cocPortal:loginError');
    }
    if (documentError) {
      return t('cocPortal:documentError');
    }
  };

  if (isLoadingAccessToken || isLoadingDocument) {
    return <PageLoader />;
  }

  return (
    <Container fluid="sm" className="login-form">
      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
          <Col className="wrapper">
            <Col sm="12" className="header">
              <h4>{t('cocPortal:certificateOfCompletion')}</h4>
              <h4>{t('cocPortal:validationPortal')}</h4>
            </Col>
            <Form>
              <FormGroup>
                <FormText className="sub-header">
                  <span>{t('cocPortal:loginFormText.1')}</span>
                  <span>{t('cocPortal:loginFormText.2')}</span>
                  <span>{t('cocPortal:loginFormText.3')}</span>
                </FormText>
                <Input
                  type="text"
                  name={t('cocPortal:transactionIdentifier')}
                  id="transactionIdentifier"
                  placeholder={t('cocPortal:transactionIdentifier')}
                  value={documentId}
                  onBlur={onErrorDismiss}
                  onChange={onDocumentIdChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name={t('cocPortal:transactionInitiatorZipCode')}
                  id="transactionInitiatorZipCode"
                  placeholder={t('cocPortal:transactionInitiatorZipCode')}
                  value={zipCode}
                  onBlur={onErrorDismiss}
                  onChange={onZipChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA}
                  onChange={onCaptchaChange}
                  onExpired={() => setRecaptchaExpired(true)}
                  className="captcha-container"
                />
              </FormGroup>
              <FormGroup className="button-row">
                <Button
                  color="secondary"
                  size="lg"
                  onClick={onSubmit}
                  disabled={!isEnabled}
                >
                  {t('validate')}
                </Button>
              </FormGroup>
              <Alert color="danger" isOpen={accessTokenError || documentError}>
                {getErrorMessage()}
              </Alert>
            </Form>
          </Col>
          <Row className="powered-by">
            <PoweredByChamp />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

Login.propTypes = {
  t: func.isRequired,
  queryId: string,
  queryZip: string,
};

Login.defaultProps = {
  queryId: '',
  queryZip: '',
};

export default withTranslation()(Login);
