const defaultDetailsConfirmationFields = [
  {
    fieldPath: 'vehicle.model',
    shown: true,
    excludedStates: ['CA'],
  },
  {
    fieldPath: 'vehicle.registrationExpirationDate',
    shown: true,
    excludedStates: ['AL', 'OH', 'TX', 'GA', 'WI'],
  },
  {
    fieldPath: 'vehicle.bodyType.name',
    shown: true,
    excludedStates: ['CA'],
  },
  {
    fieldPath: 'vehicle.licensePlateNumber',
    shown: true,
    excludedStates: ['AL', 'OH', 'TX', 'GA', 'WI'],
  },
  {
    fieldPath: 'owner.driverLicenseNumber',
    shown: true,
  },
];

export default defaultDetailsConfirmationFields;
