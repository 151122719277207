import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import { withTranslation } from 'react-i18next';

import { utils, validator } from '@champtitles/utilities';

import { ActionButton } from '../../hooks/components';

import { FONTS } from './fonts';

export const getOwnerSigningName = (transferAgreement) => {
  const owner = utils.getValue('owner', transferAgreement);
  if (owner === '') {
    return '';
  }
  const ownerType = owner.type;
  if (ownerType.toLowerCase() === 'person') {
    return owner.name.toUpperCase();
  }
  return owner.representative.name.toUpperCase();
};

class ESignatureTyped extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      successfulSubmit: false,
      submitError: false,
      signature: {
        typedName: getOwnerSigningName(props.transferAgreement),
      },
      validated: {
        typedName: {
          validationCheck: 'name',
          required: true,
          isValid: null,
        },
      },
      isMobile: /mobile/i.test(navigator.userAgent),
      mobileDevice: 'mobile',
      dropdownOpen: false,
    };

    this.auth = props.auth;
  }

  getFontClass = (fontName) => {
    const found = FONTS.find((font) => font.name === fontName);
    return found ? found.previewFont : '';
  };

  updateFont = (fontName) => {
    const newFont = FONTS.find((font) => font.name === fontName);
    const { getPreview } = this.props;
    const { signature } = this.state;
    getPreview(this.getValue('typedName', signature), newFont);
  };

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  onChange = (e) => {
    let { signature } = this.state;
    const { validated } = this.state;
    const {
      target: { name, value },
    } = e;
    const { getPreview } = this.props;

    signature = utils.buildNestedObject(signature, name, value);
    const validateField = validated[name];
    const validatedField = {
      [name]: {
        ...validateField,
        isValid: validator.validate(
          value,
          validateField.validationCheck,
          validateField.required,
        ),
      },
    };

    this.setState(
      {
        signature,
        validated: {
          ...validated,
          ...validatedField,
        },
        validationError: false,
      },
      () => {
        getPreview(this.getValue('typedName', signature));
      },
    );
  };

  onBlur = (e) => {
    let { signature } = this.state;
    const { validated } = this.state;
    const { name } = e.target;

    const validateField = validated[name];

    const value = validator.sanitize(
      e.target.value,
      validateField.validationCheck,
    );
    const { getPreview } = this.props;

    signature = utils.buildNestedObject(signature, name, value);

    const validatedField = {
      [name]: {
        ...validateField,
        isValid: validator.validate(
          value,
          validateField.validationCheck,
          validateField.required,
        ),
      },
    };
    this.setState(
      {
        signature,
        validated: {
          ...validated,
          ...validatedField,
        },
        validationError: false,
      },
      () => {
        getPreview(this.getValue('typedName', signature));
      },
    );
  };

  checkAllValues = () => {
    let allValid = true;
    const { signature, validated } = this.state;
    const validatedKeys = Object.keys(validated);

    const validatedChecked = validatedKeys.reduce((acc, key) => {
      const fieldValue = utils.getNestedObjectValue(key, signature);
      const validateField = validated[key];
      const isValid = validator.validate(
        fieldValue,
        validateField.validationCheck,
        validateField.required,
      );

      acc[key] = {
        ...acc[key],
        isValid,
      };

      if (!isValid) allValid = false;

      return acc;
    }, validated);

    if (!allValid) {
      this.setState({
        validated: validatedChecked,
      });
    } else {
      this.setState(
        {
          isSaving: true,
        },
        () => {
          const { updateParent } = this.props;
          updateParent({ signature: this.state.signature }, this.props.submit);
        },
      );
    }
  };

  onSubmitCompletion = () => {
    const { successfulSubmit, submitError } = this.state;

    if (successfulSubmit) {
      this.setState(
        {
          successfulSubmit: false,
        },
        () => {
          if (this.props.onSuccessfulSubmit) this.props.onSuccessfulSubmit();
        },
      );
    } else if (submitError) {
      this.setState(
        {
          submitError: false,
        },
        () => {
          if (this.props.onSubmitError) this.props.onSubmitError();
        },
      );
    }
  };

  getValue = (path) => utils.getValue(path, this.state.signature);

  componentDidMount() {
    const { isMobile } = this.state;

    if (isMobile && window.navigator.userAgent.match(/iPhone/i)) {
      this.setState({
        mobileDevice: 'iphone',
      });
    }

    this.props.getPreview(this.getValue('typedName', this.state.signature));
  }

  render() {
    const { font, fontTypes, cancel, submitLabel, t } = this.props;
    const {
      signature,
      validated,
      isSaving,
      isMobile,
      mobileDevice,
      dropdownOpen,
    } = this.state;

    return (
      <div id="eSignTyped" className="mx-auto">
        <Row className="page-container">
          <Col>
            <Row className="mt-5">
              <Col>
                <FormGroup className="esign-field">
                  <Input
                    type="text"
                    value={this.getValue('typedName', signature)}
                    className={`esign-input ${utils.checkFieldValidation(
                      'typedName',
                      validated,
                    )}`}
                    name="typedName"
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    required
                    disabled={this.state.signature}
                  />
                  <Label className="form-control-placeholder">
                    {t('fullName')}
                  </Label>
                  <FormFeedback>{t('formValidation:name')}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column justify-content-center">
                <div className="font-weight-bold">{t('preview')}</div>
              </Col>
              <Col className="text-right">
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={this.toggle}
                  direction="down"
                >
                  <DropdownToggle
                    color="link"
                    className="font-dropdown-toggle"
                    disabled={!this.getValue('typedName', signature)}
                  >
                    {t('changeFont')}
                  </DropdownToggle>
                  <DropdownMenu className="font-dropdown">
                    <DropdownItem
                      header
                      className="font-weight-bold font-dropdown-header"
                    >
                      {t('selectFont')}
                    </DropdownItem>
                    {fontTypes.map((fontName, idx) => (
                      <DropdownItem
                        key={idx}
                        href="#"
                        onClick={() => {
                          this.updateFont(fontName);
                        }}
                      >
                        <div>
                          <div
                            className={`font-example text-truncate ${this.getFontClass(
                              fontName,
                            )}`}
                          >
                            {this.getValue('typedName', signature)}
                          </div>
                        </div>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
            <Row noGutters>
              <Col>
                <div className="typed-signature-container">
                  <div
                    style={{ fontSize: font.fontSize }}
                    className={`typed-signature ${
                      font.previewFont || 'font-dancing-script'
                    }`}
                  >
                    {this.getValue('typedName', signature)}
                  </div>
                  <div className="signature-bar">
                    <span className="signature-x">X</span>
                    <hr className="style-detail-black" />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="text-right">
                <Button color="link" onClick={cancel} disabled={isSaving}>
                  {t('buttons:cancel')}
                </Button>
                <ActionButton
                  className="btn-primary"
                  onClick={this.checkAllValues}
                >
                  {t(`${submitLabel}`)}
                </ActionButton>
              </Col>
            </Row>
            {isMobile && (
              <Row noGutters className="mt-3 text-center rotate-msg">
                <Col>
                  **
                  {t(`portraitModeInstructions.${mobileDevice}`)}
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

ESignatureTyped.propTypes = {
  submit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
  font: PropTypes.object.isRequired,
  fontTypes: PropTypes.array.isRequired,
  cancel: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

ESignatureTyped.defaultProps = {
  submitLabel: 'buttons:submit',
  fontTypes: [],
};

export default withTranslation('eSign')(ESignatureTyped);
