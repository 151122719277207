import React from 'react';
import { motion } from 'framer-motion';

import { success } from './responseAnimations';
import css from './responseAnimationsCss.scss';

const { variants, actions } = success;

export default function ButtonError(props) {
  const easeCurve = [0.65, 0.0, 0.45, 1.0];

  return (
    <motion.span {...actions} variants={variants.mainContainer}>
      {props.showError && (
        <span className="button-cross-container">
          <svg
            className="button-cross-small"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <motion.path
              fill="none"
              d="M18.5,18.5 L34.5,34.5"
              stroke={css.danger}
              initial={{ pathLength: 0, pathOffset: 0 }}
              animate={{ pathLength: 1, pathOffset: 0 }}
              transition={{ duration: 0.6, ease: easeCurve }}
            />
            <motion.path
              fill="none"
              d="M34.5,18.5 L18.5,34.5"
              stroke={css.danger}
              initial={{ pathLength: 0, pathOffset: 0 }}
              animate={{ pathLength: 1, pathOffset: 0 }}
              transition={{ duration: 0.6, ease: easeCurve }}
              onAnimationComplete={props.onAnimationComplete}
            />
          </svg>
        </span>
      )}
    </motion.span>
  );
}
