import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';

import { StyledLinearStepTracker } from '../Styles.js';

import StepIcon from './StepIcon';
import Line from './Line';

export default function LinearStepTracker(props) {
  const { currentStep, trackerSteps, t } = props;

  const totalSteps = trackerSteps.length;

  return (
    <div id="stepIcons">
      <Row className="mb-5 mb-lg-4 mt-2" noGutters>
        <Col className="text-center">
          <StyledLinearStepTracker
            className="linear-tracker-container"
            totalSteps={trackerSteps.length}
          >
            {trackerSteps.map((status, idx) => {
              if (idx < trackerSteps.length - 1) {
                return (
                  <Fragment key={idx}>
                    <StepIcon
                      iconIdx={idx}
                      currentStep={currentStep}
                      icon="fas fa-check"
                      title={t(status.title)}
                    />
                    <Line
                      lineIdx={idx}
                      currentStep={currentStep}
                      totalSteps={totalSteps}
                    />
                  </Fragment>
                );
              } else {
                return (
                  <StepIcon
                    key={idx}
                    iconIdx={idx}
                    currentStep={currentStep}
                    icon="fas fa-check"
                    title={t(status.title)}
                  />
                );
              }
            })}
          </StyledLinearStepTracker>
        </Col>
      </Row>
    </div>
  );
}
