import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

// components
import Login from './Login/Login';
import DocumentView from './DocumentView/DocumentView';

// hooks
import { useQuery } from '../../hooks';

const CocContainer = () => {
  const { path } = useRouteMatch();
  const query = useQuery();

  return (
    <div>
      <Switch>
        <Route exact path={path}>
          {/* url like: /verify?id={documentId}&zipCode={insurerZip} */}
          <Login queryId={query.get('id')} queryZip={query.get('zipCode')} />
        </Route>
        <Route path={`${path}/document`}>
          <DocumentView />
        </Route>
      </Switch>
    </div>
  );
};

export default CocContainer;
