import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { withTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import { AppFooter, AppHeader } from '@coreui/react';

import Keycloak from 'keycloak-js';
import { ENV, KC_CONFIG } from '../../config';

import { Auth } from '../../security/Auth';

import routes from '../../routes';

import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import KeycloakSession from './KeycloakSession';

import { ErrorModal, NotAuthorizedModal } from '../../components';

import { PageLoader } from '../../hooks/components';

import { headerLogo } from './layoutAnimations';

const { showHeaderLogo, showHeaderLogoMobile, hideHeaderLogo } = headerLogo;

const MOBILE_WIDTH = 576;

class DefaultLayout extends Component {
  constructor(props) {
    super(props);

    window.addEventListener('resize', this.updateWindowDimensions);

    this.state = {
      keycloak: null,
      roleError: false,
      authError: false,
      isLoading: true,
      routes: [],
      logo: {
        path: '',
        action: hideHeaderLogo,
        delays: {
          img: 0,
          label: 0,
        },
      },
      window: {
        height: window.innerHeight,
        width: window.innerWidth,
      },
    };

    this.keycloak = Keycloak(KC_CONFIG);

    this.auth = new Auth(this.keycloak);
  }

  roleFilter = (arr) => {
    const roleSetup = this.keycloak.resourceAccess[ENV];

    if (roleSetup) {
      return arr.filter(
        (item) =>
          item.access &&
          item.access.some((role) => roleSetup.roles.includes(role)),
      );
    } else if (!roleSetup && !this.state.roleError) {
      this.setState({ roleError: true, isLoading: false });
      return null;
    }
  };

  setup = () => {
    if (!this.keycloak.resourceAccess[ENV]) {
      this.setState({ authError: true, isLoading: false });
    } else {
      this.setState({
        routes: this.roleFilter(routes(this.props.t)),
        isLoading: false,
      });
    }
  };

  updateHeaderLogo = (imgPath, delays) => {
    const { logo, window } = this.state;

    if (imgPath) {
      this.setState({
        logo: {
          path: imgPath,
          action:
            window.width < MOBILE_WIDTH ? showHeaderLogoMobile : showHeaderLogo,
          delays: {
            img: delays && delays.length ? delays[0] : 0.3,
            label: delays && delays.length > 1 ? delays[1] : 0,
          },
        },
      });
    } else {
      this.setState({
        logo: {
          ...logo,
          action: hideHeaderLogo,
          delays: {
            img: delays && delays.length ? delays[0] : 0,
            label: delays && delays.length > 1 ? delays[1] : 0.2,
          },
        },
      });
    }
  };

  updateWindowDimensions = () => {
    const { logo } = this.state;

    this.setState(
      {
        window: {
          height: window.innerHeight,
          width: window.innerWidth,
        },
      },
      () => {
        if (logo && logo.action.show) {
          this.updateHeaderLogo(logo.path, [0, 0]);
        }
      },
    );
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  async componentDidMount() {
    try {
      await this.keycloak.init({
        onLoad: 'login-required',
        adapter: 'default',
        checkLoginIframe: false,
      });
      this.setState(
        {
          keycloak: this.keycloak,
        },
        this.setup,
      );
    } catch (e) {
      this.setState({ authError: true });
    }
  }

  render() {
    const { t, location, css, i18n } = this.props;

    const { keycloak, isLoading, roleError, authError, routes, logo } =
      this.state;

    if (!keycloak || isLoading) return <PageLoader />;
    if (roleError)
      return (
        <ErrorModal
          header={t('noRolesModal:header')}
          body={t('noRolesModal:body')}
          reset={() => this.keycloak.logout()}
        />
      );

    if (authError)
      return <NotAuthorizedModal isOpen={authError} keycloak={keycloak} />;

    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader {...this.props} keycloak={keycloak} logo={logo} />
        </AppHeader>
        <div className="app-body">
          <main className="main">
            <Container fluid>
              <AnimatePresence exitBeforeEnter>
                <Switch key={location.pathname} location={location}>
                  {routes.map((route, idx) => {
                    return (
                      route.component && (
                        <Route
                          key={location.pathname}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          access={route.access}
                          render={(props) => (
                            <route.component
                              {...props}
                              updateHeaderLogo={this.updateHeaderLogo}
                              keycloak={keycloak}
                              auth={this.auth}
                              t={t}
                              css={css}
                              i18n={i18n}
                            />
                          )}
                        />
                      )
                    );
                  })}
                  <motion.div exit="undefined">
                    <Redirect from="/" to="/claims" />
                  </motion.div>
                </Switch>
              </AnimatePresence>
            </Container>
          </main>
        </div>
        <AppFooter className="justify-content-center">
          <DefaultFooter t={t} />
        </AppFooter>
        <KeycloakSession {...this.props} keycloak={keycloak} t={t} />
      </div>
    );
  }
}

export default withTranslation()(DefaultLayout);
