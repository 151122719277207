import reducerTypes from '../reducers/reducerTypes';
import fetchWrapper from '../security/fetchWrapper';

export const FETCH_ACCESS_TOKEN_LOADING = 'FETCH_ACCESS_TOKEN_LOADING';
export const FETCH_ACCESS_TOKEN_SUCCESS = 'FETCH_ACCESS_TOKEN_SUCCESS';
export const FETCH_ACCESS_TOKEN_ERROR = 'FETCH_ACCESS_TOKEN_ERROR';

export const FETCH_DOCUMENT_LOADING = 'FETCH_DOCUMENT_LOADING';
export const FETCH_DOCUMENT_SUCCESS = 'FETCH_DOCUMENT_SUCCESS';
export const FETCH_DOCUMENT_ERROR = 'FETCH_DOCUMENT_ERROR';

export const FETCH_SIGNER_VERIFY_LOADING = 'FETCH_SIGNER_VERIFY_LOADING';
export const FETCH_SIGNER_VERIFY_SUCCESS = 'FETCH_SIGNER_VERIFY_SUCCESS';
export const FETCH_SIGNER_VERIFY_ERROR = 'FETCH_SIGNER_VERIFY_ERROR';

export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const UPDATE_VERIFY_SIGNERS = 'UPDATE_VERIFY_SIGNERS';
export const RESET_COC_PORTAL = 'RESET_COC_PORTAL';

const COC_REDUCER = reducerTypes.cocPortal;

export const setLoginError = ({ dispatch, payload }) =>
  dispatch({
    type: SET_LOGIN_ERROR,
    payload,
    reducer: COC_REDUCER,
  });

export const fetchAccessTokenLoading = ({ dispatch }) =>
  dispatch({
    type: FETCH_ACCESS_TOKEN_LOADING,
    reducer: COC_REDUCER,
  });

export const fetchAccessTokenSuccess = ({ dispatch, payload }) =>
  dispatch({
    type: FETCH_ACCESS_TOKEN_SUCCESS,
    payload,
    reducer: COC_REDUCER,
  });

export const fetchAccessTokenError = ({ dispatch }) =>
  dispatch({
    type: FETCH_ACCESS_TOKEN_ERROR,
    reducer: COC_REDUCER,
  });

export const fetchAccessToken = async (dispatch, body, documentId) => {
  const api = `/transferagreements/${documentId}/verify`;
  fetchAccessTokenLoading({ dispatch });

  try {
    const options = { method: 'POST', body: JSON.stringify(body) };

    const payload = await fetchWrapper(api, options).then((response) => {
      if (!response.ok) {
        fetchAccessTokenError({ dispatch });
        return false;
      }
      return response.json();
    });

    if (payload.error || !payload) {
      fetchAccessTokenError({ dispatch });
      if (payload.error) {
        setLoginError({ dispatch, payload: payload.error });
      }
      return false;
    }

    const { accessToken } = payload;
    fetchAccessTokenSuccess({ dispatch, payload: accessToken });
    return accessToken;
  } catch (e) {
    fetchAccessTokenError({ dispatch });
  }
};

export const fetchDocumentLoading = ({ dispatch }) =>
  dispatch({
    type: FETCH_DOCUMENT_LOADING,
    reducer: COC_REDUCER,
  });

export const fetchDocumentSuccess = ({ dispatch, payload }) =>
  dispatch({
    type: FETCH_DOCUMENT_SUCCESS,
    payload,
    reducer: COC_REDUCER,
  });

export const fetchDocumentError = ({ dispatch, payload }) =>
  dispatch({
    type: FETCH_DOCUMENT_ERROR,
    payload,
    reducer: COC_REDUCER,
  });

export const fetchDocumentById = async (dispatch, documentId, accessToken) => {
  const api = `/transferagreements/${documentId}/validate`;
  fetchDocumentLoading({ dispatch });

  try {
    const headers = { Authorization: `Bearer ${accessToken}` };
    const options = { method: 'GET' };

    const payload = await fetchWrapper(api, options, headers).then(
      (response) => {
        if (!response.ok) {
          fetchDocumentError({ dispatch, payload: true });
          return false;
        }
        return response.json();
      },
    );

    if (payload.error || !payload) {
      fetchDocumentError({ dispatch, payload: true });
      if (payload.error) {
        setLoginError({ dispatch, payload: payload.error });
      }
      return false;
    }

    fetchDocumentSuccess({ dispatch, payload });
    return true;
  } catch (e) {
    fetchDocumentError({ dispatch, payload: true });
  }
};

export const fetchSignerVerificationLoading = ({ dispatch }) =>
  dispatch({
    type: FETCH_SIGNER_VERIFY_LOADING,
    reducer: COC_REDUCER,
  });

export const fetchSignerVerificationSuccess = ({ dispatch, payload }) =>
  dispatch({
    type: FETCH_SIGNER_VERIFY_SUCCESS,
    payload,
    reducer: COC_REDUCER,
  });

export const fetchSignerVerificationError = ({ dispatch }) =>
  dispatch({
    type: FETCH_SIGNER_VERIFY_ERROR,
    reducer: COC_REDUCER,
  });

export const verifySigner = async (
  dispatch,
  body,
  documentId,
  signerId,
  accessToken,
) => {
  const api = `/transferagreements/${documentId}/validate/signer/${signerId}`;
  fetchSignerVerificationLoading({ dispatch });

  try {
    const headers = { Authorization: `Bearer ${accessToken}` };
    const options = { method: 'POST', body: JSON.stringify(body) };
    const payload = await fetchWrapper(api, options, headers).then(
      (response) => {
        if (!response.ok) {
          if (response.status === 401) {
            window.location.href = '/verify';
          }
          fetchSignerVerificationError({ dispatch });
          return false;
        }
        return response.json();
      },
    );

    if (payload.error || !payload) {
      fetchSignerVerificationError({ dispatch });
      return false;
    }

    fetchSignerVerificationSuccess({ dispatch, payload });
    return payload.verified;
  } catch (e) {
    fetchSignerVerificationError({ dispatch });
  }
};

export const updateVerifySigners = ({ dispatch, payload }) =>
  dispatch({
    type: UPDATE_VERIFY_SIGNERS,
    payload,
    reducer: COC_REDUCER,
  });

export const resetCocPortal = ({ dispatch }) =>
  dispatch({
    type: RESET_COC_PORTAL,
    reducer: COC_REDUCER,
  });
