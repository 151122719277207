import React from 'react';
import { useOpenCv } from 'opencv-react';

export const withOpenCv = (Component) => {
  return function HOC(props) {
    const { cv } = useOpenCv();

    const doBlurDetection = async (image) => {
      const img = cv.imread(image);
      let dst = img.clone();
      let greyver = new cv.Mat();

      // This converts the image to a greyscale.
      await cv.cvtColor(img, greyver, cv.COLOR_BGR2GRAY);
      // Calculate Laplacian
      await cv.Laplacian(greyver, dst, cv.CV_64F, 1, 1);

      let myMean = new cv.Mat(1, 4, cv.CV_64F);
      let myStddev = new cv.Mat(1, 4, cv.CV_64F);
      await cv.meanStdDev(dst, myMean, myStddev);

      return myStddev.doubleAt(0, 0) * myStddev.doubleAt(0, 0);
    };

    return <Component cv={cv} doBlurDetection={doBlurDetection} {...props} />;
  };
};
