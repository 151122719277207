import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import css from './chip.scss';

const original = {
  color: css.white,
  backgroundColor: css.primary,
  borderColor: css.dark,
};

const toDelete = {
  color: css.white,
  backgroundColor: css.danger,
  borderColor: css.red,
};

const animateFocus = {
  opacity: 1,
  transition: {
    duration: 0.1,
  },
};

const animateBlur = {
  opacity: 0.6,
  transition: {
    duration: 0.1,
  },
};

const animateDeleteFocus = {
  color: css.red,
};

const animateDeleteBlur = {
  color: toDelete.color,
};

const animateStatic = {
  color: css.black,
  borderColor: css.gray400,
  backgroundColor: css.gray800,
};

Chip.propTypes = {
  remove: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  editable: PropTypes.bool,
};

Chip.defaultProps = {
  editable: true,
};

export default function Chip(props) {
  const [focused, setFocused] = useState(false);
  const [deleteFocus, setDeleteFocus] = useState(false);
  const [tabFocus, setTabFocus] = useState(false);

  const handleFocus = () => {
    setFocused(!focused);
  };

  const handleDeleteFocus = () => {
    setDeleteFocus(!deleteFocus);
  };

  const handleTabFocus = () => {
    setTabFocus(!tabFocus);
  };

  const handleDelete = (e) => {
    //Delete and backspace keys
    if (
      [8, 46].includes(e.keyCode) ||
      [8, 46].includes(e.which) ||
      [8, 46].includes(e.code)
    ) {
      props.remove();
    }
  };

  let animate = focused ? animateFocus : animateBlur;

  animate = {
    ...animate,
    ...(deleteFocus || props.error ? toDelete : original),
  };

  let attributes = {
    animate,
    initial: original,
    exit: original,
    role: 'button',
    onKeyDown: handleDelete,
    onFocus: handleTabFocus,
    onBlur: handleTabFocus,
    onMouseEnter: handleFocus,
    onMouseLeave: handleFocus,
  };

  if (!props.editable) {
    attributes = {
      animate: {},
      initial: animateStatic,
      exit: animateStatic,
    };
  }

  return (
    <motion.div
      className={'chip-container ' + (!props.editable ? 'static' : '')}
      tabIndex="0"
      {...attributes}
    >
      <span className="chip-label">{props.label}</span>
      {props.editable && (
        <motion.i
          className="far fa-times-circle chip-action"
          onClick={props.remove}
          onBlur={handleDeleteFocus}
          animate={
            deleteFocus || tabFocus ? animateDeleteFocus : animateDeleteBlur
          }
          onMouseEnter={handleDeleteFocus}
          onMouseLeave={handleDeleteFocus}
        />
      )}
    </motion.div>
  );
}
