import { API_ROOT } from '../config';

export class Auth {
  constructor(keycloak) {
    this.keycloak = keycloak;
  }

  fetch(relativeUrl, options) {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    if (!this.keycloak.isTokenExpired()) {
      headers.Authorization = `Bearer ${this.keycloak.token}`;
    }

    const url = `${API_ROOT}${relativeUrl}`;

    return fetch(url, {
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then((response) => response.text())
      .then((text) => (text ? JSON.parse(text) : {}))
      .catch((err) => Promise.reject(err));
  }

  fetchPostFile(relativeUrl, options) {
    const headers = {
      Accept: 'application/json',
    };

    if (!this.keycloak.isTokenExpired()) {
      headers.Authorization = `Bearer ${this.keycloak.token}`;
    }

    const url = `${API_ROOT}${relativeUrl}`;

    return fetch(url, {
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then((response) => response.json())
      .catch((err) => Promise.reject(err));
  }

  fetchImage(relativeUrl, options) {
    const url = `${API_ROOT}${relativeUrl}`;
    const headers = {
      'Content-Type': 'application/octet-stream',
    };

    if (!this.keycloak.isTokenExpired()) {
      headers.Authorization = `Bearer ${this.keycloak.token}`;
    }

    return fetch(url, {
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then((response) => response.blob())
      .then((file) => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onload = (e) => {
            file.data = e.target.result;
            resolve(file);
          };
          reader.readAsDataURL(file);
        });
      })
      .catch((err) => Promise.reject(err));
  }

  fetchSignablePNG(relativeUrl, options) {
    const url = `${API_ROOT}${relativeUrl}`;
    const headers = {
      'Content-Type': 'image/png',
    };
    if (!this.keycloak.isTokenExpired()) {
      headers.Authorization = `Bearer ${this.keycloak.token}`;
    }
    return fetch(url, {
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then((response) => response.json())
      .then((files) =>
        Promise.all(
          files.map((buffer) =>
            Promise.resolve(`data:application/octet-stream;base64,${buffer}`),
          ),
        ),
      )
      .catch((err) => Promise.reject(err));
  }

  fetchPDF(relativeUrl, options) {
    const headers = {
      'Content-Type': 'application/pdf',
    };

    if (!this.keycloak.isTokenExpired()) {
      headers.Authorization = `Bearer ${this.keycloak.token}`;
    }

    const url = `${API_ROOT}${relativeUrl}`;

    return fetch(url, {
      headers,
      ...options,
    })
      .then(this._checkStatus)
      .then((response) => response.blob())
      .catch((err) => Promise.reject(err));
  }

  _checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }

    if (response.status === 403) return this.keycloak.logout();

    return Promise.reject(response);
  }
}
