import moment from 'moment-timezone';

// to be used with date string like "2021-04-26T19:35:58"
export const formatDateTime = (dateString) => {
  const timezone = moment.tz.guess();
  const timezoneCode = moment.tz(timezone).zoneAbbr();

  const date = moment.utc(dateString).local();

  const formattedDate = date.format('MM/DD/YYYY');
  const formattedTime = date.format('hh:mm A');

  return `${formattedDate} ${formattedTime} ${timezoneCode}`;
};

export const getSignerType = (type, t) =>
  ({
    PRIMARY_OWNER: t('vehicleOwner'),
    ADDITIONAL_OWNER: t('vehicleOwner'),
    INSURER: t('insuranceCompany'),
  }[type]);

export const getSignableFormFieldType = (type, t) =>
  ({
    ODOMETER_CERTIFICATION: t('odometerCertification'),
    APPLICATION_FOR_CERTIFICATE_OF_TITLE: t(
      'applicationForCertificationOfTitle',
    ),
    ASSIGNMENT_OF_OWNERSHIP: t('assignmentOfOwnership'),
    POWER_OF_ATTORNEY: t('poa'),
    SALVAGE_AUTHORIZATION: t('salvageAuthorization'),
  }[type]);

const ACCESS_TOKEN_ERROR =
  'Error: Unable to Retrieve Access Token for Verification';
const DOCUMENT_ERROR = 'Error: Invalid Document Identifier and/or Zip Code';
export const getLoginErrorText = (type, t) =>
  ({
    [ACCESS_TOKEN_ERROR]: t('cocPortal:loginError'),
    [DOCUMENT_ERROR]: t('cocPortal:documentError'),
  }[type]);
