import React, { useEffect } from 'react';
import { motion, useMotionValue, useSpring } from 'framer-motion';

import css from '../stepIcon.scss';

const calcProgress = (currentStep, totalSteps) =>
  Math.round(((currentStep + 1) / totalSteps) * 100) / 100;

export default function ProgressCircle({ currentStep, totalSteps }) {
  const stepProgress = useMotionValue(calcProgress(currentStep, totalSteps));
  const pathLength = useSpring(stepProgress, { stiffness: 400, damping: 90 });

  useEffect(() => {
    stepProgress.set(calcProgress(currentStep, totalSteps));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  return (
    <svg className="progress-icon" viewBox="0 0 50 50">
      <motion.path
        fill="none"
        strokeWidth="4"
        stroke={css.gray600}
        d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
        style={{
          translateX: 5,
          translateY: 5,
        }}
      />
      <motion.path
        fill="none"
        strokeWidth="4"
        stroke={css.success}
        strokeDasharray="0 1"
        d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
        style={{
          pathLength,
          rotate: 90,
          translateX: 5,
          translateY: 5,
          scaleX: -1, // Reverse direction of line animation
        }}
      />
    </svg>
  );
}
