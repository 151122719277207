import front from '../assets/SampleImages/CATitleFront.png';
import back from '../assets/SampleImages/CATitleBack.png';

const ShownDocuments = [
  'OWNER_SHIPPING_LABEL',
  'OTHER_OWNER_VIEWABLE',
  'STATEMENT_OF_FACTS',
  'POWER_OF_ATTORNEY',
  'SALVAGE_AUTHORIZATION',
];

const SampleDocuments = {
  titleFront: front,
  titleBack: back,
};

export default {
  surrenderState: true,
  shownDocuments: ShownDocuments,
  sampleDocuments: SampleDocuments,
};
