import React, { useState, useContext } from 'react';
import { func } from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Col,
  Row,
  FormGroup,
  FormFeedback,
  Input,
  Button,
  Alert,
} from 'reactstrap';

// actions
import {
  verifySigner,
  updateVerifySigners,
} from '../../../actions/cocPortalActions';

// reducers
import { Context } from '../../../reducers/store';
import {
  getAccessToken,
  getSigners,
  getDocumentId,
  getSignerVerify,
  getSignerVerifyError,
} from '../../../reducers/cocPortalReducer';

const NameValidation = ({ t }) => {
  const [state, dispatch] = useContext(Context);
  const [signerInput, setSignerInput] = useState({});

  const accessToken = getAccessToken(state);
  const documentId = getDocumentId(state);
  const signers = getSigners(state);
  const signerVerified = getSignerVerify(state);
  const signerVerifyError = getSignerVerifyError(state);

  const onSignerChange = ({ target }, id) => {
    const { value } = target;
    const newState = {
      ...signerInput,
      [id]: value,
    };

    setSignerInput(newState);
  };

  const validateSigner = async (id) => {
    let input = signerInput[id];

    if (input) {
      // convert iOS "smart" single quotes to vertical single quote
      input = input.replace(/[\u2018\u2019]/g, "'").trim();
    }

    const body = {
      signer_name: input,
    };

    const match = await verifySigner(
      dispatch,
      body,
      documentId,
      id,
      accessToken,
    );

    const newSigners = signers;
    const currentSigner = newSigners.find((s) => s.id === id);

    if (currentSigner) {
      currentSigner.signerMatch = match;
    }

    const updatedSigners = [...newSigners];
    updateVerifySigners({ dispatch, payload: updatedSigners });
  };

  return (
    <Row className="section">
      <Col>
        <Row className="section-header">
          <h4>{t('cocPortal:nameValidation.title')}</h4>
        </Row>
        <Row>
          <Col md="12" lg="6">
            <p>{t('cocPortal:nameValidation.description')}</p>
            {signers &&
              signers.length > 0 &&
              signers.map((signer, i) => {
                const { id } = signer;
                const isDisabled = !signerInput[id];
                const value = signerInput[id] || '';
                const verifiedObj = signerVerified.find((s) => s.id === id);
                const { signerMatch } = verifiedObj || false;
                const verified = !!signerMatch;

                const isValid = verified;

                let isInvalid = false;
                if (verifiedObj && verifiedObj.signerMatch !== undefined) {
                  if (!verified) {
                    isInvalid = true;
                  }
                }
                return (
                  <Row className="signer-row" key={`signer-validate-${id}`}>
                    <Col sm="auto" className="signer-name">
                      <span className="bold">
                        {t('cocPortal:nameValidation.signerName.signer')}{' '}
                        {i + 1}{' '}
                        {`${t('cocPortal:nameValidation.signerName.name')}`}
                      </span>
                    </Col>
                    <Col xs="8" sm="4" md="5" lg="7">
                      <FormGroup>
                        <Input
                          type="text"
                          name={`signerName_${id}`}
                          id={`signerName${id}`}
                          placeholder={`${t('Signer ')}${i + 1}${t(' Name')}`}
                          value={value}
                          onChange={(e) => onSignerChange(e, signer.id)}
                          onBlur={() => validateSigner(id)}
                          valid={isValid}
                          invalid={isInvalid}
                        />
                        <FormFeedback valid>
                          {t('cocPortal:nameValidation.signerSuccess')}
                        </FormFeedback>
                        <FormFeedback>
                          {t('cocPortal:nameValidation.signerError')}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col xs="4" sm="auto">
                      <Button
                        size="sm"
                        outline
                        color="success"
                        onClick={() => validateSigner(id)}
                        disabled={isDisabled}
                      >
                        {t('validate')}
                      </Button>
                    </Col>
                  </Row>
                );
              })}
            <Alert color="danger" isOpen={signerVerifyError}>
              {t('cocPortal:nameValidation.fetchError')}
            </Alert>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

NameValidation.propTypes = {
  t: func.isRequired,
};

export default withTranslation()(NameValidation);
