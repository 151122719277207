import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import { ActionButton } from '../../../../hooks/components';

const CancelUploadModal = ({
  close,
  currentDocument,
  remove,
  toggleDeleteWarning,
  t,
  uploadedAttachments,
  updateUploadedAttachments,
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [successfulDelete, setSuccessfulDelete] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [deleteErrorMsg, setDeleteErrorMsg] = useState(false);

  const cancelDocumentUpload = async () => {
    const uploaded = uploadedAttachments.filter(
      (attachment) =>
        attachment.attachmentType === currentDocument.attachmentType,
    );

    setIsDeleting(true);

    try {
      await remove(uploaded[0].id);

      setIsDeleting(false);
      setSuccessfulDelete(true);
    } catch {
      setIsDeleting(false);
      setDeleteError(true);
    }
  };

  const onCancelCompletion = () => {
    if (successfulDelete) {
      setSuccessfulDelete(false);
      updateAttachmentsAndClose();
    } else if (deleteError) {
      setDeleteError(false);
      setDeleteErrorMsg(true);
    }
  };

  const updateAttachmentsAndClose = () => {
    const uploaded = uploadedAttachments.filter(
      (attachment) =>
        attachment.attachmentType === currentDocument.attachmentType,
    );
    const updatedAttachments =
      uploadedAttachments.filter(
        (attachment) => attachment.id !== uploaded[0].id,
      ) || [];

    updateUploadedAttachments(updatedAttachments);
    close();
  };

  return (
    <Modal isOpen={true}>
      <ModalHeader>
        <span>{t('common:delete')}</span>
        <span>{` ${currentDocument.displayName}`}</span>
      </ModalHeader>
      <ModalBody>
        <Row className="px-2 text-center">
          {!deleteErrorMsg ? (
            <Col className="mb-0 alert alert-warning">
              {t('titleUpload:deleteWarningMsg')}
            </Col>
          ) : (
            <Col>{t('titleUpload:deleteErrorMsg')}</Col>
          )}
        </Row>
      </ModalBody>
      <ModalFooter className="flex justify-content-center px-0">
        <Row noGutters className="w-100">
          {!deleteErrorMsg ? (
            <Col className="text-center">
              <Row noGutters>
                <Col
                  xs={{ size: 12, order: 2 }}
                  sm={{ size: 6, order: 1 }}
                  className="text-center text-sm-right"
                >
                  <Button
                    className="mr-2 mb-2 mb-sm-0"
                    color="secondary"
                    onClick={toggleDeleteWarning}
                    disabled={isDeleting}
                  >
                    {t('buttons:noGoBack')}
                  </Button>
                </Col>
                <Col
                  xs={{ size: 12, order: 1 }}
                  sm={{ size: 6, order: 2 }}
                  className="text-center text-sm-left"
                >
                  <ActionButton
                    className="btn btn-primary mb-2 mb-sm-0"
                    onClick={cancelDocumentUpload}
                    isLoading={isDeleting}
                    submitSuccess={successfulDelete}
                    submitError={deleteError}
                    onSubmitComplete={onCancelCompletion}
                  >
                    {t('buttons:yesDelete')}
                  </ActionButton>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col className="text-center">
              <Button className="mr-2" color="danger" onClick={close}>
                {t('buttons:close')}
              </Button>
            </Col>
          )}
        </Row>
      </ModalFooter>
    </Modal>
  );
};

CancelUploadModal.propTypes = {
  close: PropTypes.func.isRequired,
  currentDocument: PropTypes.object.isRequired,
  remove: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  toggleDeleteWarning: PropTypes.func.isRequired,
  updateUploadedAttachments: PropTypes.func.isRequired,
  uploadedAttachments: PropTypes.array.isRequired,
};

export default CancelUploadModal;
