const FONTS = [
  {
    name: 'BETH_ELLEN',
    previewFont: 'font-beth-ellen',
    maxChars: 30,
  },
  {
    name: 'DANCING_SCRIPT',
    previewFont: 'font-dancing-script',
    maxChars: 30,
  },
  {
    name: 'GREAT_VIBES',
    previewFont: 'font-great-vibes',
    maxChars: 50,
  },
  {
    name: 'HOMEMADE_APPLE',
    previewFont: 'font-homemade-apple',
    maxChars: 30,
  },
  {
    name: 'KRISTI',
    previewFont: 'font-kristi',
    maxChars: 50,
  },
  {
    name: 'PARISIENNE',
    previewFont: 'font-parisienne',
    maxChars: 30,
  },
];

export { FONTS };
