import React, { createRef, Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Button, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { StyledButtonHolder } from '../../../../../hooks/components/StepTracker/Styles';
import { PageLoader, ActionButton } from '../../../../../hooks/components';

import {
  ErrorModal,
  ESignArrow,
  ESignatureTyped,
  utils,
} from '../../../../../components';

import { ownerStepTransition } from '../../../pendingRequestsPageAnimations';

const { actions, variants } = ownerStepTransition;

class DocumentSign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isSubmitting: false,
      successfulSubmit: false,
      submitError: false,
      transferAgreement: props.transferAgreement || null,
      documentPages: [],
      timeStampIds: [],
      isMobile:
        /mobile/i.test(navigator.userAgent) &&
        !/ipad|tablet/i.test(navigator.userAgent),
      showHelpModal: false,
      displayTypedSignaturePad: !props.documentStep,
      signaturesApplied: [],
      arrowStatuses: props.document.documentSignatureSpecs.reduce(
        (acc, _, idx) => ({
          ...acc,
          [`arrowStatus${idx}`]: 'pending',
        }),
        {},
      ),
      spinnerLoadingStatuses: props.document.documentSignatureSpecs.reduce(
        (acc, _, idx) => ({
          ...acc,
          [`spinnerLoading${idx}`]: false,
        }),
        {},
      ),
      guideButtons: props.document.documentSignatureSpecs.reduce(
        (acc, _, idx) => ({
          ...acc,
          [`showGuideButton${idx}`]: false,
        }),
        {},
      ),
    };

    const { document } = this.props;

    this.auth = props.auth;
    this.scrollContainerRef = createRef();
    document.documentSignatureSpecs.sort((a, b) => {
      if (a.pageNumber < b.pageNumber) {
        return -1;
      }
      if (a.pageNumber > b.pageNumber) {
        return 1;
      }
      return a.coordinates.y1 - b.coordinates.y1;
    });

    document.documentSignatureSpecs.forEach((_, idx) => {
      this[`eSignArrowRef${idx}`] = createRef();
    });
  }

  async componentDidMount() {
    window.scroll(0, 0);
    const { document, documentStep } = this.props;
    const { transferAgreement } = this.state;

    const attachmentId = transferAgreement.attachments.find(
      (attachment) =>
        attachment.documentGenerationId === document.documentGenerationId,
    ).id;

    const calls = [
      this.auth.fetchSignablePNG(
        `/transferagreements/${transferAgreement.id}/attachments/${attachmentId}/preview`,
      ),
      this.auth.fetch('/config/fonts'),
    ];

    try {
      const [documentPages, fontTypes] = await Promise.all(calls);

      this.setState({
        documentPages,
        fontTypes,
        isLoading: false,
      });
      if (documentStep >= 1) {
        this.moveToNextSignature();
      }
    } catch (e) {
      this.setState({
        dataError: e,
      });
    }
  }

  filterSignatures = (idx) => {
    const { document } = this.props;
    return document.documentSignatureSpecs.filter(
      (signature) => signature.pageNumber === idx + 1,
    );
  };

  showHelpModal = () => {
    this.setState({
      showHelpModal: true,
    });
  };

  closeHelpModal = () => {
    this.setState({
      showHelpModal: false,
    });
  };

  continue = () => {
    const { updateDocumentStep } = this.props;
    updateDocumentStep();
  };

  previous = () => {
    const { previousStep } = this.props;
    previousStep();
  };

  reset = () => {
    const { history } = this.props;
    history.push('/');
  };

  updateParent = (obj, cb) => {
    const { transferAgreement } = this.state;

    this.setState(
      {
        transferAgreement: {
          ...transferAgreement,
          ...obj,
        },
      },
      cb,
    );
  };

  scrollToSignature = (signatureRef) => {
    const signaturePosition =
      signatureRef.offsetTop +
      signatureRef.offsetParent.offsetTop -
      this.scrollContainerRef.offsetTop -
      this.scrollContainerRef.clientHeight / 2;
    this.scrollContainerRef.scrollTo({
      left: 0,
      top: signaturePosition,
      behavior: 'smooth',
    });
  };

  scrollToDocument = () => {
    this.setState({
      displayTypedSignaturePad: false,
    });
    window.scroll(
      0,
      this.scrollContainerRef.getBoundingClientRect().top + window.scrollY - 70,
    );
    this.scrollToSignature(this.eSignArrowRef0);
  };

  applySignature = (field, fieldDisplayName, signatureIdx) => {
    const { transferAgreement, document, transferAgreementDocumentId } =
      this.props;
    const {
      timeStampIds,
      signaturesApplied,
      spinnerLoadingStatuses,
      arrowStatuses,
      displayTypedSignaturePad,
      guideButtons,
    } = this.state;

    if (!displayTypedSignaturePad) {
      this.setState({
        spinnerLoadingStatuses: {
          ...spinnerLoadingStatuses,
          [`spinnerLoading${signatureIdx}`]: true,
        },
      });
      if (!signaturesApplied.includes(field)) {
        this.auth
          .fetch(
            `/transferagreements/${transferAgreement.id}/document/${transferAgreementDocumentId}/signature/timestamp?field=${field}&fieldDisplayName=${fieldDisplayName}&document=${document.attachmentType}`,
            {
              method: 'POST',
            },
          )
          .then((resp) => {
            timeStampIds.push(resp.id);
            signaturesApplied.push(field);

            if (
              signaturesApplied.length !==
              document.documentSignatureSpecs.length
            ) {
              this.setState({
                guideButtons: {
                  ...guideButtons,
                  [`showGuideButton${signatureIdx}`]: true,
                },
              });
            }
            this.setState({
              arrowStatuses: {
                ...arrowStatuses,
                [`arrowStatus${signatureIdx}`]: 'complete',
              },
              spinnerLoadingStatuses: {
                ...spinnerLoadingStatuses,
                [`spinnerLoading${signatureIdx}`]: false,
              },
              timeStampIds,
              signaturesApplied,
            });
          })
          .catch(() => {
            this.setState({
              arrowStatuses: {
                ...arrowStatuses,
                [`arrowStatus${signatureIdx}`]: 'error',
              },
              spinnerLoadingStatuses: {
                ...spinnerLoadingStatuses,
                [`spinnerLoading${signatureIdx}`]: false,
              },
              isSaving: false,
              submitError: true,
            });
          });
      }
    }
  };

  moveToNextSignature = (idx) => {
    const { document } = this.props;
    const { signaturesApplied, guideButtons } = this.state;

    if (signaturesApplied.length !== document.documentSignatureSpecs.length) {
      const nextIndex = document.documentSignatureSpecs.findIndex(
        (signature) => !signaturesApplied.includes(signature.field),
      );
      this.scrollToSignature(this[`eSignArrowRef${nextIndex}`]);
      this.setState({
        guideButtons: {
          ...guideButtons,
          [`showGuideButton${idx}`]: false,
        },
      });
    }
  };

  onSubmitSuccess = () => {
    const { successfulSubmit } = this.state;
    const { updateDocumentStep } = this.props;

    successfulSubmit && updateDocumentStep();
  };

  submitSignature = async () => {
    const { signaturesApplied, timeStampIds } = this.state;
    const { document, signaturePreview, font, transferAgreementDocumentId } =
      this.props;
    let { transferAgreement } = this.props;

    this.setState({
      isSubmitting: true,
    });

    if (document.documentSignatureSpecs.length === signaturesApplied.length) {
      try {
        transferAgreement = await this.auth.fetchPostFile(
          `/transferagreements/${transferAgreement.id}/document/${transferAgreementDocumentId}/signature?type=${document.displayName}&name=${signaturePreview}&font=${font.name}&timestamps=${timeStampIds}`,
          {
            method: 'POST',
          },
        );
        if (document.isFinalDocument) {
          transferAgreement = await this.auth.fetch(
            `/transferagreements/${transferAgreement.id}/owner/signed`,
            {
              method: 'POST',
            },
          );
        }

        this.setState(
          {
            transferAgreement,
            isSubmitting: false,
            successfulSubmit: true,
          },
          this.continue,
        );
      } catch (e) {
        if (e.status === 409) {
          this.setState({
            isSubmitting: false,
            successfulSubmit: true,
          });
        } else {
          this.setState({
            isSubmitting: false,
            submitError: true,
            showSubmitError: true,
          });
        }
      }
    }
  };

  getLabelStatus = (signature, idx) => {
    const { signaturesApplied, arrowStatuses } = this.state;
    const { t } = this.props;
    let status = 'applySignature';

    if (signaturesApplied.includes(signature.field)) {
      status = 'appliedSignature';
    } else if (arrowStatuses[`arrowStatus${idx}`] === 'error') {
      status = 'signatureError';
    } else if (arrowStatuses[`arrowStatus${idx}`] === 'disabled') {
      status = 'disabledSignature';
    }
    return t(status);
  };

  getLocation = (signature, type) => {
    const {
      document: { documentDimension },
    } = this.props;
    let top = (signature.coordinates.y1 / documentDimension.height) * 100;
    let left = (signature.coordinates.x2 / documentDimension.width) * 100;
    let width;

    if (type === 'eSignArrow') {
      left -= 6;
    } else if (type === 'guideButton') {
      top += 4.25;
      left += 3;
    } else if (type === 'signaturePreview') {
      left = (signature.coordinates.x1 / documentDimension.width) * 100 + 3;
      width =
        ((signature.coordinates.x2 - signature.coordinates.x1) /
          documentDimension.width) *
        100;
      top += 1;
    }

    return {
      top: `${top}%`,
      left: `${left}%`,
      ...(width && { width: `${width}%` }),
    };
  };

  getValue = (path, secondaryReference) => {
    const { transferAgreement } = this.state;

    return utils.getValue(path, secondaryReference || transferAgreement);
  };

  getSigPreviewAdjustmentClass = (textLength, availableWidth) => {
    if (
      (textLength >= 30 && availableWidth < 50) ||
      (textLength >= 20 && availableWidth < 40)
    ) {
      return 'small-sig';
    }

    return ''; // no adjustment needed
  };

  showSigPreview = (signature, signaturePreview, font) => {
    const positionStyles = this.getLocation(signature, 'signaturePreview');

    const textLength = signaturePreview.length;
    const availableWidth = Number.parseInt(positionStyles.width, 10);
    const adjustmentClass = this.getSigPreviewAdjustmentClass(
      textLength,
      availableWidth,
    );

    return (
      <div
        className={`sig-preview ${font.previewFont} ${adjustmentClass}`}
        style={positionStyles}
      >
        {signaturePreview}
      </div>
    );
  };

  render() {
    const { t, document, cancel, signaturePreview, font } = this.props;
    const {
      documentPages,
      isLoading,
      isSubmitting,
      successfulSubmit,
      submitError,
      dataError,
      signaturesApplied,
      displayTypedSignaturePad,
      fontTypes,
      arrowStatuses,
      spinnerLoadingStatuses,
      guideButtons,
    } = this.state;

    const readOnly =
      this.getValue('signedFormStatus.salvageTitleApplicationSigned') ||
      this.getValue('transferAgreementStatus') === 'OWNER_DECLINED';

    if (dataError) return <ErrorModal reset={this.reset} />;

    if (isLoading) return <PageLoader />;

    return (
      <motion.div
        id="documentSign"
        {...actions}
        variants={variants.mainContainer}
      >
        <Row noGutters className="d-flex justify-content-center">
          <Col className="step-card">
            {displayTypedSignaturePad && (
              <Card>
                <CardBody className="esign-card-body">
                  <Row>
                    <Col>
                      <ESignatureTyped
                        {...this.props}
                        updateParent={this.updateParent}
                        submit={this.scrollToDocument}
                        submitLabel={t('buttons:useSignature')}
                        cancel={cancel}
                        t={t}
                        auth={this.auth}
                        fontTypes={fontTypes}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
            <Card>
              <CardBody className="document-card">
                <Row className="text-center">
                  <Col>
                    <div
                      ref={(ref) => {
                        this.scrollContainerRef = ref;
                      }}
                      className="scroll-container"
                    >
                      <div className="form-container">
                        {documentPages.map((page, pageIndex) => (
                          <Fragment key={page.slice(-10)}>
                            {pageIndex > 0 && (
                              <div className="img-separation">
                                {`${t('common:page')} ${pageIndex + 1}`}
                              </div>
                            )}
                            <div className="img-container">
                              <img
                                src={page}
                                className="form-view"
                                alt={`${document.displayName} ${t(
                                  'common:page',
                                )} ${pageIndex + 1}`}
                              />
                              {this.filterSignatures(pageIndex).map(
                                (signature, idx) => (
                                  <Fragment key={signature.field}>
                                    <ESignArrow
                                      {...this.props}
                                      ref={(ref) => {
                                        this[`eSignArrowRef${idx}`] = ref;
                                      }}
                                      isLoading={
                                        spinnerLoadingStatuses[
                                          `spinnerLoading${idx}`
                                        ]
                                      }
                                      onClick={() =>
                                        this.applySignature(
                                          signature.field,
                                          signature.fieldDisplayName,
                                          idx,
                                        )
                                      }
                                      label={this.getLabelStatus(
                                        signature,
                                        idx,
                                      )}
                                      labelPos={{ x: '54%', y: '50%' }}
                                      length={300}
                                      location={this.getLocation(
                                        signature,
                                        'eSignArrow',
                                      )}
                                      disabled={signaturesApplied.includes(
                                        signature.field,
                                      )}
                                      signStatus={
                                        !displayTypedSignaturePad
                                          ? arrowStatuses[`arrowStatus${idx}`]
                                          : 'disabled'
                                      }
                                    />
                                    {guideButtons[`showGuideButton${idx}`] && (
                                      <StyledButtonHolder
                                        style={this.getLocation(
                                          signature,
                                          'guideButton',
                                        )}
                                      >
                                        <Button
                                          className="arrow-button"
                                          color="secondary"
                                          onClick={() =>
                                            this.moveToNextSignature(idx)
                                          }
                                        >
                                          {t('buttons:clickNext')}
                                        </Button>
                                      </StyledButtonHolder>
                                    )}
                                    {signaturesApplied.includes(
                                      signature.field,
                                    ) &&
                                      this.showSigPreview(
                                        signature,
                                        signaturePreview,
                                        font,
                                      )}
                                  </Fragment>
                                ),
                              )}
                            </div>
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="bg-white pt-1">
                {!readOnly ? (
                  <>
                    <Row
                      noGutters
                      className="text-row mb-2 mb-md-3 text-center"
                    >
                      <Col>{t('signStatement')}</Col>
                    </Row>
                    <Row>
                      <Col className="text-center">
                        <Button color="link" onClick={cancel}>
                          {t('buttons:cancel')}
                        </Button>
                        <Button
                          color="secondary"
                          className="m-2"
                          onClick={this.previous}
                        >
                          {t('buttons:previousStep')}
                        </Button>
                        <ActionButton
                          className="btn-primary"
                          disabled={
                            signaturesApplied.length !==
                            document.documentSignatureSpecs.length
                          }
                          onClick={this.submitSignature}
                          isLoading={isSubmitting}
                          submitSuccess={successfulSubmit}
                          submitError={submitError}
                          onSubmitComplete={this.onSubmitSuccess}
                        >
                          {t('buttons:submitDocument')}
                        </ActionButton>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row className="text-center mt-3">
                    <Col>
                      <Button color="link" onClick={cancel}>
                        {t('buttons:cancel')}
                      </Button>
                      <Button color="primary" onClick={this.continue}>
                        {t('buttons:continue')}
                      </Button>
                    </Col>
                  </Row>
                )}
              </CardFooter>
            </Card>
          </Col>
        </Row>
        {submitError && <ErrorModal reset={this.reset} />}
      </motion.div>
    );
  }
}

DocumentSign.propTypes = {
  cancel: PropTypes.func.isRequired,
  document: PropTypes.object.isRequired,
  updateDocumentStep: PropTypes.func.isRequired,
  transferAgreementDocumentId: PropTypes.string.isRequired,
};

export default withTranslation('documentSign')(DocumentSign);
