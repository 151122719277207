import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  CustomInput,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import { Trans } from 'react-i18next';

import { utils, ErrorModal } from '../../../../components';

import TitleImageUpload from './TitleImageUpload/TitleImageUpload';
import CancelUploadModal from './CancelUploadModal';

class UploadModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile:
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        ),
      uploadError: null,
      retrieveError: null,
      removeError: null,
      transferAgreement: props.transferAgreement || null,
      userUploadVerification: props.isVerified,
      validated: {
        [`${props.currentDocument.documentGenerationId}`]: {
          validationCheck: '',
          required: true,
          isValid: null,
        },
        userUploadVerification: {
          validationCheck: 'checkbox',
          required: true,
          isValid: null,
        },
      },
      isImageBlurry: false,
      showDeleteWarning: false,
      doubleCheckDelete: false,
    };

    this.auth = props.auth;
  }

  onUserUploadVerificationCheck = (e) => {
    const { currentDocument } = this.props;
    let { userUploadVerification, validated } = this.state;
    const {
      target: { name, checked },
    } = e;

    userUploadVerification = checked;
    const validateField = validated[name];
    const validatedField = {
      [name]: {
        ...validateField,
        isValid: checked,
      },
    };

    this.setState(
      {
        userUploadVerification,
        validated: {
          ...validated,
          ...validatedField,
        },
      },
      () => {
        if (checked) {
          this.props.addVerifiedDocument(currentDocument);
        } else {
          this.props.removeVerifiedDocument(currentDocument);
        }
      },
    );
  };

  upload = async (file, type, id) => {
    let { uploadedAttachments } = this.props;
    let { transferAgreement, validated } = this.state;

    const body = new FormData();
    body.append('file', file);
    let resp;

    this.setState({ isUploading: true });
    try {
      resp = await this.auth.fetchPostFile(
        `/transferagreements/${transferAgreement.id}/attachments?type=${type}&documentGenerationId=${id}`,
        {
          method: 'POST',
          body,
        },
      );

      let updatedAttachments = uploadedAttachments;
      updatedAttachments.push(resp);

      const validateField = validated[type];
      const validatedField = {
        [id]: {
          ...validateField,
          isValid: true,
        },
      };

      this.setState(
        {
          isUploading: false,
          validated: { ...validated, ...validatedField },
        },
        () => {
          this.props.updateUploadedAttachments(updatedAttachments);
        },
      );
    } catch (e) {
      resp = Promise.reject(e);
      this.setState({ uploadError: e, isUploading: false });
    }
    return resp;
  };

  retrieve = async (file) => {
    const { transferAgreement } = this.state;
    let resp;

    try {
      resp = await this.auth.fetchImage(
        `/transferagreements/${transferAgreement.id}/attachments/${file.id}/download`,
      );
    } catch (e) {
      resp = Promise.reject(e);
      if (!this.state.retrieveError) this.setState({ retrieveError: e });
    }
    return resp;
  };

  remove = async (id) => {
    const { transferAgreement } = this.state;

    return await this.auth.fetch(
      `/transferagreements/${transferAgreement.id}/attachments/${id}`,
      {
        method: 'DELETE',
      },
    );
  };

  convertURIToImageData = (URI) => {
    return new Promise(function (resolve, reject) {
      if (URI == null) return reject();
      const image = new Image();
      image.addEventListener(
        'load',
        function () {
          resolve(image);
        },
        false,
      );
      image.src = URI;
    });
  };

  onUploadRetrieved = async (file) => {
    const BLUR_THRESHOLD = 300;
    const isImage = () => file.mimeType && !file.mimeType.includes('pdf');

    if (isImage) {
      const image = await this.convertURIToImageData(file.data);
      const blurValue = await this.props.doBlurDetection(image);
      const isImageBlurry = blurValue < BLUR_THRESHOLD;

      this.setState({
        isImageBlurry,
      });
    }
  };

  removeAndReset = async (id) => {
    const { validated, isImageBlurry } = this.state;
    const { currentDocument, uploadedAttachments } = this.props;

    const updatedAttachments =
      uploadedAttachments.filter((attachment) => attachment.id !== id) || [];
    const type = currentDocument.documentGenerationId;

    if (isImageBlurry) {
      this.setState({
        isImageBlurry: false,
      });
    }

    try {
      await this.remove(id);

      setTimeout(() => {
        const validateField = validated[type];
        const validatedField = {
          [type]: {
            ...validateField,
            isValid: false,
          },
        };

        this.setState(
          {
            validated: {
              ...validated,
              ...validatedField,
            },
          },
          () => {
            this.props.updateUploadedAttachments(updatedAttachments);
          },
        );
      }, 0);
    } catch (e) {
      this.setState({ removeError: e });
    }
  };

  getDocumentError = () => {
    const { t } = this.props;
    const { retrieveError, removeError, uploadError } = this.state;
    let errorLabel = 'generalError';

    if (retrieveError) errorLabel = 'retrieveError';
    else if (removeError) errorLabel = 'removeError';
    else if (uploadError) errorLabel = 'uploadError';

    return {
      header: t(`documentError.${errorLabel}Header`),
      body: t(`documentError.${errorLabel}`),
    };
  };

  checkUploadVerification = () => {
    const { userUploadVerification, validated } = this.state;

    let validatedField = validated;
    validatedField['userUploadVerification'] = {
      ...validatedField['userUploadVerification'],
      isValid: userUploadVerification || '',
    };

    this.setState(
      {
        validated: validatedField,
      },
      () => {
        if (userUploadVerification) this.props.close();
      },
    );
  };

  toggleDeleteWarning = () => {
    const { showDeleteWarning } = this.state;

    this.setState({
      showDeleteWarning: !showDeleteWarning,
    });
  };

  showImageDoublecheckDelete = () => {
    this.setState({
      doubleCheckDelete: true,
    });
  };

  cancelImageDoubleCheckDelete = () => {
    this.setState({
      doubleCheckDelete: false,
    });
  };

  getValue = (path) => utils.getValue(path, this.state.transferAgreement);

  render() {
    const { t, css, i18n, currentDocument, uploadedAttachments } = this.props;

    const {
      uploadError,
      retrieveError,
      removeError,
      userUploadVerification,
      validated,
      isUploading,
      isMobile,
      isImageBlurry,
      showDeleteWarning,
      doubleCheckDelete,
    } = this.state;

    const uploaded = uploadedAttachments.filter(
      (attachment) =>
        attachment.attachmentType === currentDocument.attachmentType,
    );

    const errors = this.getDocumentError();

    if (retrieveError || removeError || uploadError) {
      return <ErrorModal {...errors} reset={this.props.close} />;
    }

    return (
      <>
        {!showDeleteWarning ? (
          <Modal isOpen={true} className="upload-modal">
            <ModalHeader
              toggle={
                uploaded.length ? this.toggleDeleteWarning : this.props.close
              }
            >
              <span>{t('upload')}</span>
              <span>{` ${currentDocument.displayName}`}</span>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col className="ml-3">
                  {currentDocument.instructions !==
                    'No additional instructions' && (
                    <div
                      className={
                        (isMobile
                          ? 'mobile-detail-header '
                          : 'detail-header ') + 'mb-4'
                      }
                    >
                      {currentDocument.instructions}
                    </div>
                  )}
                </Col>
              </Row>
              {isMobile && !uploaded.length && !isUploading && (
                <Row className="mb-3">
                  <Col>
                    <div className="text-center font-weight-bold">
                      {t('common:instructions')}:
                    </div>
                    <ol>
                      <li>{t('mobileInstructions.step1')}</li>
                      <li>{t('mobileInstructions.step2')}</li>
                      <li>{t('mobileInstructions.step3')}</li>
                    </ol>
                  </Col>
                </Row>
              )}
              <Row className="text-center">
                <Col
                  xs={{ size: 10, offset: 1 }}
                  className={isMobile ? 'border p-4' : ''}
                >
                  {isUploading && <div className="overlay"></div>}
                  <div
                    className={
                      'upload-file-container ' +
                      (utils.checkFieldValidation(
                        `${currentDocument.documentGenerationId}`,
                        validated,
                      )
                        ? 'show-upload-error'
                        : '')
                    }
                  >
                    {isMobile && !uploaded.length && !isUploading && (
                      <div className="mobile-upload-area-header">
                        {t('takePhotoMobile')}
                      </div>
                    )}
                    <TitleImageUpload
                      id={currentDocument.documentGenerationId}
                      type={currentDocument.attachmentType}
                      attachments={uploaded}
                      upload={this.upload}
                      retrieve={this.retrieve}
                      remove={this.removeAndReset}
                      isUploading={isUploading}
                      onUploadRetrieved={this.onUploadRetrieved}
                      css={css}
                      i18n={i18n}
                      doubleCheckDelete={doubleCheckDelete}
                      cancelDoubleCheckDelete={
                        this.cancelImageDoubleCheckDelete
                      }
                    />
                    {isMobile && !uploaded.length && !isUploading && (
                      <div className="how-to-camera">{t('mobileHowTo')}</div>
                    )}
                  </div>
                </Col>
              </Row>
              {!!uploaded.length && (
                <>
                  {isImageBlurry && (
                    <Row>
                      <Col xs={{ size: 10, offset: 1 }}>
                        <div className="text-center alert-danger py-2">
                          <div className="font-weight-bold">
                            {t('common:warning')}:
                          </div>
                          <div className="mb-1">{t('imageBlurDetected')}</div>
                          <div className="unclear-image-instructions">
                            {t('unclearImageInstructions')}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row className="text-center">
                    <Col>
                      <CustomInput
                        type="checkbox"
                        defaultChecked={userUploadVerification}
                        className={
                          utils.checkFieldValidation(
                            'userUploadVerification',
                            validated,
                          ) + ' mt-4 mb-3 d-inline-block checkbox-lg'
                        }
                        id="userUploadVerification"
                        name="userUploadVerification"
                        label={
                          <Trans i18nKey="titleUpload:userUploadVerification">
                            <div className="pointer">
                              {' '}
                              <span className="font-weight-bold"> </span>{' '}
                              <span className="font-weight-bold"> </span>{' '}
                              <span className="font-weight-bold"> </span>
                            </div>
                          </Trans>
                        }
                        onChange={this.onUserUploadVerificationCheck}
                        required
                      />
                    </Col>
                  </Row>
                </>
              )}
            </ModalBody>
            <ModalFooter className="bg-white pt-1 px-2 px-md-4">
              <Row
                noGutters
                className="mt-4 w-100 d-flex justify-content-between"
              >
                {!!uploaded.length && (
                  <Col className="text-left">
                    <Button
                      color="outline-danger"
                      onClick={this.showImageDoublecheckDelete}
                      disabled={isUploading}
                    >
                      {t('buttons:replaceImage')}
                    </Button>
                  </Col>
                )}
                <Col className="text-right">
                  <Button
                    color="primary"
                    onClick={this.checkUploadVerification}
                    disabled={!uploaded.length}
                  >
                    {t('buttons:continue')}
                  </Button>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
        ) : (
          <CancelUploadModal
            {...this.props}
            close={this.props.close}
            remove={this.remove}
            toggleDeleteWarning={this.toggleDeleteWarning}
          />
        )}
      </>
    );
  }
}

UploadModal.propTypes = {
  close: PropTypes.func.isRequired,
  documents: PropTypes.array.isRequired,
  uploadedAttachments: PropTypes.array.isRequired,
  currentDocument: PropTypes.object.isRequired,
  updateUploadedAttachments: PropTypes.func.isRequired,
};

export default UploadModal;
