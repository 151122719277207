import { utils } from '../../../components';
import DetailsConfirmation from './DetailsConfirmation';
import AssetAuthorization from './AssetAuthorization';
import DocumentStepsContainer from './EsignSteps/DocumentSignSteps/DocumentStepsContainer';
import RequestSubmitted from './RequestSubmitted';
import UploadsContainer from './UploadSteps/UploadsContainer';
import MailDocuments from './MailDocuments';

export default [
  {
    title: 'detailConfirmation',
    component: DetailsConfirmation,
  },
  {
    title: 'assetAuthorization',
    component: AssetAuthorization,
  },
  {
    title: 'uploadDocuments',
    component: UploadsContainer,
  },
  {
    title: 'mailDocuments',
    component: MailDocuments,
    validation: (transferAgreement, stateConfig) =>
      utils
        .getValue('attachments', transferAgreement)
        .find(
          (attachment) => attachment.attachmentType === 'OWNER_SHIPPING_LABEL',
        ) && utils.getValue('existingLiens', transferAgreement).length === 0,
  },
  {
    title: 'signDocuments',
    component: DocumentStepsContainer,
  },
  {
    title: '',
    component: RequestSubmitted,
  },
];
