import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

const propTypes = {
  mssgs: PropTypes.bool,
};
const defaultProps = {
  mssgs: false,
};

class DefaultHeaderDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };
  }

  logout = () => {
    this.props.keycloak.logout();
  };

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  dropAccnt() {
    const { t } = this.props;
    return (
      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle nav>
          <i id="account" className="fa fa-user-o font-lg"></i>
        </DropdownToggle>
        <DropdownMenu left="true" className="dropdown-menu-lg">
          <DropdownItem id="logOut" className="pointer" onClick={this.logout}>
            <i className="fa fa-sign-out"></i>
            {t('common:logout')}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }

  render() {
    const { accnt } = this.props;

    return accnt ? this.dropAccnt() : null;
  }
}

DefaultHeaderDropdown.propTypes = propTypes;
DefaultHeaderDropdown.defaultProps = defaultProps;

export default withTranslation('pageHeader')(DefaultHeaderDropdown);
