export const initialState = {
  loginError: false,
  document: {},
  documentList: [],
  isLoadingDocument: false,
  documentError: false,
  signerVerify: [],
  isLoadingSignerVerify: false,
  signerVerifyError: false,
  isLoadingAccessToken: false,
  accessToken: {},
  accessTokenError: false,
};
