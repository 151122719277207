import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
} from 'reactstrap';

import { Trans } from 'react-i18next';

import signatureExampleGif from '../../../assets/img/signatureExample.gif';
import signatureExampleMp4 from '../../../assets/img/signatureExample.mp4';

import FingerTapIcon from './FingerTapIcon';
import MouseIcon from './MouseIcon';

class SignatureHelpModal extends Component {
  handleKeyPress = (e) => {
    if (
      [27].includes(e.keyCode) ||
      [27].includes(e.which) ||
      [27].includes(e.code)
    ) {
      if (this.props.close) this.props.close();
    }
  };

  render() {
    const { t, isOpen, close } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.handleKeyPress}
        className="medium-modal help-modal"
        autoFocus={true}
      >
        <ModalHeader
          className="border-bottom-0 justify-content-center help-header"
          toggle={close}
        >
          <div className="detail-header font-weight-bold">
            {t('signInstructions:howToSign')}
          </div>
        </ModalHeader>
        <ModalBody className="signature-help pt-0">
          <Row className="mb-4">
            <Col xs={12} sm={2} className="my-auto text-center">
              <FingerTapIcon />
            </Col>
            <Col xs={12} sm={10} className="my-auto">
              <Trans i18nKey="signInstructions:touchScreenInstructions">
                <div>
                  {' '}
                  <span className="font-weight-bold"> </span>{' '}
                  <span className="font-weight-bold"> </span>{' '}
                  <span className="font-weight-bold"> </span>{' '}
                </div>
              </Trans>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs={12} sm={2} className="my-auto text-center">
              <MouseIcon />
            </Col>
            <Col xs={12} sm={10} className="my-auto">
              <Trans i18nKey="signInstructions:mouseInstructions">
                <div>
                  {' '}
                  <span className="font-weight-bold"> </span>{' '}
                </div>
              </Trans>
            </Col>
          </Row>
          <Row className="my-2" noGutters>
            <Col xl={1} />
            <Col>
              <div className="document-card sample-box text-center pb-4">
                <div className="d-inline-block px-1 px-sm-3">
                  <p className="enhance-label text-left pt-2">
                    {t('landingPage:exampleSignature')}:
                  </p>
                  <video className="sample-img" autoPlay loop="loop">
                    <source src={signatureExampleMp4} type="video/mp4" />
                    <img
                      src={signatureExampleGif}
                      alt={t('signInstructions:sampleSignatureDrawing')}
                    />
                  </video>
                  <div className="sample-watermark">
                    {t('example').toUpperCase()}
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={1} />
          </Row>
        </ModalBody>
        <ModalFooter className="border-top-0 justify-content-center">
          <Row>
            <Col className="text-center">
              <Button color="secondary" onClick={close}>
                {t('buttons:close')}
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

SignatureHelpModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default SignatureHelpModal;
