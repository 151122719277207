import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Button, Card, CardBody, Col, CustomInput, Row } from 'reactstrap';

import { withTranslation } from 'react-i18next';

// import moment from 'moment';
import NumberFormat from 'react-number-format';
import { AllowedFieldWrapper } from '../../../Util/AllowedFieldWrapper';

import { AddressDisplayFormatter, utils, validator } from '../../../components';

import { ActionButton } from '../../../hooks/components';

import { ownerStepTransition } from '../pendingRequestsPageAnimations';
import defaultDetailsConfirmationFields from './Cards/CardDefaultConfigs/DetailsConfirmation';

const { actions, variants } = ownerStepTransition;

class DetailsConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      successfulSubmit: false,
      submitError: false,
      showDeclineModal: false,
      transferAgreement: props.transferAgreement || null,
      validated: {
        infoConfirmation: {
          validationCheck: 'checkbox',
          required: true,
          isValid: null,
        },
      },
    };

    this.auth = props.auth;
  }

  onCheck = (e) => {
    let { transferAgreement } = this.state;
    const { validated } = this.state;
    const {
      target: { name, checked },
    } = e;

    transferAgreement = utils.buildNestedObject(
      transferAgreement,
      name,
      checked,
    );
    const validateField = validated[name];
    const validatedField = {
      [name]: {
        ...validateField,
        isValid: checked,
      },
    };

    this.setState({
      transferAgreement,
      validated: {
        ...validated,
        ...validatedField,
      },
    });
  };

  checkAllValues = () => {
    let allValid = true;
    const { transferAgreement, validated } = this.state;
    const validatedKeys = Object.keys(validated);

    const validatedChecked = validatedKeys.reduce((acc, key) => {
      let isValid = false;
      const fieldValue = utils.getNestedObjectValue(key, transferAgreement);
      const validateField = validated[key];

      if (validateField.validationCheck === 'checkbox') {
        if (fieldValue !== undefined) {
          isValid = fieldValue;
        }
      } else {
        isValid = validator.validate(
          fieldValue,
          validateField.validationCheck,
          validateField.required,
        );
      }

      acc[key] = {
        ...acc[key],
        isValid,
      };

      if (!isValid) allValid = false;

      return acc;
    }, validated);

    if (!allValid) {
      this.setState({
        validated: validatedChecked,
      });
    } else {
      this.props.updateStep();
    }
  };

  continue = () => {
    this.props.updateStep();
  };

  getOwnerName = () => {
    const type = this.getValue('owner.type').toLowerCase();

    if (['company', 'trust'].includes(type)) return this.getValue('owner.name');

    const firstName = this.getValue('owner.firstName');
    const middleName = this.getValue('owner.middleName')
      ? this.getValue('owner.middleName')
      : '';
    const middleInitial =
      this.getValue('owner.middleInitial') && !this.getValue('owner.middleName')
        ? this.getValue('owner.middleInitial')
        : '';
    const lastName = this.getValue('owner.lastName');
    const suffix = `${this.getValue('owner.suffix') ? ', ' : ''}${
      this.getValue('owner.suffix') ? this.getValue('owner.suffix') : ''
    }`;

    const ownerName = `${firstName} ${middleName}${middleInitial} ${lastName}${suffix}`;

    return ownerName;
  };

  getValue = (path) => utils.getValue(path, this.state.transferAgreement);

  componentDidMount() {
    if (this.props.confirmInfo) {
      let { transferAgreement } = this.state;
      const { validated } = this.state;
      const name = 'infoConfirmation';
      const checked = true;
      transferAgreement = utils.buildNestedObject(
        transferAgreement,
        name,
        checked,
      );
      const validateField = validated[name];
      const validatedField = {
        [name]: {
          ...validateField,
          isValid: true,
        },
      };
      this.setState({
        transferAgreement,
        validated: {
          ...validated,
          ...validatedField,
        },
      });
    }
    window.scroll(0, 0);
  }

  render() {
    const { submitError, successfulSubmit, isSaving, validated } = this.state;
    const { t } = this.props;

    const readOnly =
      this.getValue('signedFormStatus.salvageAuthorizationSigned') ||
      this.getValue('transferAgreementStatus') === 'OWNER_DECLINED';
    const defaultChecked = this.getValue(
      'signedFormStatus.salvageAuthorizationSigned',
    );

    return (
      <motion.div
        id="detailsConfirmation"
        {...actions}
        variants={variants.mainContainer}
      >
        <Row noGutters className="d-flex justify-content-center">
          <Col className="step-card">
            <Row noGutters>
              <Col xs={12} md={5} className="mr-2">
                <Row noGutters>
                  <Col className="px-0">
                    <Card className="mb-2">
                      <CardBody className="p-2 p-md-2">
                        <Row noGutters className="mb-3">
                          <Col className="mb-0 mb-md-5">
                            <div className="detail-header">
                              {t('common:owner')}
                            </div>
                          </Col>
                        </Row>
                        <Row noGutters>
                          <Col xs={12} className="mb-4 mb-md-3 pr-1">
                            <div className="detail-sub-header">
                              {t('fullName')}
                            </div>
                            <div className="detail-text">
                              {this.getOwnerName()}
                            </div>
                          </Col>
                        </Row>

                        <AllowedFieldWrapper
                          allowedFields={defaultDetailsConfirmationFields}
                          genericEntity={this.state.transferAgreement}
                          path="owner.driverLicenseNumber"
                        >
                          <Row noGutters>
                            <Col xs={12} className="mb-4 mb-md-3 pr-1">
                              <div className="detail-sub-header">
                                {t('common:driverLicenseNumber')}
                              </div>
                              <div className="detail-text">
                                {this.getValue('owner.driverLicenseNumber')}
                              </div>
                            </Col>
                          </Row>
                        </AllowedFieldWrapper>

                        <Row noGutters>
                          <Col xs={12} className="mb-4 mb-md-3 pr-1">
                            <div className="detail-sub-header">
                              {t('address')}
                            </div>
                            <div className="detail-text">
                              {AddressDisplayFormatter.multiLine(
                                this.getValue('owner.address'),
                              )}
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row noGutters className="h-100 pb-md-2">
                  <Col className="px-0">
                    <Card className="mb-2 vehicle-details-card">
                      <CardBody className="p-2">
                        <Row>
                          <Col>
                            <Row noGutters className="mb-3">
                              <Col className="mb-0 mb-md-5">
                                <div className="detail-header">
                                  {t('common:vehicle')}
                                </div>
                              </Col>
                            </Row>
                            <Row noGutters>
                              <Col
                                xs={8}
                                sm={4}
                                md={8}
                                lg={8}
                                xl={4}
                                className="mb-4 mb-md-5 pr-1"
                              >
                                <div className="detail-sub-header">
                                  {t('common:vin')}
                                </div>
                                <div className="detail-text">
                                  {this.getValue('vehicle.vin')}
                                </div>
                              </Col>
                              <Col
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                className="mb-3 mb-md-5 pr-1"
                              >
                                <div className="detail-sub-header">
                                  {t('common:year')}
                                </div>
                                <div className="detail-text">
                                  {this.getValue('vehicle.year')}
                                </div>
                              </Col>

                              <Col
                                xs={8}
                                sm={4}
                                md={8}
                                lg={8}
                                xl={4}
                                className="mb-4 mb-md-5 pr-1"
                              >
                                <div className="detail-sub-header">
                                  {t('common:make')}
                                </div>
                                <div className="detail-text">
                                  {this.getValue('vehicle.make.name')}
                                </div>
                              </Col>

                              <AllowedFieldWrapper
                                allowedFields={defaultDetailsConfirmationFields}
                                genericEntity={this.state.transferAgreement}
                                path="vehicle.model"
                              >
                                <Col
                                  xs={4}
                                  sm={4}
                                  md={4}
                                  lg={4}
                                  xl={4}
                                  className="mb-0 mb-md-4 pr-1"
                                >
                                  <div className="detail-sub-header">
                                    {t('common:model')}
                                  </div>
                                  <div className="detail-text">
                                    {this.getValue('vehicle.model')}
                                  </div>
                                </Col>
                              </AllowedFieldWrapper>

                              <AllowedFieldWrapper
                                allowedFields={defaultDetailsConfirmationFields}
                                genericEntity={this.state.transferAgreement}
                                path="vehicle.registrationExpirationDate"
                              >
                                <Col
                                  xs={4}
                                  sm={4}
                                  md={4}
                                  lg={4}
                                  xl={4}
                                  className="mb-0 mb-md-4 pr-1"
                                >
                                  <div className="detail-sub-header">
                                    {t('common:dateRegistrationExpiration')}
                                  </div>
                                  <div className="detail-text">
                                    {moment(
                                      this.getValue(
                                        'vehicle.registrationExpirationDate',
                                      ),
                                    ).format('L')}
                                  </div>
                                </Col>
                              </AllowedFieldWrapper>

                              <AllowedFieldWrapper
                                allowedFields={defaultDetailsConfirmationFields}
                                genericEntity={this.state.transferAgreement}
                                path="vehicle.bodyType.name"
                              >
                                <Col
                                  xs={8}
                                  sm={4}
                                  md={8}
                                  lg={8}
                                  xl={4}
                                  className="mb-0 mb-md-4 pr-1"
                                >
                                  <div className="detail-sub-header">
                                    {t('common:bodyType')}
                                  </div>
                                  <div className="detail-text">
                                    {this.getValue('vehicle.bodyType.name')}
                                  </div>
                                </Col>
                              </AllowedFieldWrapper>

                              <AllowedFieldWrapper
                                allowedFields={defaultDetailsConfirmationFields}
                                genericEntity={this.state.transferAgreement}
                                path="vehicle.licensePlateNumber"
                              >
                                <Col
                                  xs={8}
                                  sm={4}
                                  md={8}
                                  lg={8}
                                  xl={4}
                                  className="mb-0 mb-md-4 pr-1"
                                >
                                  <div className="detail-sub-header">
                                    {t('common:licensePlate')}
                                  </div>
                                  <div className="detail-text">
                                    {this.getValue(
                                      'vehicle.licensePlateNumber',
                                    )}
                                  </div>
                                </Col>
                              </AllowedFieldWrapper>

                              <Col
                                xs={4}
                                sm={4}
                                md={4}
                                xl={4}
                                className="mb-0 mb-md-4 pr-1"
                              >
                                <div className="detail-sub-header">
                                  {t('common:mileage')}
                                </div>
                                <NumberFormat
                                  value={this.getValue(
                                    'vehicle.odometer.mileage',
                                  )}
                                  displayType="text"
                                  thousandSeparator
                                  renderText={(value) => (
                                    <span className="detail-text">{value}</span>
                                  )}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row noGutters>
              <Col>
                <Card className="mb-2">
                  <CardBody className="p-2">
                    <Row noGutters>
                      <Col className="text-center">
                        <p className="mb-0">{t('contactInsurer')}</p>
                      </Col>
                    </Row>
                    <Row noGutters className="mb-4 text-center">
                      <Col className="pt-3 px-0">
                        <CustomInput
                          type="checkbox"
                          defaultChecked={
                            defaultChecked ||
                            this.getValue('infoConfirmation') ||
                            this.props.confirmInfo
                          }
                          className={`
                            ${utils.checkFieldValidation(
                              'infoConfirmation',
                              validated,
                            )}  d-inline-block checkbox-lg pointer
                          `}
                          id="infoConfirmation"
                          name="infoConfirmation"
                          label={
                            <span className="pointer font-weight-bold">
                              {t('infoConfirmation')}
                            </span>
                          }
                          onChange={this.onCheck}
                          disabled={readOnly}
                          required
                        />
                      </Col>
                    </Row>
                    <Row noGutters>
                      <Col className="text-right">
                        <Button
                          color="link"
                          onClick={this.props.cancel}
                          disabled={isSaving}
                        >
                          {t('buttons:cancel')}
                        </Button>
                        <ActionButton
                          className="btn-primary"
                          isLoading={isSaving}
                          submitSuccess={successfulSubmit}
                          submitError={submitError}
                          onSubmitComplete={this.onSaveCompletion}
                          onClick={
                            readOnly ? this.continue : this.checkAllValues
                          }
                        >
                          {readOnly
                            ? t('buttons:continue')
                            : t('buttons:submit')}
                        </ActionButton>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </motion.div>
    );
  }
}

DetailsConfirmation.propTypes = {
  updateStep: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default withTranslation('detailsConfirmation')(DetailsConfirmation);
