import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';

import SignatureCanvas from 'react-signature-canvas';

import { withTranslation, Trans } from 'react-i18next';

import { ActionButton } from '../../hooks/components';

import { utils, validator } from '@champtitles/utilities';

class ESignaturePad extends Component {
  constructor(props) {
    super(props);

    window.addEventListener('resize', this.resizeCanvas);

    this.state = {
      isSaving: false,
      successfulSubmit: false,
      submitError: false,
      disabled: false,
      signature: {
        typedName: null,
      },
      emptySigError: false,
      sigPreviewUrl: null,
      canvasProps: props.canvasProps,
      validated: {
        typedName: {
          validationCheck: 'name',
          required: true,
          isValid: null,
        },
      },
      isMobile: /mobile/i.test(navigator.userAgent),
      mobileDevice: 'mobile',
    };

    this.auth = props.auth;
    this.signatureCanvasRef = createRef();
  }

  onChange = (e) => {
    let { signature, validated } = this.state;
    let {
      target: { name, value },
    } = e;

    signature = utils.buildNestedObject(signature, name, value);
    const validateField = validated[name];
    const validatedField = {
      [name]: {
        ...validateField,
        isValid: validator.validate(
          value,
          validateField.validationCheck,
          validateField.required,
        ),
      },
    };
    this.setState(
      {
        signature,
        validated: {
          ...validated,
          ...validatedField,
        },
        validationError: false,
      },
      () => {
        if (this.props.updateStatus) this.props.updateStatus('pending');
      },
    );
  };

  onBlur = (e) => {
    let { signature, validated } = this.state;
    let {
      target: { name, value },
    } = e;

    const validateField = validated[name];

    value = validator.sanitize(value, validateField.validationCheck);
    signature = utils.buildNestedObject(signature, name, value);

    const validatedField = {
      [name]: {
        ...validateField,
        isValid: validator.validate(
          value,
          validateField.validationCheck,
          validateField.required,
        ),
      },
    };
    this.setState(
      {
        signature,
        validated: {
          ...validated,
          ...validatedField,
        },
        validationError: false,
      },
      () => {
        if (this.props.updateStatus) this.props.updateStatus('pending');
      },
    );
  };

  clear = () => {
    const { disabled } = this.state;
    this.signatureCanvasRef.clear();
    if (!disabled) this.createPreview();
  };

  checkAllValues = () => {
    let allValid = true;
    const { signature, sigPreviewUrl, validated } = this.state;
    let validatedKeys = Object.keys(validated);

    let validatedChecked = validatedKeys.reduce((acc, key) => {
      const fieldValue = utils.getNestedObjectValue(key, signature);
      const validateField = validated[key];
      const isValid = validator.validate(
        fieldValue,
        validateField.validationCheck,
        validateField.required,
      );

      acc[key] = {
        ...acc[key],
        isValid,
      };

      if (!isValid) allValid = false;

      return acc;
    }, validated);

    if (this.signatureCanvasRef.isEmpty()) {
      allValid = false;
    }

    if (!allValid) {
      this.setState(
        {
          emptySigError: this.signatureCanvasRef.isEmpty(),
          validated: validatedChecked,
        },
        () => {
          if (this.props.updateStatus) this.props.updateStatus('error');
        },
      );
    } else {
      this.setState(
        {
          isSaving: true,
          signature: {
            data: this.dataURItoBlob(sigPreviewUrl),
            ...signature,
          },
        },
        () => {
          if (this.props.updateStatus) this.props.updateStatus('complete');
          this.props.updateParent(
            { signature: this.state.signature },
            this.props.submit,
          );
        },
      );
    }
  };

  onSubmitCompletion = () => {
    const { successfulSubmit, submitError } = this.state;

    if (successfulSubmit) {
      this.setState(
        {
          successfulSubmit: false,
        },
        () => {
          if (this.props.onSuccessfulSubmit) this.props.onSuccessfulSubmit();
        },
      );
    } else if (submitError) {
      this.setState(
        {
          submitError: false,
        },
        () => {
          if (this.props.onSubmitError) this.props.onSubmitError();
        },
      );
    }
  };

  dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);

    for (let i = 0, iLen = byteString.length; i < iLen; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  };

  resizeCanvas = () => {
    this.signatureCanvasRef._resizeCanvas();
    if (!this.state.disabled) this.createPreview();
  };

  createPreview = () => {
    const sigPreviewUrl = this.signatureCanvasRef
      .getTrimmedCanvas()
      .toDataURL();

    this.setState(
      {
        sigPreviewUrl,
      },
      () => {
        if (this.props.getPreview) this.props.getPreview(sigPreviewUrl);
      },
    );
  };

  toggleDisabled = () => {
    this.signatureCanvasRef[this.props.disabled ? 'off' : 'on']();
    this.setState(
      {
        disabled: this.props.disabled,
        emptySigError: false,
      },
      this.clear,
    );
  };

  onBegin = () => {
    document.activeElement.blur();
    if (this.props.onFocus) this.props.onFocus();
    this.setState({ emptySigError: false });
  };

  onEnd = () => {
    if (this.props.updateStatus) this.props.updateStatus('pending');
    this.createPreview();
  };

  getValue = (path) => utils.getValue(path, this.state.signature);

  static getDerivedStateFromProps(props, state) {
    if (
      JSON.stringify(props.canvasProps) !== JSON.stringify(state.canvasProps)
    ) {
      return { canvasProps: props.canvasProps };
    }
    return null;
  }

  componentDidUpdate() {
    if (this.props.disabled !== this.state.disabled) {
      this.toggleDisabled();
    }
  }

  componentDidMount() {
    const { isMobile } = this.state;

    if (this.props.disabled) {
      this.toggleDisabled();
    }

    if (isMobile && window.navigator.userAgent.match(/iPhone/i)) {
      this.setState({
        mobileDevice: 'iphone',
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeCanvas);
  }

  render() {
    const { cancel, submitLabel, t } = this.props;
    const {
      signature,
      validated,
      emptySigError,
      isSaving,
      successfulSubmit,
      submitError,
      canvasProps,
      isMobile,
      mobileDevice,
    } = this.state;

    return (
      <div id="eSign" className="mx-auto">
        <Row className="page-container">
          <Col>
            <Row className="my-2">
              <Col className="text-center font-weight-bold">
                <Trans i18nKey="eSign:instructions">
                  <span className="intensify-label"> </span>
                </Trans>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <FormGroup className="esign-field">
                  <Input
                    type="text"
                    value={this.getValue('typedName', signature)}
                    className={
                      'esign-input ' +
                      utils.checkFieldValidation('typedName', validated)
                    }
                    name="typedName"
                    disabled={this.props.disabled}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    onFocus={() => {
                      if (this.props.onFocus) {
                        this.props.onFocus();
                      }
                    }}
                    required
                  />
                  <Label className="form-control-placeholder">
                    {t('fullName')}
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex flex-column justify-content-center">
                <div className="font-weight-bold">{t('drawSignature')}:</div>
              </Col>
              <Col className="text-right">
                <div
                  className="d-flex justify-content-end align-items-center pointer ml-2 text-secondary help-button"
                  onClick={this.props.showHelpModal}
                >
                  <div className="help-icon mr-1 my-1">?</div>
                  <span className="help-text">
                    {t('signInstructions:howToSign')}
                  </span>
                </div>
              </Col>
            </Row>
            <Row noGutters>
              <Col>
                <div className="sig-canvas-container">
                  <SignatureCanvas
                    ref={(ref) => (this.signatureCanvasRef = ref)}
                    backgroundColor={
                      this.state.disabled
                        ? 'rgba(228, 231, 234, 1)'
                        : 'rgba(0,0,0,0)'
                    }
                    penColor="black"
                    onBegin={this.onBegin}
                    onEnd={this.onEnd}
                    clearOnResize={false}
                    canvasProps={canvasProps}
                  />
                  <div className="signature-bar">
                    <span className="signature-x">X</span>
                    <hr className="style-detail-black" />
                  </div>
                </div>
              </Col>
            </Row>
            {emptySigError ? (
              !this.state.disabled ? (
                <Row noGutters className="text-center alert-danger">
                  <Col className="py-2 px-2">
                    <i
                      className="fa fa-exclamation-circle pr-2"
                      aria-hidden="true"
                    ></i>
                    {t('emptySigError')}
                  </Col>
                </Row>
              ) : (
                <Row noGutters className="text-center alert-danger">
                  <Col className="py-2 px-2">
                    <i
                      className="fa fa-exclamation-circle pr-2"
                      aria-hidden="true"
                    ></i>
                    {t('disabledSigError')}
                  </Col>
                </Row>
              )
            ) : null}
            <Row className="mt-2">
              <Col xs={3} className="text-left">
                <Button
                  className="btn-secondary"
                  onClick={this.clear}
                  disabled={isSaving}
                >
                  {t('buttons:clear')}
                </Button>
              </Col>
              <Col className="text-right">
                <Button color="link" onClick={cancel} disabled={isSaving}>
                  {t('buttons:cancel')}
                </Button>
                <ActionButton
                  className="btn-primary"
                  isLoading={isSaving}
                  submitSuccess={successfulSubmit}
                  submitError={submitError}
                  onSubmitComplete={this.onSubmitCompletion}
                  onClick={
                    this.state.disabled
                      ? () => {
                          this.setState({ emptySigError: true });
                        }
                      : this.checkAllValues
                  }
                >
                  {t(`${submitLabel}`)}
                </ActionButton>
              </Col>
            </Row>
            {isMobile && (
              <Row noGutters className="mt-3 text-center rotate-msg">
                <Col>**{t(`portraitModeInstructions.${mobileDevice}`)}</Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

ESignaturePad.propTypes = {
  submit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
  updateParent: PropTypes.func.isRequired,
  canvasProps: PropTypes.object,
  cancel: PropTypes.func.isRequired,
  showHelpModal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

ESignaturePad.defaultProps = {
  canvasProps: { className: 'sig-canvas' },
  submitLabel: 'buttons:submit',
};

export default withTranslation('eSign')(ESignaturePad);
