import React from 'react';
import { string } from 'prop-types';

import logo from '../../assets/img/brand/ChampTitlesSecured.png';

const ChampSecuredLogo = ({ className }) => {
  return <img src={logo} alt="CHAMPtitles Secured" className={className}></img>;
};

ChampSecuredLogo.propTypes = {
  className: string,
};

ChampSecuredLogo.defaultProps = {
  className: '',
};

export default ChampSecuredLogo;
