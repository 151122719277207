import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import { success } from './responseAnimations';
import css from './responseAnimationsCss.scss';

const { variants, actions } = success;

Success.defaultProps = {
  labelPosition: 'BOTTOM',
};

Success.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelPosition: PropTypes.oneOf(['TOP', 'BOTTOM']),
};

export default function Success(props) {
  return (
    <motion.div {...actions} variants={variants.mainContainer}>
      {props.label && props.labelPosition === 'TOP' && (
        <div className="text-center mb-2 success-phrase">{props.label}</div>
      )}
      <div className="animated-checkbox mb-3">
        <svg
          className="checkmark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            className="checkmark__circle"
            cx="26"
            cy="26"
            r="25"
            stroke={css.primary}
            strokeDasharray="166"
            fill="none"
          />
          <path
            className="checkmark__check"
            stroke={css.primary}
            strokeDasharray="48"
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
      </div>
      {props.label && props.labelPosition === 'BOTTOM' && (
        <div className="text-center mb-2 success-phrase">{props.label}</div>
      )}
    </motion.div>
  );
}
