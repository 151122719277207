import React, { useState, useEffect } from 'react';
import { UploadContainer } from '@champtitles/upload';

const TitleImageUpload = ({
  id,
  css,
  i18n,
  upload,
  retrieve,
  remove,
  onUploadRetrieved,
  type,
  attachments,
  doubleCheckDelete,
  cancelDoubleCheckDelete,
}) => {
  const [availableAttachments, setAvailableAttachments] = useState(attachments);

  const uploadFile = async (file) => {
    return await upload(file, type, id);
  };

  const retrieveFile = async (file) => {
    return await retrieve(file);
  };

  const removeFile = async (id) => {
    return await remove(id);
  };

  useEffect(() => {
    const available = attachments.filter(
      (attachment) => attachment.attachmentType === type,
    );
    const ids = availableAttachments.map((attachment) => attachment.id);
    const noChanges = available.every((attachment) =>
      ids.includes(attachment.id),
    );
    if (!noChanges) setAvailableAttachments(available);
  }, [attachments, availableAttachments, type]);

  return (
    <UploadContainer
      id={id}
      upload={uploadFile}
      retrieve={retrieveFile}
      remove={removeFile}
      onUploadRetrieved={onUploadRetrieved}
      maxFiles={1}
      attachments={availableAttachments}
      allowedFileTypes={['jpg', 'jpeg', 'png', 'pdf']}
      autoUpload={true}
      css={css}
      i18n={i18n}
      doubleCheckDelete={doubleCheckDelete}
      cancelDoubleCheckDelete={cancelDoubleCheckDelete}
    />
  );
};

export default TitleImageUpload;
