import React, { Component } from 'react';
import { motion } from 'framer-motion';
import { withTranslation } from 'react-i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ErrorModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
    };
  }

  close = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });

    if (this.props.reset) this.props.reset();
  };

  render() {
    const { t } = this.props;

    return (
      <motion.div
        exit={{
          opacity: 0,
        }}
      >
        <Modal isOpen={this.state.isOpen}>
          <ModalHeader>
            {this.props.header ? this.props.header : t('header')}
          </ModalHeader>
          <ModalBody>{this.props.body ? this.props.body : t('body')}</ModalBody>
          <ModalFooter className="d-flex justify-content-center px-0">
            <Button color="danger" onClick={() => this.close()}>
              {t('common:close')}
            </Button>
          </ModalFooter>
        </Modal>
      </motion.div>
    );
  }
}

export default withTranslation('errorModal')(ErrorModal);
