import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';

import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import 'react-datepicker/dist/react-datepicker.css';

import { utils } from '@champtitles/utilities';

class CustomDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      [props.name]: props.defaultValue || null,
      selected: props.defaultValue || null,
    };

    this.datePickerRef = React.createRef();
  }

  onNumberFormatChange = (values) => {
    let { value } = values;
    const { selected } = this.state;

    if (value.length < 8 && selected !== '') {
      this.setState(
        {
          [this.props.name]: value,
          selected: '',
        },
        this.callback,
      );
    } else {
      this.setState({
        [this.props.name]: value,
      });
    }
  };

  select = (date) => {
    if (date)
      this.setState({ [this.props.name]: date, selected: date }, this.callback);
    else this.setState({ [this.props.name]: '', selected: '' }, this.callback);
  };

  callback = () => {
    const dataset = utils.buildDataset(this.props);

    this.props.onSelect({
      target: {
        name: this.props.name,
        value: this.state.selected,
        dataset: {
          ...dataset,
        },
      },
    });
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 9 || e.which === 9 || e.code === 9) {
      this.datePickerRef.setOpen(false);
    }
  };

  renderCustomInput() {
    let { dateFormat } = this.props;
    return (
      <NumberFormat
        format={this.getFormat(dateFormat)}
        mask={this.getMasking(dateFormat)}
        onValueChange={this.onNumberFormatChange}
      />
    );
  }

  getFormat = (dateFormat) => {
    return dateFormat.replace(/[^/]/g, '#');
  };

  getMasking = (dateFormat) => {
    let mask = dateFormat.replaceAll('/', '').split('');
    return mask.map((char) => char.toUpperCase());
  };

  componentDidUpdate() {
    if (this.props.reset) this.setState({ selected: null });
  }

  render() {
    return (
      <FormGroup
        className={
          (this.state[this.props.name] ? 'date-selected ' : '') +
          (this.props.formGroupClassName ? this.props.formGroupClassName : '')
        }
      >
        <DatePicker
          ref={(ref) => (this.datePickerRef = ref)}
          id={this.props.id}
          selected={this.state.selected}
          className={'form-control ' + this.props.className}
          name={this.props.name}
          value={this.state[this.props.name]}
          autoComplete="off"
          dateFormat={this.props.dateFormat}
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          onChange={this.select}
          onKeyDown={this.handleKeyDown}
          disabled={this.props.disabled}
          required={this.props.required || false}
          showYearDropdown={this.props.showYearDropdown}
          showMonthDropdown={this.props.showMonthDropdown}
          dropdownMode={this.props.dropdownMode}
          customInput={this.renderCustomInput()}
        />
        <Label className="form-control-placeholder">{this.props.label}</Label>
      </FormGroup>
    );
  }
}

CustomDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
  label: PropTypes.string,
};

CustomDatePicker.defaultProps = {
  dateFormat: 'MM/dd/yyyy',
};

export default CustomDatePicker;
