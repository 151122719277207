import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {
    // eslint-disable-next-line
    const { t, children, ...attributes } = this.props;

    return (
      <>
        <span style={{ fontSize: 12 }}>CHAMP &copy; 2020</span>
        <div className="policy-tags-container">
          <a
            style={{ fontSize: 12 }}
            className="text-secondary p-2"
            href="/policy"
            target="_blank"
          >
            {t('policy:policy')}
          </a>
          <a
            style={{ fontSize: 12 }}
            className="text-secondary"
            href="/terms"
            target="_blank"
          >
            {t('policy:terms')}
          </a>
        </div>
        <div className="powered-by-container">
          <span style={{ fontSize: 12 }}>
            {t('poweredBy')}{' '}
            <a
              href="https://www.champtitles.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              CHAMP
            </a>
          </span>
          <div style={{ fontSize: 10 }} className="version-container">
            {t('version')} 1.0
          </div>
        </div>
      </>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
