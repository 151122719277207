import React from 'react';
import { motion } from 'framer-motion';

import { Row } from 'reactstrap';

import logo from '../../../assets/img/brand/ChampTitlesLogo.png';

import { spinner } from './pageLoaderAnimations';

export default function PageLoader() {
  return (
    <Row id="page-loader">
      <div className="loader-container">
        <motion.span
          className="loader-spinner"
          animate={spinner.animate}
          transition={spinner.transition}
        />
        <img src={logo} alt="CHAMPtitles"></img>
      </div>
    </Row>
  );
}
