import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { withTranslation } from 'react-i18next';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';

// Import Font Awesome Icons Set
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/css/v4-shims.min.css';
// Import Main styles for this application
import css from './scss/style.css';

// Containers
import { DefaultLayout, CocLayout } from './containers';

import i18n from './i18n';
import Policy from './components/Privacy/Policy';
import Terms from './components/Privacy/Terms';

function App({ t }) {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/policy"
          component={(props) => (
            <Policy
              {...props}
              changelanguage={changeLanguage}
              i18n={i18n}
              css={css}
            />
          )}
        />
        <Route
          path="/terms"
          component={(props) => (
            <Terms
              {...props}
              changelanguage={changeLanguage}
              i18n={i18n}
              css={css}
            />
          )}
        />
        <Route
          path="/verify"
          component={(props) => (
            <CocLayout
              {...props}
              changelanguage={changeLanguage}
              i18n={i18n}
              css={css}
            />
          )}
        />
        <Route
          path="/"
          component={(props) => (
            <DefaultLayout
              {...props}
              changelanguage={changeLanguage}
              i18n={i18n}
              css={css}
            />
          )}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default withTranslation()(App);
