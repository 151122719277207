import { isNil } from 'lodash';
import ALConfig from './AL';
import TXConfig from './TX';
import CAConfig from './CA';
import GAConfig from './GA';
import WIConfig from './WI';
import VAConfig from './VA';

const stateConfigs = {
  AL: ALConfig,
  TX: TXConfig,
  CA: CAConfig,
  GA: GAConfig,
  WI: WIConfig,
  VA: VAConfig,
};

export const getStateConfig = (state) => checkStateExistsAndReturn(state);

const checkStateExistsAndReturn = (state) => {
  const stateConfig = stateConfigs[state];
  return !isNil(stateConfig) ? stateConfig : undefined;
};
