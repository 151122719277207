import styled from 'styled-components';

const ICON_WIDTH = 35;
const TEXT_WIDTH_LG = 200;
const TEXT_WIDTH_MD = 140;
const TEXT_WIDTH_SM = 75;

export const StyledStepTrackerContainer = styled.div`
  display: ${(props) =>
    props.currentStep < props.trackerSteps.length || !props.isMobile
      ? 'flex'
      : 'none'};
`;

export const StyledLinearTrackerContainer = styled.div`
  display: ${(props) => (props.isMobile ? 'none' : 'inline')};

  @media screen and (max-width: 576px) {
    display: none;
  }
`;

export const StyledRadialTrackerContainer = styled.div`
  display: ${(props) => (props.isMobile ? 'inline' : 'none')};

  @media screen and (max-width: 576px) {
    display: inline;
  }
`;

export const StyledLinearStepTracker = styled.div`
  max-width: ${(props) => (props.totalSteps < 4 ? '800px' : '1200px')};
`;

export const StyledLineContainer = styled.div`
  width: calc(
    ${(props) =>
      100 / props.totalLines +
      '% - ' +
      (ICON_WIDTH * props.totalLines + TEXT_WIDTH_LG) / props.totalLines +
      'px'}
  );

  @media screen and (max-width: 991px) {
    width: calc(
      ${(props) =>
        100 / props.totalLines +
        '% - ' +
        (ICON_WIDTH * props.totalLines + TEXT_WIDTH_MD) / props.totalLines +
        'px'}
    );
  }

  @media screen and (max-width: 576px) {
    width: calc(
      ${(props) =>
        100 / props.totalLines +
        '% - ' +
        (ICON_WIDTH * props.totalLines + TEXT_WIDTH_SM) / props.totalLines +
        'px'}
    );
  }
`;

export const StyledButtonHolder = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  margin: 0 auto auto;
  align-items: center;

  .arrow-button {
    @media screen and (max-width: 1200px) {
      font-size: calc(20% + 0.8vw);
      padding: 0.13rem 0.2rem;
    }
    @media screen and (max-width: 535px) {
      font-size: calc(20% + 0.7vw);
    }
    @media screen and (max-width: 450px) {
      font-size: calc(10% + 0.7vw);
    }
    @media screen and (max-width: 400px) {
      font-size: calc(10% + 0.5vw);
    }
  }
`;
