import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Card, CardBody, Col, Row } from 'reactstrap';

import moment from 'moment';
import NumberFormat from 'react-number-format';

import {
  ErrorModal,
  PrintMenu,
  ResponseAnimations,
  utils,
} from '../../components';

import { PageLoader } from '../../hooks/components';

import { completedRequestDetails } from './completedRequestsPageAnimations';

import { AllowedFieldWrapper } from '../../Util/AllowedFieldWrapper';
import defaultDetailsConfirmationFields from '../PendingRequests/OwnerSteps/Cards/CardDefaultConfigs/DetailsConfirmation';

const { actions, variants } = completedRequestDetails;

class CompletedRequestDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      transferAgreement: props.transferAgreement || null,
    };
    this.auth = props.auth;
  }

  getVehicleDetails = () => {
    const { vehicle } = this.state.transferAgreement;
    return ['year', 'make.name', 'model', 'color']
      .reduce((acc, cur) => {
        const val = utils.getValue(cur, vehicle);
        if (val) acc.push(val);
        return acc;
      }, [])
      .join(' / ');
  };

  getOwnerName = () => {
    const type = this.getValue('owner.type').toLowerCase();

    if (['company', 'trust'].includes(type)) return this.getValue('owner.name');

    const firstName = this.getValue('owner.firstName');
    const middleName = this.getValue('owner.middleName')
      ? this.getValue('owner.middleName')
      : '';
    const middleInitial =
      this.getValue('owner.middleInitial') && !this.getValue('owner.middleName')
        ? `${this.getValue('owner.middleInitial')}`
        : '';
    const lastName = this.getValue('owner.lastName');
    const suffix = `${this.getValue('owner.suffix') ? ', ' : ''}${
      this.getValue('owner.suffix') ? this.getValue('owner.suffix') : ''
    }`;

    const ownerName = `${firstName} ${middleName}${middleInitial} ${lastName}${suffix}`;

    return ownerName;
  };

  getValue = (path) => utils.getValue(path, this.state.transferAgreement);

  async componentDidMount() {
    try {
      const transferAgreementId = this.state.transferAgreement.id;
      const transferAgreement = await this.auth.fetch(
        `/transferagreements/${transferAgreementId}`,
      );

      this.setState({
        transferAgreement,
        isLoading: false,
      });
    } catch {
      this.setState({
        loadError: true,
        isLoading: false,
      });
    }
  }

  render() {
    const { t } = this.props;
    const { isLoading, loadError, transferAgreement } = this.state;

    if (isLoading) return <PageLoader />;
    if (loadError) return <ErrorModal />;

    return (
      <motion.div
        id="completedRequest"
        className="step-card-container"
        {...actions}
        variants={variants.mainContainer}
      >
        <Row noGutters className="d-flex justify-content-center">
          <Col className="step-card">
            <Row noGutters>
              <Col xs={12} md={6} className="pb-2 pr-0 pr-md-2">
                <Card className="h-100">
                  <CardBody>
                    <Row noGutters>
                      <Col className="mb-2">
                        <div className="detail-header">
                          {t('requestDetails')}
                        </div>
                      </Col>
                    </Row>
                    <Row noGutters>
                      <Col
                        xs={12}
                        sm={6}
                        md={12}
                        lg={12}
                        xl={6}
                        className="mb-2"
                      >
                        <Row noGutters>
                          <Col>
                            <div className="detail-sub-header">
                              {t('common:owner')}
                            </div>
                          </Col>
                        </Row>
                        <Row noGutters>
                          <Col className="detail-text">
                            <div>{this.getOwnerName()}</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        md={12}
                        lg={12}
                        xl={6}
                        className="mb-2"
                      >
                        <Row noGutters>
                          <Col>
                            <div className="detail-sub-header">
                              {t('dateCompleted')}
                            </div>
                          </Col>
                        </Row>
                        <Row noGutters>
                          <Col className="detail-text">
                            <div>
                              {this.getValue('ownerReviewCompleteDate') &&
                                moment(
                                  this.getValue('ownerReviewCompleteDate'),
                                ).format('L')}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <AllowedFieldWrapper
                        allowedFields={defaultDetailsConfirmationFields}
                        genericEntity={this.state.transferAgreement}
                        path="owner.driverLicenseNumber"
                      >
                        <Col
                          xs={12}
                          sm={6}
                          md={12}
                          lg={12}
                          xl={12}
                          className="mb-2"
                        >
                          <Row noGutters>
                            <Col>
                              <div className="detail-sub-header">
                                {t('driverLicenseNumber')}
                              </div>
                            </Col>
                          </Row>
                          <Row noGutters>
                            <Col className="detail-text">
                              <div>
                                {this.getValue('owner.driverLicenseNumber')}
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </AllowedFieldWrapper>
                      <Col
                        xs={12}
                        sm={6}
                        md={12}
                        lg={12}
                        xl={12}
                        className="mb-2"
                      >
                        <Row noGutters>
                          <Col>
                            <div className="detail-sub-header">
                              {t('insurer')}
                            </div>
                          </Col>
                        </Row>
                        <Row noGutters>
                          <Col className="detail-text">
                            <div>{this.getValue('insurancePolicy.insuranceCompanyName')}</div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col className="pb-2">
                <Card className="h-100">
                  <CardBody>
                    <Row>
                      <Col>
                        <Row noGutters>
                          <Col className="mb-2">
                            <div className="detail-header">
                              {t('vehicleDetails')}
                            </div>
                          </Col>
                        </Row>
                        <Row noGutters>
                          <Col
                            xs={8}
                            sm={4}
                            md={8}
                            lg={8}
                            xl={4}
                            className="mb-2 pr-1"
                          >
                            <div className="detail-sub-header">
                              {t('common:vin')}
                            </div>
                            <div className="detail-text">
                              {this.getValue('vehicle.vin')}
                            </div>
                          </Col>
                          <Col
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            className=" mb-2pr-1"
                          >
                            <div className="detail-sub-header">
                              {t('common:year')}
                            </div>
                            <div className="detail-text">
                              {this.getValue('vehicle.year')}
                            </div>
                          </Col>

                          <Col
                            xs={8}
                            sm={4}
                            md={8}
                            lg={8}
                            xl={4}
                            className="mb-2 pr-1"
                          >
                            <div className="detail-sub-header">
                              {t('common:make')}
                            </div>
                            <div className="detail-text">
                              {this.getValue('vehicle.make.name')}
                            </div>
                          </Col>

                          <AllowedFieldWrapper
                            allowedFields={defaultDetailsConfirmationFields}
                            genericEntity={this.state.transferAgreement}
                            path="vehicle.model"
                          >
                            <Col
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              className="mb-0 mb-md-4 pr-1"
                            >
                              <div className="detail-sub-header">
                                {t('common:model')}
                              </div>
                              <div className="detail-text">
                                {this.getValue('vehicle.model')}
                              </div>
                            </Col>
                          </AllowedFieldWrapper>

                          <AllowedFieldWrapper
                            allowedFields={defaultDetailsConfirmationFields}
                            genericEntity={this.state.transferAgreement}
                            path="vehicle.registrationExpirationDate"
                          >
                            <Col
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              xl={4}
                              className="mb-0 mb-md-4 pr-1"
                            >
                              <div className="detail-sub-header">
                                {t('common:dateRegistrationExpiration')}
                              </div>
                              <div className="detail-text">
                                {moment(
                                  this.getValue(
                                    'vehicle.registrationExpirationDate',
                                  ),
                                ).format('L')}
                              </div>
                            </Col>
                          </AllowedFieldWrapper>

                          <AllowedFieldWrapper
                            allowedFields={defaultDetailsConfirmationFields}
                            genericEntity={this.state.transferAgreement}
                            path="vehicle.bodyType.name"
                          >
                            <Col
                              xs={8}
                              sm={4}
                              md={8}
                              lg={8}
                              xl={4}
                              className="mb-0 mb-md-4 pr-1"
                            >
                              <div className="detail-sub-header">
                                {t('common:bodyType')}
                              </div>
                              <div className="detail-text">
                                {this.getValue('vehicle.bodyType.name')}
                              </div>
                            </Col>
                          </AllowedFieldWrapper>

                          <AllowedFieldWrapper
                            allowedFields={defaultDetailsConfirmationFields}
                            genericEntity={this.state.transferAgreement}
                            path="vehicle.licensePlateNumber"
                          >
                            <Col
                              xs={8}
                              sm={4}
                              md={8}
                              lg={8}
                              xl={4}
                              className="mb-0 mb-md-4 pr-1"
                            >
                              <div className="detail-sub-header">
                                {t('common:licensePlate')}
                              </div>
                              <div className="detail-text">
                                {this.getValue('vehicle.licensePlateNumber')}
                              </div>
                            </Col>
                          </AllowedFieldWrapper>

                          <Col
                            xs={4}
                            sm={4}
                            md={4}
                            xl={4}
                            className="mb-2 pr-1"
                          >
                            <div className="detail-sub-header">
                              {t('common:mileage')}
                            </div>
                            <NumberFormat
                              value={this.getValue('vehicle.odometer.mileage')}
                              displayType="text"
                              thousandSeparator
                              renderText={(value) => (
                                <span className="detail-text">{value}</span>
                              )}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Card>
              <CardBody>
                {this.getValue('transferAgreementStatus') !==
                'OWNER_DECLINED' ? (
                  <>
                    <Row className="submitted-status mb-4">
                      <Col sm={0} lg={1} />
                      <Col className="d-flex">
                        <span className="mr-3">{t('common:status')}:</span>
                        <span className="submitted-checkmark">
                          <ResponseAnimations.Checkmark
                            {...this.props}
                            className="submitted-svg"
                          />
                        </span>
                        <span className="text-primary ml-2">
                          {t('common:completed')}
                        </span>
                      </Col>
                      <Col sm={0} lg={1} />
                    </Row>
                    <Row className="my-4">
                      <Col sm={0} lg={1} />
                      <Col>
                        <div>{t('requestSubmitted:successMsg')}</div>
                      </Col>
                      <Col sm={0} lg={1} />
                    </Row>
                    <Row>
                      <Col sm={0} lg={1} />
                      {this.getValue('documentsViewable') ? (
                        <Col>
                          <Row className="mb-4">
                            <Col>
                              <div className="print-msg">
                                {t('requestSubmitted:printDocumentsMsg')}
                              </div>
                            </Col>
                          </Row>
                          <PrintMenu
                            {...this.props}
                            transferAgreement={transferAgreement}
                          />
                        </Col>
                      ) : (
                        <Col className="mb-4">
                          {this.getValue('multiOwner') ? (
                            <div className="print-msg">
                              {t('requestSubmitted:documentsMsgMultiOwner')}
                            </div>
                          ) : (
                            <div className="print-msg">
                              {t('requestSubmitted:documentsMsg')}
                            </div>
                          )}
                        </Col>
                      )}
                      <Col sm={0} lg={1} />
                    </Row>
                  </>
                ) : (
                  <Row className="mb-4 text-center">
                    <Col md={1} />
                    <Col>
                      <div className="decline-notice">
                        {t('actionStatuses:OWNER_DECLINED')}
                      </div>
                      <div>
                        {t('common:reason')}:{' '}
                        <span className="detail-text">
                          {this.getValue('transferAgreementStatusReason')}
                        </span>
                      </div>
                    </Col>
                    <Col md={1} />
                  </Row>
                )}
                <Row className="text-right">
                  <Col sm={0} lg={1} />
                  <Col>
                    <motion.button
                      className="btn btn-secondary px-5"
                      onClick={this.props.cancel}
                    >
                      {t('common:done')}
                    </motion.button>
                  </Col>
                  <Col sm={0} lg={1} />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </motion.div>
    );
  }
}

CompletedRequestDetails.propTypes = {
  cancel: PropTypes.func.isRequired,
};

export default CompletedRequestDetails;
