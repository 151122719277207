import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

import {
  StyledStepTrackerContainer,
  StyledLinearTrackerContainer,
  StyledRadialTrackerContainer,
} from './Styles.js';

import LinearStepTracker from './LinearStepTracker/LinearStepTracker';
import RadialStepTracker from './RadialStepTracker/RadialStepTracker';

export default function StepTrackerContainer(props) {
  const isMobile =
    /mobile/i.test(navigator.userAgent) &&
    !/ipad|tablet/i.test(navigator.userAgent);

  return (
    <div id="stepTrackerContainer">
      <Row>
        <Col>
          <StyledStepTrackerContainer {...props} isMobile={isMobile}>
            <Card className="w-100 mb-2">
              <CardBody className="p-0">
                <StyledLinearTrackerContainer
                  className="linear-step-tracker"
                  isMobile={isMobile}
                >
                  <LinearStepTracker {...props} />
                </StyledLinearTrackerContainer>
                <StyledRadialTrackerContainer
                  className="radial-step-tracker"
                  isMobile={isMobile}
                >
                  <RadialStepTracker {...props} />
                </StyledRadialTrackerContainer>
              </CardBody>
            </Card>
          </StyledStepTrackerContainer>
        </Col>
      </Row>
    </div>
  );
}
