import {
  SET_LOGIN_ERROR,
  FETCH_ACCESS_TOKEN_LOADING,
  FETCH_ACCESS_TOKEN_SUCCESS,
  FETCH_ACCESS_TOKEN_ERROR,
  FETCH_DOCUMENT_LOADING,
  FETCH_DOCUMENT_SUCCESS,
  FETCH_DOCUMENT_ERROR,
  FETCH_SIGNER_VERIFY_LOADING,
  FETCH_SIGNER_VERIFY_SUCCESS,
  FETCH_SIGNER_VERIFY_ERROR,
  UPDATE_VERIFY_SIGNERS,
  RESET_COC_PORTAL,
} from '../actions/cocPortalActions';

import { initialState } from '../initialStates/cocPortal';

const cocPortalReducer = (state, action) => {
  const { cocPortal } = state;
  switch (action.type) {
    case SET_LOGIN_ERROR:
      return {
        ...state,
        cocPortal: {
          ...cocPortal,
          loginError: action.payload,
        },
      };
    case FETCH_ACCESS_TOKEN_LOADING:
      return {
        ...state,
        cocPortal: {
          ...cocPortal,
          isLoadingAccessToken: true,
        },
      };
    case FETCH_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        cocPortal: {
          ...cocPortal,
          isLoadingAccessToken: false,
          accessToken: action.payload,
          accessTokenError: false,
        },
      };
    case FETCH_ACCESS_TOKEN_ERROR:
      return {
        ...state,
        cocPortal: {
          ...cocPortal,
          isLoadingAccessToken: false,
          accessTokenError: true,
        },
      };
    case FETCH_DOCUMENT_LOADING:
      return {
        ...state,
        cocPortal: {
          ...cocPortal,
          isLoadingDocument: true,
        },
      };
    case FETCH_DOCUMENT_SUCCESS:
      return {
        ...state,
        cocPortal: {
          ...cocPortal,
          isLoadingDocument: false,
          document: action.payload,
          documentList: action.payload.documentList,
          documentError: false,
        },
      };
    case FETCH_DOCUMENT_ERROR:
      return {
        ...state,
        cocPortal: {
          ...cocPortal,
          isLoadingDocument: false,
          documentError: action.payload,
        },
      };
    case FETCH_SIGNER_VERIFY_LOADING:
      return {
        ...state,
        cocPortal: {
          ...cocPortal,
          isLoadingSignerVerify: true,
        },
      };
    case FETCH_SIGNER_VERIFY_SUCCESS:
      return {
        ...state,
        cocPortal: {
          ...cocPortal,
          isLoadingSignerVerify: false,
          signerVerifyError: false,
        },
      };
    case FETCH_SIGNER_VERIFY_ERROR:
      return {
        ...state,
        cocPortal: {
          ...cocPortal,
          isLoadingSignerVerify: false,
          signerVerifyError: true,
        },
      };
    case UPDATE_VERIFY_SIGNERS:
      return {
        ...state,
        cocPortal: {
          ...cocPortal,
          signerVerify: action.payload,
        },
      };
    case RESET_COC_PORTAL:
      return {
        ...state,
        cocPortal: initialState,
      };
    default:
      return state;
  }
};

export default cocPortalReducer;

// selectors
export const getCocPortal = (state) => state.cocPortal;

export const getLoginError = (state) => {
  const cocPortal = getCocPortal(state);
  return cocPortal.loginError || false;
};

export const getAccessTokenError = (state) => {
  const cocPortal = getCocPortal(state);
  return cocPortal.accessTokenError || false;
};

export const getAccessTokenLoading = (state) => {
  const cocPortal = getCocPortal(state);
  return cocPortal.accessTokenLoading || false;
};

export const getAccessToken = (state) => {
  const cocPortal = getCocPortal(state);
  return cocPortal.accessToken || {};
};

export const getDocumentError = (state) => {
  const cocPortal = getCocPortal(state);
  return cocPortal.documentError || false;
};

export const getDocumentLoading = (state) => {
  const cocPortal = getCocPortal(state);
  return cocPortal.isLoadingDocument || false;
};

export const getDocument = (state) => {
  const cocPortal = getCocPortal(state);
  return cocPortal.document || {};
};

export const getSigners = (state) => {
  const document = getDocument(state);
  return document.signers || [];
};

export const getDocumentInitiator = (state) => {
  const document = getDocument(state);
  return document.insurerName || '';
};

export const getDocumentId = (state) => {
  const document = getDocument(state);
  return document.id || '';
};

export const getDocumentName = (state) => {
  const documentName = getCocPortal(state);
  return documentName.documentList || [];
};

// FIXME: this value should come from the api
// return date of last signature on TA
export const getCompletionDate = (state) => {
  const signers = getSigners(state);
  const signatures = [];

  signers.forEach((signer) => {
    signer.signatures.forEach((s) => {
      signatures.push(s.signedDateTime);
    });
  });

  const sortedSignatureDates = signatures.sort(
    (a, b) => new Date(b) - new Date(a),
  );

  return sortedSignatureDates[0];
};

export const getSignerVerify = (state) => {
  const cocPortal = getCocPortal(state);
  return cocPortal.signerVerify || {};
};

export const getSignerVerifyError = (state) => {
  const cocPortal = getCocPortal(state);
  return cocPortal.signerVerifyError || false;
};
