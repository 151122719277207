const printButtons = {
  hover: {
    scale: 1.05,
  },
  tap: {
    scale: 0.95,
  },
};

export { printButtons };
