const success = {
  actions: {
    animate: 'open',
    exit: 'closed',
    initial: 'closed',
  },
  variants: {
    mainContainer: {
      open: {
        opacity: 1,
      },
      closed: {
        opacity: 0,
      },
    },
  },
};

export { success };
