import React from 'react';

import Store from '../../reducers/store';
import CocContainer from '../../views/CocPortal/CocContainer';

const CocLayout = () => {
  return (
    <Store>
      <div className="app coc-app">
        <div className="app-body">
          <main className="main">
            <CocContainer />
          </main>
        </div>
      </div>
    </Store>
  );
};

export default CocLayout;
