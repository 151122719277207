const spinner = {
  transition: {
    loop: Infinity,
    ease: 'linear',
    duration: 0.6,
  },
  animate: {
    rotate: 360,
  },
};

export { spinner };
