import React, { useContext } from 'react';
import { func } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

// reducers
import { Context } from '../../../../reducers/store';
import {
  getDocumentName,
  getSigners,
} from '../../../../reducers/cocPortalReducer';

import SignerTable from './SignerTable';

const SignatureInformation = ({ t }) => {
  const [state] = useContext(Context);
  const signer = getSigners(state);
  const documents = getDocumentName(state);

  const signerTable = (s, i) => (
    <SignerTable
      key={`signer-table-${s.id}`}
      documents={documents}
      signer={s}
      index={i}
    />
  );

  return (
    <Row className="section">
      <Col>
        <Row className="section-header">
          <h4>{t('cocPortal:signatureInformation.title')}</h4>
        </Row>
        <Row>
          <Col>
            {signer &&
              signer.length > 0 &&
              signer.map((s, i) => signerTable(s, i))}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

SignatureInformation.propTypes = {
  t: func.isRequired,
};

export default withTranslation()(SignatureInformation);
