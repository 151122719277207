import React, { Fragment, useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import css from '../stepIcon.scss';

export default function StepIcon(props) {
  const { title, icon } = props;

  const getColor = (currentStep) => {
    let color = { color: css.success, delay: 0 };

    if (currentStep === props.iconIdx) {
      color = { color: css.secondary, delay: 0 };
    } else if (currentStep < props.iconIdx) {
      color = { color: css.gray400, delay: 0 };
    }
    return color;
  };

  const getSize = (currentStep) => {
    let size = { width: '10px', height: '10px', fontSize: '1.2rem' };

    if (currentStep === props.iconIdx) {
      size = { width: '35px', height: '35px', fontSize: '1.2rem' };
    } else if (currentStep > props.iconIdx) {
      size = { width: '35px', height: '35px', fontSize: '1.2rem' };
    }
    return size;
  };

  const [color, setColor] = useState(getColor(props.currentStep));
  const [size, setSize] = useState(getSize(props.currentStep));

  useEffect(() => {
    setColor(getColor(props.currentStep));
    setSize(getSize(props.currentStep));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentStep]);

  return (
    <div className="icon-container">
      {props.currentStep === props.iconIdx ? (
        <Fragment>
          <motion.div
            className="icon-circle"
            initial={{
              backgroundColor: css.gray400,
              width: '10px',
              height: '10px',
              fontSize: '1rem',
            }}
            animate={{
              backgroundColor: css.white,
              border: `1px solid ${css.gray400}`,
              boxShadow: `0 0 5px 3px ${css.gray600}`,
              ...size,
            }}
            transition={{ duration: 0.3, delay: color.delay }}
          ></motion.div>
          <motion.div
            className="icon-circle"
            initial={{ backgroundColor: css.gray400, fontSize: '1rem' }}
            animate={{
              backgroundColor: css.secondary,
            }}
            transition={{ duration: 0.3, delay: color.delay }}
          ></motion.div>
        </Fragment>
      ) : (
        <motion.div
          className="icon-circle"
          initial={{
            backgroundColor: color.color,
            ...size,
            boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
            border: '0 solid rgba(0,0,0,0)',
          }}
          animate={{
            backgroundColor: color.color,
            boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
            border: '0 solid rgba(0,0,0,0)',
            ...size,
          }}
          transition={{ duration: 0.3, delay: color.delay }}
        >
          <i
            className={
              'center-absolute ' +
              (props.iconIdx <= props.currentStep ? icon : '')
            }
          />
        </motion.div>
      )}

      <motion.div
        className="icon-title bold"
        initial={{ color: color.color }}
        animate={{ color: color.color }}
        transition={{ duration: 0.3, delay: color.delay }}
      >
        <span>{title}</span>
      </motion.div>
    </div>
  );
}
