import React from 'react';
import { motion } from 'framer-motion';

import { success } from './responseAnimations';
import css from './responseAnimationsCss.scss';

const { variants, actions } = success;

export default function ButtonSuccess(props) {
  const easeCurve = [0.65, 0.0, 0.45, 1.0];

  return (
    <motion.span {...actions} variants={variants.mainContainer}>
      {props.showSuccess && (
        <span className="animated-check-small">
          <svg
            className="check-small"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <motion.path
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
              stroke={css.primary}
              initial={{ pathLength: 0, pathOffset: 0 }}
              animate={{ pathLength: 1, pathOffset: 0 }}
              transition={{ duration: 0.6, ease: easeCurve }}
              onAnimationComplete={props.onAnimationComplete}
            />
          </svg>
        </span>
      )}
    </motion.span>
  );
}
