import React, { Component } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import LandingPage1 from './LandingPage1';
import UserValidation from './KnowledgeBasedVerification/UserValidation';

import { ownerStepTransition } from '../pendingRequestsPageAnimations';

const { actions, variants } = ownerStepTransition;

const pages = [LandingPage1, UserValidation];

class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
    };

    this.auth = props.auth;
  }

  loadNextPage = () => {
    const { currentPage } = this.state;
    const nextPage = parseInt(currentPage) + 1;

    if (nextPage >= pages.length) {
      this.props.updateStep();
    } else {
      this.setState({
        currentPage: parseInt(currentPage) + 1,
      });
    }
  };

  render() {
    const {
      trackerSteps,
      transferAgreement,
      t,
      updateTransferAgreement,
      updateStep,
      cancel,
    } = this.props;
    const { currentPage } = this.state;

    const LandingPage = pages[currentPage];

    return (
      <motion.div
        id="landingPageContainer"
        {...actions}
        variants={variants.mainContainer}
      >
        <AnimatePresence exitBeforeEnter>
          <LandingPage
            key={currentPage}
            trackerSteps={trackerSteps}
            transferAgreement={transferAgreement}
            loadNextPage={this.loadNextPage}
            updateTransferAgreement={updateTransferAgreement}
            updateStep={updateStep}
            cancel={cancel}
            auth={this.auth}
            t={t}
          />
        </AnimatePresence>
      </motion.div>
    );
  }
}

export default LandingPage;
