import React from 'react';
import { motion } from 'framer-motion';

export default function LoadingSpinner(props) {
  const spinTransition = {
    loop: Infinity,
    ease: 'linear',
    duration: 1,
  };

  const { isLoading } = props;

  return (
    <span className="spinner-container-style">
      {isLoading && (
        <motion.span
          className="circle-style"
          animate={{ rotate: 360 }}
          transition={spinTransition}
        />
      )}
    </span>
  );
}
