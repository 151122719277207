import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import css from '../stepIcon.scss';

import { StyledLineContainer } from '../Styles.js';

export default function Line(props) {
  const { lineIdx, currentStep, totalSteps } = props;

  const getGradient = () => {
    let gradient = {
      animate: [{ offset: '100%' }, { offset: '200%' }],
      transition: { duration: 0.3, ease: 'linear', delay: 0 },
    };

    if (lineIdx >= currentStep) {
      gradient = {
        animate: [{ offset: '-100%' }, { offset: '0%' }],
        transition: { duration: 0.3, ease: 'linear', delay: 0 },
      };
    }
    return gradient;
  };

  const [gradient, setGradient] = useState(getGradient());

  useEffect(() => {
    setGradient(getGradient());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentStep]);

  return (
    <StyledLineContainer className="line-container" totalLines={totalSteps - 1}>
      <div className="overflow-wrapper">
        <svg
          className="step-line"
          viewBox="0 0 1000 50"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              id={`linear${lineIdx}`}
              gradientUnits="userSpaceOnUse"
            >
              <motion.stop
                stopColor={css.success}
                initial={gradient.animate[0]}
                animate={gradient.animate[0]}
                transition={gradient.transition}
              />
              <motion.stop
                stopColor={css.gray400}
                initial={gradient.animate[1]}
                animate={gradient.animate[1]}
                transition={gradient.transition}
              />
            </linearGradient>
          </defs>
          <line
            x1="0"
            x2="1000"
            y1="25"
            y2="25"
            className="point"
            stroke={`url(#linear${lineIdx})`}
            vectorEffect="non-scaling-stroke"
          />
        </svg>
      </div>
    </StyledLineContainer>
  );
}
