import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import css from './eSign.scss';
import { LoadingSpinner } from '../../hooks/components';

const ESignArrow = forwardRef((props, ref) => {
  const { t } = props;

  const arrowStatuses = {
    pending: {
      color: css.arrowPending,
      label: 'common:sign',
      labelPos: { x: '54%', y: '50%' },
      textDecoration: 'underline',
    },
    complete: {
      color: css.arrowComplete,
      label: 'common:signed',
      labelPos: { x: '54%', y: '50%' },
      textDecoration: 'none',
    },
    error: {
      color: css.arrowDanger,
      label: 'common:signError',
      labelPos: { x: '54%', y: '50%' },
      textDecoration: 'none',
      textColor: css.white,
    },
    preview: {
      color: css.arrowPreview,
      label: 'common:preview',
      labelPos: { x: '54%', y: '50%' },
      textDecoration: 'none',
    },
    disabled: {
      color: css.arrowDisabled,
      label: 'common:disabled',
      labelPos: { x: '54%', y: '50%' },
      textDecoration: 'none',
    },
  };

  const [status, setStatus] = useState(arrowStatuses[props.signStatus]);

  useEffect(() => {
    setStatus(arrowStatuses[props.signStatus]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.signStatus]);

  return (
    <div
      ref={ref}
      id={props.id}
      className={
        'arrow-container ' +
        (props.onClick && !props.disabled && props.signStatus !== 'disabled'
          ? 'pointer '
          : '') +
        props.className
      }
      style={{
        top: props.location ? props.location.top : '',
        left: props.location ? props.location.left : '',
      }}
      onClick={() => {
        if (props.onClick && !props.disabled) props.onClick();
      }}
    >
      <span>
        <LoadingSpinner isLoading={props.isLoading} />
      </span>
      <svg
        className="esign-arrow"
        viewBox={`0 0 ${props.length} 50`}
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMinYMid meet"
      >
        <polygon
          points={`0,25 25,0 ${props.length},0 ${props.length},50 25,50`}
          fill={status.color}
        />
        {!props.isLoading && (
          <text
            className={'arrow-text ' + props.className}
            x={props.labelPos ? props.labelPos.x : status.labelPos.x}
            y={props.labelPos ? props.labelPos.y : status.labelPos.y}
            textDecoration={status.textDecoration}
            fill={status.textColor}
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {props.label ? props.label : t(status.label)}
          </text>
        )}
      </svg>
    </div>
  );
});

ESignArrow.propTypes = {
  signStatus: PropTypes.string,
};

ESignArrow.defaultProps = {
  signStatus: 'pending',
};

export default ESignArrow;
