import React, { Component } from 'react';
import { OpenCvProvider } from 'opencv-react';
import { AnimatePresence, motion } from 'framer-motion';
import { isNil } from 'lodash';
import { ErrorModal, utils } from '../../../components';

import { PageLoader, StepTrackerContainer } from '../../../hooks/components';

import LandingPageContainer from '../LandingPage/LandingPageContainer';
import defaultStepMap from './defaultStepMap';

import { ownerStepContainer } from '../pendingRequestsPageAnimations';
import { getStateConfig } from '../../../state-configs/GetStateConfig';

const { actions, variants } = ownerStepContainer;

const ownerActions = [
  'salvageAuthorizationSigned',
  'salvageTitleApplicationSigned',
];

export const checkUploadRequired = (transferAgreement, uploadableDocuments) => {
  const uploadRequired =
    utils.getValue('paperTitleRequired', transferAgreement) &&
    uploadableDocuments.length;
  const allSteps = [...defaultStepMap].filter((step) =>
    !uploadRequired ? step.title !== 'uploadDocuments' : true,
  );
  const stateConfig = getStateConfig(
    utils.getValue('stateCode', transferAgreement),
  );
  // Expect a state config for each configurable step
  const validatedSteps = [...allSteps].filter(
    (step) =>
      isNil(step.validation) ||
      (!isNil(stateConfig) && step.validation(transferAgreement, stateConfig)),
  );

  return {
    allSteps: validatedSteps,
    trackerSteps: [...validatedSteps].slice(0, -1),
    uploadRequired,
  };
};

// FIXME: add end to end testing for this component
class OwnerStepContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      transferAgreement: null,
      progressStep: null,
      allSteps: [],
      trackerSteps: [],
      documents: [],
      confirmInfo: false,
    };

    this.auth = props.auth;
  }

  updateStep = (cb) => {
    const { progressStep } = this.state;
    if (cb) cb();

    this.setState({
      progressStep: progressStep !== null ? parseInt(progressStep, 10) + 1 : 0,
    });
  };

  previousStep = (cb) => {
    const { progressStep } = this.state;
    if (cb) cb();

    this.setState({
      progressStep: progressStep !== null ? parseInt(progressStep, 10) - 1 : 0,
      confirmInfo: progressStep === 1,
    });
  };

  updateTransferAgreement = (transferAgreement, cb) => {
    this.setState(
      {
        transferAgreement,
      },
      () => {
        if (cb) cb();
      },
    );
  };

  getValue = (path) => utils.getValue(path, this.state.transferAgreement);

  async componentDidMount() {
    let { transferAgreement } = this.props;

    try {
      const ownerType =
        transferAgreement.ownerOrdinal > 0
          ? 'ADDITIONAL_OWNER'
          : 'PRIMARY_OWNER';
      const { documentSpecs } = await this.auth.fetch(
        `/documentsets/state/${transferAgreement.stateCode.toLowerCase()}?transactionTypeCode=${transferAgreement.totalLossType.toLowerCase()}&persona=${ownerType}`,
      );
      const uploadableDocuments = documentSpecs.filter(
        (document) => document.type === 'UPLOAD',
      );
      const { allSteps, trackerSteps, uploadRequired } = checkUploadRequired(
        transferAgreement,
        uploadableDocuments,
      );

      if (transferAgreement.userValidated) {
        transferAgreement = await this.auth.fetch(
          `/transferagreements/${transferAgreement.id}`,
        );

        let progressStep = ownerActions.findIndex(
          (action) => !transferAgreement.signedFormStatus[action],
        );

        if (progressStep === -1) {
          progressStep = uploadRequired ? 4 : 3;
        } else if (progressStep > 0) {
          progressStep += 1;
        }

        this.setState({
          progressStep,
          transferAgreement,
          allSteps,
          trackerSteps,
          documents: documentSpecs,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
          transferAgreement,
          allSteps,
          trackerSteps,
          documents: documentSpecs,
        });
      }
    } catch {
      this.setState({
        loadError: true,
        isLoading: false,
      });
    }
  }

  render() {
    const { t } = this.props;
    const {
      isLoading,
      loadError,
      transferAgreement,
      documents,
      progressStep,
      allSteps,
      trackerSteps,
      confirmInfo,
    } = this.state;

    if (isLoading) return <PageLoader />;
    if (loadError) return <ErrorModal />;

    const OwnerStep =
      progressStep !== null ? allSteps[progressStep].component : null;

    return (
      <motion.div
        id="ownerStepContainer"
        className="step-card-container"
        {...actions}
        variants={variants.mainContainer}
      >
        {progressStep !== null ? (
          <>
            <StepTrackerContainer
              trackerSteps={trackerSteps}
              currentStep={progressStep}
              t={t}
            />
            <AnimatePresence exitBeforeEnter>
              <OpenCvProvider>
                <OwnerStep
                  key={progressStep}
                  {...this.props}
                  transferAgreement={transferAgreement}
                  documents={documents}
                  updateStep={this.updateStep}
                  previousStep={this.previousStep}
                  confirmInfo={confirmInfo}
                  updateTransferAgreement={this.updateTransferAgreement}
                  cancel={this.props.cancel}
                />
              </OpenCvProvider>
            </AnimatePresence>
          </>
        ) : (
          <LandingPageContainer
            key={progressStep}
            {...this.props}
            trackerSteps={trackerSteps}
            transferAgreement={transferAgreement}
            updateStep={this.updateStep}
            updateTransferAgreement={this.updateTransferAgreement}
            cancel={this.props.cancel}
          />
        )}
      </motion.div>
    );
  }
}

export default OwnerStepContainer;
