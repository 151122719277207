import React from 'react';
import { func } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Navbar } from 'reactstrap';

const Header = ({ t }) => {
  return (
    <Navbar fixed="sm">
      <div className="header-text-label">
        <h3 className="d-inline-block header-text underline">
          {t('cocPortal:certificateOfCompletion')}
        </h3>
        <h3 className="d-inline-block header-text-sub">
          {' '}
          {t('cocPortal:validationDetails')}
        </h3>
      </div>
    </Navbar>
  );
};

Header.propTypes = {
  t: func.isRequired,
};

export default withTranslation()(Header);
