import React from 'react';
import { motion } from 'framer-motion';

import { success } from './responseAnimations';
import css from './responseAnimationsCss.scss';

const { actions } = success;

export default function Checkmark(props) {
  const easeCurve = [0.65, 0.0, 0.45, 1.0];

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-2 -2 56 56"
      {...actions}
      className={props.className}
    >
      <motion.circle
        fill="none"
        cx="26"
        cy="26"
        r="25"
        stroke={css.primary}
        strokeWidth="2"
        strokeDashoffset="0"
        strokeDasharray="166"
        initial={{ pathLength: 0, pathOffset: 0 }}
        animate={{
          pathLength: 1,
          pathOffset: 1,
          strokeDashoffset: [166, 166, 0],
        }}
        transition={{ duration: 0.6, ease: easeCurve, times: [0, 0.5, 1] }}
      />
      <motion.path
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
        stroke={css.primary}
        strokeWidth="2"
        initial={{ pathLength: 0, pathOffset: 0 }}
        animate={{ pathLength: 1, pathOffset: 0 }}
        transition={{ duration: 0.6, ease: easeCurve }}
        onAnimationComplete={props.onAnimationComplete}
      />
    </motion.svg>
  );
}
