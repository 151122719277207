import React from 'react';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import { utils } from '@champtitles/utilities';

export const AllowedFieldWrapper = ({
  allowedFields,
  genericEntity,
  path,
  pathList,
  children,
}) => {
  const getValueFromForm = (fieldPath) =>
    utils.getValue(fieldPath, genericEntity);
  const getValue = (_path, _pathList) => {
    let valueFound = false;
    if (_path) {
      const pathValue = allowedFields.find(
        (x) => x.fieldPath === _path && x.shown,
      );
      if (!isNil(pathValue)) {
        if (
          pathValue.excludedStates &&
          pathValue.excludedStates.includes(genericEntity.stateCode)
        ) {
          return false;
        }
        if (genericEntity) {
          const value = getValueFromForm(pathValue.fieldPath);
          if (!isNil(value) && value !== '') {
            valueFound = true;
          }
        } else {
          valueFound = true;
        }
      }
    } else if (_pathList) {
      _pathList.forEach((pathFromList) => {
        const pathValue = allowedFields.find(
          (x) => x.fieldPath === pathFromList && x.shown,
        );
        if (!isNil(pathValue)) {
          valueFound = true;
        } else {
          valueFound = false;
        }
      });
    }

    return valueFound;
  };

  return getValue(path, pathList) && <>{children}</>;
};

AllowedFieldWrapper.propTypes = {
  // required props
  allowedFields: PropTypes.array.isRequired,
  // optional props
  path: PropTypes.string,
  pathList: PropTypes.array,
  genericEntity: PropTypes.object,
};
