const completedRequestDetails = {
  actions: {
    animate: 'open',
    exit: 'closed',
    initial: 'closed',
  },
  variants: {
    mainContainer: {
      open: {
        x: 0,
        opacity: 1,
        transition: {
          x: { stiffness: 1, velocity: 0.1 },
        },
        scale: 1,
      },
      closed: {
        x: 10,
        opacity: 0,
        scale: 0.9,
      },
    },
  },
};

export { completedRequestDetails };
