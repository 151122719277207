import React, { Component } from 'react';
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';

import { withTranslation } from 'react-i18next';

import { utils, validator } from '../../../components';

import { ActionButton } from '../../../hooks/components';

class DeclineRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      submitError: false,
      successfulSubmit: false,
      validationSubmitError: false,
      decline: {
        reason: '',
      },
      validated: {
        reason: {
          validationCheck: 'textarea',
          required: true,
          isValid: null,
        },
      },
    };

    this.auth = props.auth;
  }

  onChange = (e) => {
    let { validated, decline } = this.state;
    let {
      target: { name, value },
    } = e;

    decline = utils.buildNestedObject(decline, name, value);
    const validateField = validated[name];

    let isValid = false;
    if (validateField.validationCheck === 'textarea') {
      isValid = !!value.length;
    } else {
      isValid = validator.validate(
        value,
        validateField.validationCheck,
        validateField.required,
      );
    }

    const validatedField = {
      [name]: {
        ...validateField,
        isValid,
      },
    };

    this.setState({
      decline,
      validated: {
        ...validated,
        ...validatedField,
      },
      validationSubmitError: !decline.reason.length,
      submitErrorMsg: false,
    });
  };

  checkAllValues = () => {
    let allValid = true;
    const { decline, validated } = this.state;
    let validatedKeys = Object.keys(validated);

    let validatedChecked = validatedKeys.reduce((acc, key) => {
      let isValid = false;
      const fieldValue = utils.getNestedObjectValue(key, decline);
      const validateField = validated[key];

      if (validateField.validationCheck === 'textarea') {
        isValid = !!fieldValue.length;
      } else {
        isValid = validator.validate(
          fieldValue,
          validateField.validationCheck,
          validateField.required,
        );
      }

      acc[key] = {
        ...acc[key],
        isValid,
      };

      if (!isValid) allValid = false;

      return acc;
    }, validated);

    if (!allValid) {
      this.setState(
        {
          validated: validatedChecked,
          validationSubmitError: true,
          submitError: true,
        },
        () => {
          setTimeout(() => {
            this.setState({ submitError: false });
          }, 1000);
        },
      );
    } else {
      this.setState(
        {
          isSubmitting: true,
          submitError: false,
          successfulSubmit: false,
          validationSubmitError: false,
        },
        this.submit,
      );
    }
  };

  submit = () => {
    const { decline } = this.state;
    const { transferAgreement } = this.props;

    this.auth
      .fetch(`/transferagreements/${transferAgreement.id}/decline`, {
        method: 'POST',
        body: JSON.stringify(decline),
      })
      .then(() => {
        this.setState({
          isSubmitting: false,
          successfulSubmit: true,
        });
      })
      .catch(() => {
        this.setState({
          isSubmitting: false,
          submitError: true,
          submitErrorMsg: true,
        });
      });
  };

  onSubmitComplete = () => {
    const { successfulSubmit } = this.state;

    if (successfulSubmit) {
      this.setState(
        {
          successfulSubmit: false,
        },
        this.props.onSubmitComplete,
      );
    } else {
      this.setState({
        submitError: false,
      });
    }
  };

  handleKeyPress = (e) => {
    if (
      [27].includes(e.keyCode) ||
      [27].includes(e.which) ||
      [27].includes(e.code)
    ) {
      this.props.cancel();
    }
  };

  static getDerivedStateFromProps(props, state) {
    return props.isOpen !== state.isOpen
      ? {
          isOpen: props.isOpen,
          validationSubmitError: false,
          submitErrorMsg: false,
          decline: {
            reason: '',
          },
          validated: {
            reason: {
              validationCheck: 'textarea',
              required: true,
              isValid: null,
            },
          },
        }
      : null;
  }

  render() {
    const {
      isSubmitting,
      decline,
      validated,
      submitError,
      successfulSubmit,
      validationSubmitError,
      submitErrorMsg,
    } = this.state;

    const { isOpen, cancel, requestorEmail, t } = this.props;

    return (
      <div id="declineRequest">
        <Modal
          isOpen={isOpen}
          toggle={this.handleKeyPress}
          className="medium-modal"
          autoFocus={true}
        >
          <ModalHeader>{t('declineToSignAuthorization')}</ModalHeader>
          <ModalBody style={{ width: '60vw !important' }}>
            <Row className="mb-4">
              <Col md={1} />
              <Col>{t('declineDocument', { requestorEmail })}</Col>
              <Col md={1} />
            </Row>
            <Row className="page-container">
              <Col md={1} />
              <Col>
                <FormGroup>
                  <Input
                    type="textarea"
                    id="reason"
                    maxLength="2000"
                    value={decline.reason}
                    style={{ height: '100px' }}
                    className={utils.checkFieldValidation('reason', validated)}
                    onChange={this.onChange}
                    name="reason"
                    autoComplete="off"
                    required
                  />
                  <Label className="form-control-placeholder">
                    <span className="mr-2">{t('reasonForDecline')}</span>
                  </Label>
                </FormGroup>
              </Col>
              <Col md={1} />
            </Row>
            {validationSubmitError && (
              <Row className="text-center" noGutters>
                <Col md={1} />
                <Col className="alert-danger">
                  <i
                    className="fa fa-exclamation-circle pr-2"
                    aria-hidden="true"
                  ></i>
                  {t('validationError')}
                </Col>
                <Col md={1} />
              </Row>
            )}
            {submitErrorMsg && (
              <Row className="text-center" noGutters>
                <Col md={1} />
                <Col className="alert-danger">
                  <i
                    className="fa fa-exclamation-circle pr-2"
                    aria-hidden="true"
                  ></i>
                  {t('submitError')}
                </Col>
                <Col md={1} />
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              className="mr-2 animated-scale"
              color="link"
              onClick={cancel}
              disabled={isSubmitting}
            >
              {t('buttons:cancel')}
            </Button>
            <ActionButton
              className="btn btn-primary"
              onClick={this.checkAllValues}
              isLoading={isSubmitting}
              isSubmitting={isSubmitting}
              submitSuccess={successfulSubmit}
              submitError={submitError}
              onSubmitComplete={this.onSubmitComplete}
            >
              {t('buttons:submit')}
            </ActionButton>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withTranslation('declineRequest', { withRef: true })(
  DeclineRequest,
);
