import React from 'react';
import { withTranslation } from 'react-i18next';
import { number, func, object } from 'prop-types';
import { Table, Col, Row } from 'reactstrap';

import { formatDateTime, getSignerType } from '../../helpers';

const SignerTable = ({ t, index, signer, documents }) => {
  const { id, signerType, signatures } = signer;
  return (
    <Row key={`Signer ${id}`} className="signer-table">
      <Col>
        <h4 className="signer-table-header">
          <span className="bold">
            {t('signer')} {index + 1}
          </span>{' '}
          | {getSignerType(signerType, t)}
        </h4>
        <Table responsive className="custom-striped">
          <thead>
            <tr>
              <th style={{ width: '25%' }}>
                {t('cocPortal:signatureInformation.documentSectionSigned')}
              </th>
              <th style={{ width: '25%' }}>
                {t('cocPortal:signatureInformation.timestamp')}
              </th>
              <th>{t('cocPortal:signatureInformation.hash')}</th>
            </tr>
          </thead>
          <tbody>
            {signatures &&
              signatures.length > 0 &&
              signatures.map((signature) => {
                const { signedDateTime, signatureHash } = signature;
                let documentNames;
                documents.forEach((sign) => {
                  sign.signers.forEach((s) => {
                    s.signatures.every((result) => {
                      if (result.id === signature.id) {
                        documentNames = sign.userFileName;
                        return false;
                      }
                      return true;
                    });
                  });
                });
                return (
                  <tr key={signature.id}>
                    <td style={{ width: '25%' }}>{documentNames}</td>
                    <td style={{ width: '25%' }}>
                      {formatDateTime(signedDateTime)}
                    </td>
                    <td>{signatureHash}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

SignerTable.propsignerTypes = {
  index: number.isRequired,
  signer: object.isRequired,
  t: func.isRequired,
};

export default withTranslation()(SignerTable);
