import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class NotAuthorizedModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.isOpen || false,
    };
  }

  close = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });

    this.props.keycloak.logout();
  };

  static getDerivedStateFromProps(props, state) {
    return props.isOpen !== state.isOpen ? { isOpen: props.isOpen } : null;
  }

  render() {
    const { t } = this.props;

    return (
      <Modal isOpen={this.state.isOpen}>
        <ModalHeader>{t('header')}</ModalHeader>
        <ModalBody>{t('body')}</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => this.close()}>
            {t('common:close')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withTranslation('notAuthorizedModal')(NotAuthorizedModal);
