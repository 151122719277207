import React, { Fragment } from 'react';

const singleLine = (addr) => {
  if (!addr) return '';
  const line2 = addr.addressLine2 || '';
  const formattedZipcode =
    addr.zipcode && addr.zipcode.length > 5
      ? addr.zipcode.slice(0, 5) + '-' + addr.zipcode.slice(5)
      : addr.zipcode;
  return `${
    addr.addressLine1 + (line2 ? ', ' + line2 + ', ' : ', ') + (addr.city || '')
  }, ${addr.state && addr.state.code ? addr.state.code : ''} ${
    formattedZipcode || ''
  } - ${addr.county && addr.county.name ? addr.county.name : ''}`;
};

const multiLine = (addr) => {
  if (!addr) return '';
  const line2 = addr.addressLine2 || '';
  const formattedZipcode =
    addr.zipcode && addr.zipcode.length > 5
      ? addr.zipcode.slice(0, 5) + '-' + addr.zipcode.slice(5)
      : addr.zipcode;
  return (
    <Fragment>
      <div>{addr.addressLine1}</div>
      {line2 && <div>{line2}</div>}
      <div>
        {addr.city || ''},{' '}
        {addr.state && addr.state.code ? addr.state.code : ''}{' '}
        {formattedZipcode || ''}
      </div>
      <div>{addr.county && addr.county.name ? addr.county.name : ''}</div>
    </Fragment>
  );
};
export { singleLine, multiLine };
