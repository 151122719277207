import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Button, Card, Col, CustomInput, Row } from 'reactstrap';

import { withTranslation, Trans } from 'react-i18next';

import {
  ActionModal,
  ResponseAnimations,
  utils,
  validator,
} from '../../../components';

import { ActionButton } from '../../../hooks/components';

import DeclineRequest from './DeclineRequest';

import { ownerStepTransition } from '../pendingRequestsPageAnimations';

const { actions, variants } = ownerStepTransition;

class AssetAuthorization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      successfulSubmit: false,
      submitError: false,
      showDeclineModal: false,
      showSubmitError: false,
      transferAgreement: props.transferAgreement || null,
      validated: {
        agreementConsent: {
          validationCheck: 'checkbox',
          required: true,
          isValid: null,
        },
      },
    };

    this.auth = props.auth;
  }

  onCheck = (e) => {
    let { transferAgreement, validated } = this.state;
    let {
      target: { name, checked },
    } = e;

    transferAgreement = utils.buildNestedObject(
      transferAgreement,
      name,
      checked,
    );
    const validateField = validated[name];
    const validatedField = {
      [name]: {
        ...validateField,
        isValid: checked,
      },
    };

    this.setState({
      transferAgreement,
      validated: {
        ...validated,
        ...validatedField,
      },
    });
  };

  saveProgress = () => {
    const { transferAgreement } = this.state;

    this.auth
      .fetchPostFile(`/transferagreements/${transferAgreement.id}/consent`, {
        method: 'POST',
      })
      .then((response) => {
        this.setState({
          isSaving: false,
          successfulSubmit: true,
        });
        this.props.updateTransferAgreement(response);
      })
      .catch((e) => {
        this.setState({
          isSaving: false,
          submitError: true,
        });

        if (e.status === 409) {
          this.props.updateStep();
        } else {
          this.showSubmissionErrorModal();
        }
      });
  };

  onSaveCompletion = () => {
    const { successfulSubmit } = this.state;

    if (successfulSubmit) {
      this.setState(
        {
          successfulSubmit: false,
        },
        () => {
          this.props.updateStep();
        },
      );
    } else {
      this.setState({
        submitError: false,
      });
    }
  };

  checkAllValues = () => {
    let allValid = true;
    const { transferAgreement, validated } = this.state;
    let validatedKeys = Object.keys(validated);

    let validatedChecked = validatedKeys.reduce((acc, key) => {
      let isValid = false;
      const fieldValue = utils.getNestedObjectValue(key, transferAgreement);
      const validateField = validated[key];

      if (validateField.validationCheck === 'checkbox') {
        if (fieldValue !== undefined) {
          isValid = fieldValue;
        }
      } else {
        isValid = validator.validate(
          fieldValue,
          validateField.validationCheck,
          validateField.required,
        );
      }

      acc[key] = {
        ...acc[key],
        isValid,
      };

      if (!isValid) allValid = false;

      return acc;
    }, validated);

    if (!allValid) {
      this.setState({
        validated: validatedChecked,
      });
    } else {
      this.setState(
        {
          isSaving: true,
        },
        this.saveProgress,
      );
    }
  };

  showDeclineModal = () => {
    this.setState({
      showDeclineModal: true,
    });
  };

  cancelDeclineModal = () => {
    this.setState({
      showDeclineModal: false,
    });
  };

  showSubmissionErrorModal = () => {
    this.setState({
      showSubmitError: true,
    });
  };

  continue = () => {
    this.props.updateStep();
  };

  previous = () => {
    this.props.previousStep();
  };

  getValue = (path) => utils.getValue(path, this.state.transferAgreement);

  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const {
      submitError,
      successfulSubmit,
      isSaving,
      validated,
      showDeclineModal,
      showSubmitError,
    } = this.state;
    const { t } = this.props;

    const readOnly =
      this.getValue('signedFormStatus.salvageAuthorizationSigned') ||
      this.getValue('transferAgreementStatus') === 'OWNER_DECLINED';
    const defaultChecked = this.getValue(
      'signedFormStatus.salvageAuthorizationSigned',
    );
    const insurerName = this.getValue('insurancePolicy.insuranceCompanyName');

    return (
      <motion.div
        id="assetAuthorization"
        {...actions}
        variants={variants.mainContainer}
      >
        <Row noGutters className="d-flex justify-content-center">
          <Col className="step-card">
            <Card className="p-2 agreement-body">
              <Row noGutters>
                <Col className="detail-header mb-2 font-weight-bold ml-2 ml-lg-3">
                  {t('consentAndAgreement')}
                </Col>
              </Row>
              <Row noGutters>
                <Col className="mx-md-2 mx-lg-3">
                  <Trans i18nKey="assetAuthorization:agreement">
                    <p> </p>
                    <ul className="pl-3 pl-md-4">
                      <li className="mb-2"> </li>
                      <li className="mb-2"> </li>
                      <li className="mb-2">{{ insurerName }}</li>
                      <li className="mb-2"> </li>
                      <li className="mb-2"> </li>
                      <li
                        className="ml-3 mb-2"
                        style={{ listStyleType: 'circle' }}
                      >
                        {' '}
                      </li>
                      <li
                        className="ml-3 mb-2"
                        style={{ listStyleType: 'circle' }}
                      >
                        {' '}
                      </li>
                      <li
                        className="mb-2"
                        style={{ listStyleType: 'square', marginLeft: '2rem' }}
                      >
                        {' '}
                      </li>
                      <li
                        className="ml-3 mb-2"
                        style={{ listStyleType: 'circle' }}
                      >
                        {' '}
                      </li>
                      <li
                        className="ml-3 mb-2"
                        style={{ listStyleType: 'circle' }}
                      >
                        {' '}
                      </li>
                      <li className="mb-2"> </li>
                      <li className="mb-2">{{ insurerName }}</li>
                      <li className="mb-2"> </li>
                      <li> </li>
                    </ul>
                  </Trans>
                </Col>
              </Row>
              <Row noGutters className="text-center mt-0 mt-lg-2 mb-2 mb-lg-0">
                <Col className="pt-3">
                  <CustomInput
                    type="checkbox"
                    defaultChecked={
                      defaultChecked || this.getValue('agreementConsent')
                    }
                    className={
                      utils.checkFieldValidation(
                        'agreementConsent',
                        validated,
                      ) + ' mt-4 mb-3 d-inline-block checkbox-lg'
                    }
                    id="agreementConsent"
                    name="agreementConsent"
                    label={
                      <span className="pointer font-weight-bold">
                        {t('agreementConsent')}
                      </span>
                    }
                    onChange={this.onCheck}
                    disabled={readOnly}
                    required
                  ></CustomInput>
                </Col>
              </Row>
              <Row noGutters>
                <Col xs={5} sm={6}>
                  {!readOnly && (
                    <Button
                      color="link"
                      className="px-0 px-sm-3"
                      onClick={this.showDeclineModal}
                      disabled={isSaving}
                    >
                      {t('declineToSign')}
                    </Button>
                  )}
                </Col>
                <Col xs={7} sm={6} className="text-right">
                  <Button
                    color="link"
                    onClick={this.props.cancel}
                    disabled={isSaving}
                  >
                    {t('buttons:cancel')}
                  </Button>
                  <Button
                    color="secondary"
                    className="m-2"
                    onClick={this.previous}
                  >
                    {t('buttons:previousStep')}
                  </Button>
                  <ActionButton
                    className="btn-primary"
                    isLoading={isSaving}
                    submitSuccess={successfulSubmit}
                    submitError={submitError}
                    onSubmitComplete={this.onSaveCompletion}
                    onClick={readOnly ? this.continue : this.checkAllValues}
                  >
                    {readOnly ? t('buttons:continue') : t('buttons:submit')}
                  </ActionButton>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <DeclineRequest
          {...this.props}
          isOpen={showDeclineModal}
          cancel={this.cancelDeclineModal}
          requestorEmail={this.getValue('owner.email')}
          onSubmitComplete={this.props.cancel}
        />
        <ActionModal
          isOpen={showSubmitError}
          bodyOnly={true}
          className="submit-error"
        >
          <ResponseAnimations.Error
            {...this.props}
            label={t('common:signatureError')}
            className="medium-cross"
          >
            <div className="text-center mt-3">
              <Button
                color="secondary"
                className="mr-2 animated-scale"
                onClick={this.props.cancel}
              >
                {t('buttons:returnToPending')}
              </Button>
            </div>
          </ResponseAnimations.Error>
        </ActionModal>
      </motion.div>
    );
  }
}

AssetAuthorization.propTypes = {
  updateStep: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default withTranslation('assetAuthorization')(AssetAuthorization);
