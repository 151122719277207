import React, { useContext } from 'react';
import { func } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

// reducers
import { Context } from '../../../reducers/store';
import {
  getDocumentInitiator,
  getDocumentId,
  getCompletionDate,
  getDocumentName,
} from '../../../reducers/cocPortalReducer';

import ChampSecuredLogo from '../../../components/ChampSecuredLogo/ChampSecuredLogo';
import { formatDateTime } from '../helpers';

const DocumentDetails = ({ t }) => {
  const [state] = useContext(Context);
  const completionDate = getCompletionDate(state);
  const documentId = getDocumentId(state);
  const documentInitiator = getDocumentInitiator(state);
  const documentName = getDocumentName(state);

  return (
    <Row className="section">
      <Col>
        <Row className="section-header">
          <h4>{t('cocPortal:transactionDetails.title')}</h4>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col className="col-4">
                <span className="bold">
                  {t('cocPortal:transactionDetails.transactionIdentifier')}:{' '}
                </span>
              </Col>
              <Col>{documentId || '-'}</Col>
            </Row>
            <Row>
              <Col className="col-4">
                <span className="bold">
                  {t('cocPortal:transactionDetails.completionDate')}:{' '}
                </span>
              </Col>
              <Col>{completionDate ? formatDateTime(completionDate) : '-'}</Col>
            </Row>
            <Row>
              <Col className="col-4">
                <span className="bold">
                  {t('cocPortal:transactionDetails.transactionInitiator')}:{' '}
                </span>
              </Col>
              <Col>{documentInitiator || '-'}</Col>
            </Row>
            <Row>
              <Col className="col-4">
                <span className="bold">
                  {t('cocPortal:transactionDetails.transactionName')}:{' '}
                </span>
              </Col>
              <Col>
                {documentName &&
                  documentName.length > 0 &&
                  documentName.map((result) => (
                    <div key={result.id}>
                      <span>{result.userFileName}</span>
                      <br />
                    </div>
                  ))}
              </Col>
            </Row>
          </Col>
          <Col>
            <ChampSecuredLogo className="secured-logo" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

DocumentDetails.propTypes = {
  t: func.isRequired,
};

export default withTranslation()(DocumentDetails);
