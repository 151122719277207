export default {
  titleNumber: {
    type: 'TextInputField',
    validation: {
      validationCheck: 'radio',
      required: true,
      isValid: null,
    },
  },
  claimNumber: {
    type: 'TextInputField',
    validation: {
      validationCheck: 'alphanumericalNoSpaces',
      required: true,
      isValid: null,
    },
  },
  policyNumber: {
    type: 'TextInputField',
    validation: {
      validationCheck: 'policyNumber',
      required: true,
      isValid: null,
    },
  },
  salvageDeclarationDate: {
    type: 'DateField',
    dateFormat: 'MM/dd/yyyy',
    validation: {
      validationCheck: 'dateYYYY-MM-DD',
      dateCheck: true,
      required: true,
      isValid: null,
      error: 'dateInputSelection',
    },
  },
  vin: {
    type: 'TextInputField',
    validation: {
      validationCheck: 'vin',
      required: true,
      isValid: null,
    },
  },
  zipcode: {
    type: 'NumberInputField',
    format: '#####',
    validation: {
      validationCheck: 'zipcode',
      required: true,
      isValid: null,
    },
  },
  lastFour: {
    type: 'NumberInputField',
    format: '####',
    validation: {
      validationCheck: 'ssnLastFour',
      required: true,
      isValid: null,
    },
  },
  ssnLastFour: {
    type: 'NumberInputField',
    format: '####',
    validation: {
      validationCheck: 'ssnLastFour',
      required: true,
      isValid: null,
    },
  },
  feinLastFour: {
    type: 'NumberInputField',
    format: '####',
    validation: {
      validationCheck: 'ssnLastFour',
      required: true,
      isValid: null,
    },
  },
};
