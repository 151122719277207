import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';

import { ActionButton, PageLoader } from '../../../../hooks/components';

import { ErrorModal } from '../../../../components';

import { utils, validator } from '@champtitles/utilities';

import GenerateKBV from './GenerateKBV';
import kbvMappings from './kbvMappings';

import { ownerStepTransition } from '../../pendingRequestsPageAnimations';

const { actions, variants } = ownerStepTransition;

class UserValidation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isSubmitting: false,
      successfulSubmit: false,
      validationError: false,
      validationFields: [],
      userValidationFields: {},
      validated: {},
    };

    this.auth = props.auth;
  }

  onChange = (e) => {
    let { userValidationFields, validated } = this.state;
    let {
      target: { name, value },
    } = e;

    userValidationFields = utils.buildNestedObject(
      userValidationFields,
      name,
      value,
    );
    const validateField = validated[name];
    const validatedField = {
      [name]: {
        ...validateField,
        isValid: validator.validate(
          value,
          validateField.validationCheck,
          validateField.required,
        ),
      },
    };

    this.setState({
      userValidationFields,
      validated: {
        ...validated,
        ...validatedField,
      },
    });
  };

  onNumberChange = (e) => {
    let { userValidationFields, validated } = this.state;
    let {
      target: { name, value },
    } = e;

    const validateField = validated[name];

    value = validator.sanitize(value, validateField.validationCheck);

    userValidationFields = utils.buildNestedObject(
      userValidationFields,
      name,
      value,
    );

    const validatedField = {
      [name]: {
        ...validateField,
        isValid: validator.validate(
          value,
          validateField.validationCheck,
          validateField.required,
        ),
      },
    };

    this.setState({
      userValidationFields,
      validated: {
        ...validated,
        ...validatedField,
      },
    });
  };

  onDateSelect = (name, value, formattedForValidation, dateFormat) => {
    let { userValidationFields, validated } = this.state;
    const validateField = validated[name];

    userValidationFields = utils.buildNestedObject(
      userValidationFields,
      name,
      value,
    );

    const validatedField = {
      [name]: {
        ...validateField,
        ...validator.validateDate[dateFormat](
          formattedForValidation,
          validateField.required,
        ),
      },
    };

    this.setState({
      userValidationFields,
      validated: {
        ...validated,
        ...validatedField,
      },
    });
  };

  onDateChange = (name, value, dateFormatCheck) => {
    let { userValidationFields, validated } = this.state;
    const validateField = validated[name];

    userValidationFields = utils.buildNestedObject(
      userValidationFields,
      name,
      value,
    );

    const validatedField = {
      [name]: {
        ...validateField,
        ...validator.validateDate[dateFormatCheck](
          value,
          validateField.required,
        ),
      },
    };

    this.setState({
      userValidationFields,
      validated: {
        ...validated,
        ...validatedField,
      },
    });
  };

  checkValues = () => {
    let allValid = true;
    const { userValidationFields, validated } = this.state;
    let validatedKeys = Object.keys(validated);

    let validatedChecked = validatedKeys.reduce((acc, key) => {
      const fieldValue = utils.getNestedObjectValue(key, userValidationFields);
      const validateField = validated[key];
      const isValid = validator.validate(
        fieldValue,
        validateField.validationCheck,
        validateField.required,
      );

      acc[key] = {
        ...acc[key],
        isValid,
      };

      if (!isValid) allValid = false;

      return acc;
    }, validated);

    if (!allValid) {
      this.setState({
        validated: validatedChecked,
      });
    } else {
      this.setState(
        {
          isSubmitting: true,
        },
        this.submit,
      );
    }
  };

  submit = () => {
    const { userValidationFields } = this.state;
    const { transferAgreement } = this.props;

    this.auth
      .fetch(`/transferagreements/${transferAgreement.id}/uservalidation`, {
        method: 'POST',
        body: JSON.stringify(userValidationFields),
      })
      .then(() => {
        this.setState({
          isSubmitting: false,
          successfulSubmit: true,
        });
      })
      .catch((e) => {
        this.setState({
          isSubmitting: false,
          validationError: true,
          validationErrorMsg: e.status === 400,
          submitErrorMsg: e.status !== 400,
        });
      });
  };

  onSubmitComplete = async () => {
    const { successfulSubmit } = this.state;

    if (successfulSubmit) {
      this.setState(
        {
          successfulSubmit: false,
          isLoading: true,
        },
        this.onValidationSuccess,
      );
    } else {
      this.setState({
        validationError: false,
      });
    }
  };

  onValidationSuccess = async () => {
    let { transferAgreement, updateTransferAgreement, updateStep } = this.props;

    try {
      transferAgreement = await this.auth.fetch(
        `/transferagreements/${transferAgreement.id}`,
      );
      updateTransferAgreement(transferAgreement, updateStep);
    } catch {
      this.setState({
        dataError: true,
      });
    }
  };

  getValue = (path) => utils.getValue(path, this.state.userValidationFields);

  async componentDidMount() {
    const { transferAgreement } = this.props;
    try {
      const validationFields = await this.auth.fetch(
        `/transferagreements/${transferAgreement.id}/uservalidation`,
      );
      const validated = validationFields.reduce((acc, name) => {
        acc[name] = kbvMappings[name].validation;
        return acc;
      }, {});

      this.setState({ isLoading: false, validationFields, validated });
    } catch (e) {
      this.setState({ dataError: e });
    }
  }

  render() {
    const { t, cancel } = this.props;
    const {
      isLoading,
      dataError,
      validationError,
      successfulSubmit,
      isSubmitting,
      validationErrorMsg,
      submitErrorMsg,
      validated,
      validationFields,
      userValidationFields,
    } = this.state;

    if (dataError) return <ErrorModal />;
    if (isLoading) return <PageLoader />;

    return (
      <motion.div
        id="userValidation"
        {...actions}
        variants={variants.mainContainer}
      >
        <Row noGutters className="d-flex justify-content-center">
          <Col className="step-card">
            <Card>
              <CardBody className="page-container">
                {validationErrorMsg && (
                  <Row noGutters className="text-center alert-danger">
                    <Col className="py-2 px-2">
                      <i
                        className="fa fa-exclamation-circle pr-2"
                        aria-hidden="true"
                      ></i>
                      {t('userValidation:validationError')}
                    </Col>
                  </Row>
                )}
                {submitErrorMsg && (
                  <Row noGutters className="text-center alert-danger">
                    <Col className="py-2 px-2">
                      <i
                        className="fa fa-exclamation-circle pr-2"
                        aria-hidden="true"
                      ></i>
                      {t('userValidation:submitError')}
                    </Col>
                  </Row>
                )}
                <Row className="pt-2">
                  <Col className="mb-4 text-center font-weight-bold">
                    {t('userValidation:verifyIdentity')}
                  </Col>
                </Row>
                {validationFields.map((name, idx) => (
                  <Row key={idx}>
                    <Col xs={1} sm={2} md={3} lg={4} />
                    <Col>
                      <GenerateKBV
                        t={t}
                        value={this.getValue(name)}
                        name={name}
                        className={utils.checkFieldValidation(name, validated)}
                        onChange={this.onChange}
                        onNumberChange={this.onNumberChange}
                        onDateChange={this.onDateChange}
                        onDateSelect={this.onDateSelect}
                        validated={validated}
                        parentObject={userValidationFields}
                      />
                    </Col>
                    <Col xs={1} sm={2} md={3} lg={4} />
                  </Row>
                ))}
                <Row>
                  <Col xs={1} sm={2} md={3} lg={4} />
                  <Col className="text-right">
                    <Button
                      className="mr-2"
                      color="link"
                      disabled={isSubmitting}
                      onClick={cancel}
                    >
                      {t('cancel')}
                    </Button>
                    <ActionButton
                      className="btn-primary"
                      onClick={this.checkValues}
                      onSubmitComplete={this.onSubmitComplete}
                      isLoading={isSubmitting}
                      submitSuccess={successfulSubmit}
                      submitError={validationError}
                    >
                      {t('submit')}
                    </ActionButton>
                  </Col>
                  <Col xs={1} sm={2} md={3} lg={4} />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </motion.div>
    );
  }
}

UserValidation.propTypes = {
  transferAgreement: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default UserValidation;
