import React from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import NumberFormat from 'react-number-format';

import { DateSelectorWithIcon } from '../../../../components';

import kbvMappings from './kbvMappings';

const TextInputField = ({ t, value, name, className, onChange }) => (
  <FormGroup>
    <Input
      type="text"
      value={value}
      className={className}
      name={name}
      onChange={onChange}
      required
    />
    <Label className="form-control-placeholder">{t(`kbv:${name}`)}</Label>
    <FormFeedback>{t(`formValidation:${name}`)}</FormFeedback>
  </FormGroup>
);

const NumberInputField = ({
  t,
  value,
  className,
  name,
  format,
  onNumberChange,
}) => (
  <FormGroup>
    <NumberFormat
      value={value}
      displayType="input"
      format={format}
      mask="_"
      allowNegative={false}
      isNumericString
      className={`form-control ${className}`}
      name={name}
      onBlur={onNumberChange}
      autoComplete="none"
      required
    />
    <Label className="form-control-placeholder">{t(`kbv:${name}`)}</Label>
    <FormFeedback>{t(`formValidation:${name}`)}</FormFeedback>
  </FormGroup>
);

const DateField = ({
  t,
  value,
  className,
  name,
  dateFormat,
  errorMessage,
  parentObject,
  validated,
  onDateChange,
  onDateSelect,
}) => (
  <DateSelectorWithIcon
    id={name}
    parentObject={parentObject}
    dateFormat={dateFormat}
    showYearDropdown
    dropdownMode="select"
    label={t(`kbv:${name}`)}
    defaultValue={value}
    name={name}
    validated={validated}
    actions={{ onDateChange, onDateSelect }}
  />
);

const functionMappings = {
  TextInputField,
  NumberInputField,
  DateField,
};

export default function GenerateKBV(props) {
  const field = kbvMappings[props.name];
  const FieldType = functionMappings[field.type];

  return <FieldType {...field} {...props} />;
}
