import React from 'react';

import { Card, Col, Row, Table } from 'reactstrap';

import { withTranslation, Trans } from 'react-i18next';

const Policy = (props) => {
  const { t } = props;
  return (
    <>
      <Row noGutters className="d-flex justify-content-center">
        <Col>
          <Card className="p-2 policy">
            <Row noGutters>
              <Col className="detail-header mb-2 font-weight-bold ml-2 ml-lg-3">
                <h1> {t('policy:policyHeader')} </h1>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="mx-md-2 mx-lg-3">
                <p>{t('policy:updatedDate')}</p>
                <Trans i18nKey="policy:useCase">
                  <p> </p>
                  <p>
                    {' '}
                    <a href="#changePolicy" className="no-underline-link">
                      {' '}
                    </a>{' '}
                  </p>
                  <p id="collectAndUse">
                    <span className="font-weight-bold underline-text"> </span>{' '}
                  </p>
                  <p> </p>
                  <ul>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                  </ul>
                  <p> </p>
                  <ul>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li>
                      {' '}
                      <a href="#stateSpecific" className="no-underline-link">
                        {' '}
                      </a>
                    </li>
                  </ul>
                  <p> </p>
                </Trans>
              </Col>
            </Row>
            <Row noGutters>
              <Col id="sharingInfo" className="mx-md-2 mx-lg-3">
                <Trans i18nKey="policy:shareInfo">
                  <span className="font-weight-bold underline-text"> </span>
                  <p className="mt-3"> </p>
                  <p> </p>
                  <p> </p>
                  <p>
                    {' '}
                    <a href="#stateSpecific" className="no-underline-link">
                      {' '}
                    </a>{' '}
                  </p>
                  <p> </p>
                </Trans>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="mx-md-2 mx-lg-3">
                <Trans i18nKey="policy:automaticData">
                  <span className="font-weight-bold underline-text"> </span>
                  <p className="mt-3"> </p>
                  <ul>
                    <li> </li>
                    <li> </li>
                  </ul>
                  <p> </p>
                  <ul>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                  </ul>
                  <p> </p>
                  <ul>
                    <li> </li>
                    <li> </li>
                  </ul>
                  <p> </p>
                </Trans>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="mx-md-2 mx-lg-3">
                <Trans i18nKey="policy:useInfo">
                  <span className="font-weight-bold underline-text"> </span>
                  <p className="mt-3"> </p>
                  <span className="font-weight-bold underline-text"> </span>
                  <p className="mt-3">
                    {' '}
                    <a
                      href="mailto:privacy@champtitles.com"
                      className="no-underline-link"
                    >
                      {' '}
                    </a>
                  </p>
                  <span className="font-weight-bold underline-text"> </span>
                  <p className="mt-3"> </p>
                  <p> </p>
                  <span className="font-weight-bold underline-text"> </span>
                  <p className="mt-3"> </p>
                  <span className="font-weight-bold underline-text"> </span>
                  <p className="mt-3">
                    {' '}
                    <a href="#changePolicy" className="no-underline-link">
                      {' '}
                    </a>{' '}
                  </p>
                </Trans>
              </Col>
            </Row>
            <Row noGutters>
              <Col id="changePolicy" className="mx-md-2 mx-lg-3">
                <Trans i18nKey="policy:changeAndUpdate">
                  <span className="font-weight-bold underline-text"> </span>
                  <p className="mt-3">
                    {' '}
                    <a
                      href="mailto:privacy@champtitles.com"
                      className="no-underline-link"
                    >
                      {' '}
                    </a>{' '}
                  </p>
                  <p>
                    {' '}
                    <a href="#stateSpecific" className="no-underline-link">
                      {' '}
                    </a>{' '}
                  </p>
                  <span className="font-weight-bold underline-text"> </span>
                  <p className="mt-3"> </p>
                </Trans>
              </Col>
            </Row>
            <Row noGutters>
              <Col id="stateSpecific" className="mx-md-2 mx-lg-3">
                <hr />
                <Trans i18nKey="policy:stateSpecificOne">
                  <span className="font-weight-bold"> </span>
                  <span className="mt-3 font-weight-bold"> </span>
                  <p className="mt-3"> </p>
                  <p> </p>
                  <p>
                    {' '}
                    <span className="mt-3 font-weight-bold underline-text">
                      {' '}
                    </span>
                  </p>
                  <p className="mt-3"> </p>
                </Trans>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="mx-md-2 mx-lg-3">
                <Trans i18nKey="policy:stateTableOne">
                  <Table bordered className="table-responsive">
                    <thead>
                      <tr className="text-center">
                        <th> </th>
                        <th> </th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                    </tbody>
                  </Table>
                </Trans>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="mx-md-2 mx-lg-3">
                <Trans i18nKey="policy:stateSpecificTwo">
                  <p> </p>
                  <p> </p>
                  <p> </p>
                  <p>
                    {' '}
                    <span className="mt-3 font-weight-bold underline-text">
                      {' '}
                    </span>
                  </p>
                  <p className="mt-3">
                    {' '}
                    <a href="#collectAndUse" className="no-underline-link">
                      {' '}
                    </a>
                  </p>
                  <p>
                    {' '}
                    <span className="mt-3 font-weight-bold underline-text">
                      {' '}
                    </span>
                  </p>
                  <p className="mt-3">
                    {' '}
                    <a href="#sharingInfo" className="no-underline-link">
                      {' '}
                    </a>{' '}
                  </p>
                </Trans>
              </Col>
            </Row>
            <Row noGutters className="dflex align-self-center">
              <Col className="mx-md-2 mx-lg-3">
                <Trans i18nKey="policy:stateTableTwo">
                  <Table bordered className="table-responsive">
                    <thead>
                      <tr>
                        <th> </th>
                        <th> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td> </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td> </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td> </td>
                      </tr>
                    </tbody>
                  </Table>
                </Trans>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="mx-md-2 mx-lg-3">
                <Trans i18nKey="policy:stateSpecificThree">
                  <p>
                    {' '}
                    <span className="mt-3 font-weight-bold underline-text">
                      {' '}
                    </span>
                  </p>
                  <p className="mt-3">
                    <span className="mt-3 font-weight-bold"> </span>{' '}
                    <a href="#sharingInfo" className="no-underline-link">
                      {' '}
                    </a>
                  </p>
                  <p>
                    {' '}
                    <span className="mt-3 font-weight-bold underline-text">
                      {' '}
                    </span>
                  </p>
                  <p className="mt-3"> </p>
                  <span className="mt-3 font-weight-bold font-italic"> </span>
                  <p className="mt-3">
                    {' '}
                    <a href="#accessDataRights" className="no-underline-link">
                      {' '}
                    </a>
                  </p>
                  <ul>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                  </ul>
                  <p> </p>
                  <ul>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                  </ul>
                </Trans>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="mx-md-2 mx-lg-3">
                <Trans i18nKey="policy:deletionRequests">
                  <span className="mt-3 font-weight-bold font-italic"> </span>
                  <p className="mt-3">
                    {' '}
                    <a href="#accessDataRights" className="no-underline-link">
                      {' '}
                    </a>{' '}
                  </p>
                  <p> </p>
                  <ul>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                  </ul>
                  <span
                    id="accessDataRights"
                    className="mt-3 font-weight-bold font-italic"
                  >
                    {' '}
                  </span>
                  <p className="mt-3"> </p>
                  <ul>
                    <li>
                      {' '}
                      <a
                        href="mailto:privacy@champtitles.com"
                        className="no-underline-link"
                      >
                        {' '}
                      </a>{' '}
                    </li>
                    <li> </li>
                  </ul>
                  <p> </p>
                  <p> </p>
                  <ul>
                    <li> </li>
                    <li> </li>
                  </ul>
                  <p> </p>
                  <p> </p>
                </Trans>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="mx-md-2 mx-lg-3">
                <Trans i18nKey="policy:stateSpecificFour">
                  <span className="font-weight-bold font-italic"> </span>
                  <p className="mt-3"> </p>
                  <p> </p>
                  <p> </p>
                  <span className="font-weight-bold font-italic"> </span>
                  <p className="mt-3"> </p>
                  <ul>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                    <li> </li>
                  </ul>
                  <p className="mt-3"> </p>
                  <span className="font-weight-bold font-italic"> </span>
                  <p className="mt-3">
                    {' '}
                    <a
                      href="mailto:privacy@champtitles.com"
                      className="no-underline-link"
                    >
                      {' '}
                    </a>{' '}
                  </p>
                </Trans>
              </Col>
            </Row>
            <Row noGutters>
              <Col className="mx-md-2 mx-lg-3">
                <p>{t('policy:footer')}</p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default withTranslation('policy')(Policy);
