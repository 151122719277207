import React from 'react';

import ProgressCircle from './ProgressCircle';

export default function RadialStepTracker(props) {
  const { currentStep, trackerSteps, t } = props;

  const totalSteps = trackerSteps.length;

  return (
    <div id="radialStepTracker" className="d-flex justify-content-center">
      <div className="d-inline-block position-relative progress-circle-container">
        <div className="center-absolute font-weight-bold">
          <span className="mr-1">{currentStep + 1}</span>
          <span className="mr-1">{t('of')}</span>
          <span>{totalSteps}</span>
        </div>
        <div className="center-absolute">
          <ProgressCircle currentStep={currentStep} totalSteps={totalSteps} />
        </div>
      </div>
      <div className="title-container">
        {currentStep < totalSteps && (
          <div className="current-step-title text-left font-weight-bold">
            {t(`${trackerSteps[currentStep].title}`)}
          </div>
        )}
        {currentStep < totalSteps - 1 && (
          <div className="next-step-title text-left">
            {t('next')}: {t(`${trackerSteps[currentStep + 1].title}`)}
          </div>
        )}
      </div>
    </div>
  );
}
