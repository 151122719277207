import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

class DocumentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      initialLoadError: false,
      haveALook: null,
      downloadData: null,
      viewImage: this.props.viewImage || false,
      relativeUrl: null,
      documentName: '',
      imageFileTypes: ['jpg', 'jpeg', 'png'],
    };

    this.auth = props.auth;
  }

  componentDidUpdate(prevProps) {
    const { viewImage, relativeUrl, documentName } = this.props;
    const {
      viewImage: viewImageState,
      relativeUrl: relativeUrlState,
      documentName: documentNameState,
    } = this.state;

    if (
      (viewImage && prevProps.viewImage !== viewImageState) ||
      (relativeUrl && prevProps.relativeUrl !== relativeUrlState) ||
      (documentName && prevProps.documentName !== documentNameState)
    ) {
      this.downloadDoc(relativeUrl, documentName);
    }
  }

  static getDerivedStateFromProps(props, state) {
    return props.viewImage !== state.viewImage ||
      props.relativeUrl !== state.relativeUrl ||
      props.documentName !== state.documentName
      ? {
          viewImage: props.viewImage,
          relativeUrl: props.relativeUrl,
          documentName: props.documentName,
        }
      : null;
  }

  getImageTypeIfExists = (type) => {
    const { imageFileTypes } = this.state;
    return imageFileTypes.find((imageType) => type.indexOf(imageType) > 0);
  };

  downloadDoc = (relativeUrl, name) => {
    const { attachmentType, handleError } = this.props;
    const imageType = this.getImageTypeIfExists(attachmentType);

    if (imageType) {
      this.auth
        .fetchImage(relativeUrl)
        .then((res) => {
          this.setState({
            haveALook: {
              data: res.data,
              name: `${attachmentType}.${imageType}`,
              type: res.type,
            },
            viewImage: true,
          });
        })
        .catch((err) => {
          handleError('dataError');
        });
    } else {
      this.auth
        .fetchPDF(relativeUrl)
        .then((res) => {
          this.viewDoc(res, name, 'application/pdf');
        })
        .catch((err) => {
          handleError('dataError');
        });
    }
  };

  viewDoc = (blob, name, type) => {
    const img = new Blob([blob], { type });
    if (window.navigator.msSaveOrOpenBlob) {
      if (type.includes('pdf')) {
        window.navigator.msSaveOrOpenBlob(img, `${name}.pdf`);
        this.props.closeModal();
      }
    } else {
      const data = blob && URL.createObjectURL(img);

      if (window.navigator.userAgent.match(/Android|webOS|Blackberry/i)) {
        window.open(data, '_blank');
        this.props.closeModal();
      } else if (window.navigator.userAgent.match(/iPhone|iPad/i)) {
        window.open(data, '_self');
        this.props.closeModal();
      } else {
        this.setState({
          haveALook: {
            data,
            name: `${name}.pdf`,
            type,
          },
          downloadData: {
            blob,
            name,
            type,
          },
          viewImage: true,
          relativeUrl: null,
        });
      }
    }
  };

  toggleImage = () => {
    const { closeModal } = this.props;
    const { haveALook } = this.state;
    if (haveALook && haveALook.data) {
      URL.revokeObjectURL(haveALook.data);
    }

    this.setState({
      haveALook: null,
      downloadData: null,
      viewImage: false,
    });
    closeModal();
  };

  render() {
    const { t } = this.props;
    const { haveALook, viewImage } = this.state;

    return (
      <div id="documentModal">
        <Modal
          isOpen={viewImage}
          toggle={this.toggleImage}
          className="modal-dark document-modal"
        >
          <ModalHeader toggle={this.toggleImage} />
          <ModalBody className="document-modal-body">
            <div className="document-container">
              {haveALook && (
                <object
                  alt={haveALook.name}
                  className="document-object"
                  data={haveALook.data}
                  type={haveALook.type}
                  name={haveALook.name}
                >
                  <div className="text-center viewing-error">
                    {t('common:documentViewError')}
                  </div>
                </object>
              )}
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

DocumentModal.propTypes = {
  viewImage: PropTypes.bool,
  relativeUrl: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  attachmentType: PropTypes.string.isRequired,
};

export default DocumentModal;
