import React from 'react';
import Tile from './Tile';

export default function TileContainer({ children }) {
  return (
    <div className="tile-container">
      {children.map((child, idx) => (
        <Tile key={idx}>{child}</Tile>
      ))}
    </div>
  );
}
