import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { utils } from '@champtitles/utilities';
import { Document, Page, pdfjs } from 'react-pdf';
import fetchWrapper from '../../../security/fetchWrapper';
import { ActionButton, LoadingSpinner } from '../../../hooks/components';
import { getStateConfig } from '../../../state-configs/GetStateConfig';
import getShippingCarrierInfo from '../../../state-configs/shippingCarriers';
import warning from '../../../assets/icons/warning.png';

const MailDocuments = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const { t, cancel, updateStep, previousStep, transferAgreement, auth } =
    props;
  const [documentDownloaded, setDocumentDownloaded] = useState(false);
  const [documentUrl, setDocumentUrl] = useState('');

  const nextStep = () => {
    updateStep();
  };

  const previous = () => {
    previousStep();
  };

  const getValue = (path) => utils.getValue(path, transferAgreement);

  const stateConfig = getStateConfig(getValue('stateCode'));
  const { lastModifiedDate } = transferAgreement;
  const ownerShippingLabel = transferAgreement.attachments?.find(
    (attachment) => attachment.attachmentType === 'OWNER_SHIPPING_LABEL',
  );

  const shippingCarrierInfo = getShippingCarrierInfo(
    transferAgreement.insurer.preferredShippingCarrier,
  );

  const downloadShippingLabel = useCallback(async () => {
    try {
      // fetch the document
      const api = `/transferagreements/${transferAgreement.id}/attachments/${ownerShippingLabel.id}/download`;
      const headers = { Authorization: `Bearer ${auth.keycloak.token}` };
      const options = { method: 'GET' };
      const response = await fetchWrapper(api, options, headers);

      // generate the file
      const blob = await response.blob();
      const fileURL = URL.createObjectURL(
        new Blob([blob], { type: 'application/pdf' }),
      );

      setDocumentUrl(fileURL);
    } catch (e) {
      Promise.reject(e);
    }
  }, [transferAgreement.id, ownerShippingLabel.id, auth.keycloak.token]);

  const openPrintPreview = () => {
    window.open(documentUrl, '');

    setDocumentDownloaded(true);
  };

  let dueDate;
  if (!lastModifiedDate) {
    dueDate = moment(new Date()).add(2).format('LL').toUpperCase();
  } else {
    dueDate = moment(new Date(lastModifiedDate))
      .add(2, 'days')
      .format('LL')
      .toUpperCase();
  }

  const DocumentPlaceholder = () => (
    <div style={{ height: '450px', width: '595px' }}>
      <LoadingSpinner isLoading />
    </div>
  );

  useEffect(() => {
    if (!documentUrl) {
      downloadShippingLabel();
    }
  }, [downloadShippingLabel, documentUrl]);

  return (
    <div id="uploadContainer">
      <Row>
        <Col sm="12" md={{ size: 8, offset: 2 }}>
          <Card>
            <CardBody>
              <div className="detail-header font-weight-bold mb-2">
                {t('mailDocuments:header')}
              </div>
              <div className="mb-2 font-weight-bold">
                {t('mailDocuments:mailWarning')}
                <span style={{ fontStyle: 'italic' }}>
                  {t('mailDocuments:failureWarning')}
                </span>
              </div>
              <Row className="warning-container">
                <Col>
                  <div>
                    <img className="warning-icon" src={warning} alt="" />
                    <Trans
                      i18nKey="mailDocuments:documentMailByDate"
                      values={{ dueDate }}
                    >
                      <b> </b>
                      <span className="important-text"> </span>
                    </Trans>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="ml-lg-5" md="5">
                  <Trans i18nKey="mailDocuments:instructions:instructionHeader">
                    <b> </b>
                  </Trans>
                  <br />
                  <Trans i18nKey="mailDocuments:instructions:instructionSubHeader" />
                  <ol className="pl-sm-3 mail-instructions">
                    <li key={1}>
                      <Trans i18nKey="mailDocuments:instructions:doNotWriteOnTitle">
                        <span className="intensify-label"> </span>
                      </Trans>
                    </li>
                    <li key={2}>
                      <Trans i18nKey="mailDocuments:instructions:doNotFoldTitle">
                        <span className="intensify-label"> </span>
                      </Trans>
                    </li>
                    <li key={3}>
                      {t('mailDocuments:instructions:putTitleInEnvelope')}
                    </li>
                    <li key={4}>
                      {t('mailDocuments:instructions:printShippingLabel')}
                    </li>
                    <li key={5}>
                      {t('mailDocuments:instructions:attachShippingLabel')}
                    </li>
                    <li key={6}>
                      {t('mailDocuments:instructions:mailEnvelope1')}
                      <img
                        className="inline-branding-logo"
                        src={shippingCarrierInfo.logo}
                        alt=""
                      />
                      <Trans i18nKey="mailDocuments:instructions:mailEnvelope2" />
                      <a
                        href={shippingCarrierInfo.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-secondary"
                      >
                        {shippingCarrierInfo.url}
                      </a>
                      .
                    </li>
                  </ol>
                </Col>
                <Col className="d-flex flex-column align-items-center">
                  <img
                    src={stateConfig.sampleDocuments.titleFront}
                    alt=""
                    className="img-fluid image-col"
                  />
                  <div style={{ textAlign: 'center' }}>
                    <span className="img-sub-header">
                      {t('mailDocuments:sampleTitle')}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Button
                  color="light"
                  id="downloadButton"
                  className="m-2 image-action-button"
                  href={documentUrl}
                  download
                  onClick={() => setDocumentDownloaded(true)}
                  disabled={!documentUrl}
                >
                  Download
                </Button>
                <Button
                  color="light"
                  id="printButton"
                  className="m-2 image-action-button"
                  onClick={() => openPrintPreview()}
                  disabled={!documentUrl}
                >
                  Print
                </Button>
              </Row>
              <Row className="justify-content-center">
                <Card>
                  <CardBody
                    style={{
                      maxHeight: '500px',
                      display: 'flex',
                      flexShrink: 1,
                    }}
                    className="document-card"
                  >
                    <Col
                      style={{
                        overflow: 'scroll',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                      }}
                    >
                      <Document
                        file={documentUrl}
                        loading={<DocumentPlaceholder />}
                        noData={<DocumentPlaceholder />}
                      >
                        <Page pageNumber={1} />
                      </Document>
                    </Col>
                  </CardBody>
                </Card>
              </Row>
              <div className="text-center font-italic">
                <small>{t('mailDocuments:mustDownloadOrPrint')}</small>
              </div>
              <Row className="mt-2">
                <Col className="text-center">
                  <Button color="link" onClick={cancel}>
                    {t('buttons:cancel')}
                  </Button>
                  <Button color="secondary" className="m-2" onClick={previous}>
                    {t('buttons:previousStep')}
                  </Button>
                  <ActionButton
                    className="btn-primary"
                    onClick={nextStep}
                    disabled={!documentDownloaded}
                  >
                    {t('buttons:continue')}
                  </ActionButton>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

MailDocuments.propTypes = {
  t: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  updateStep: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  transferAgreement: PropTypes.shape({
    lastModifiedDate: PropTypes.string.isRequired,
    attachments: PropTypes.arrayOf(PropTypes.object),
    find: PropTypes.func,
  }),
  auth: PropTypes.shape({
    fetchImage: PropTypes.func.isRequired,
  }).isRequired,
};

MailDocuments.defaultProps = {
  transferAgreement: {
    lastModifiedDate: '',
  },
};
export default MailDocuments;
