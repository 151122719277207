import React, { Component, Fragment } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ActionButton } from '../../hooks/components';

class ActionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.isOpen || false,
    };
  }

  getBodyStyle = () => {
    return {
      width: '1000px !important',
      overflowY: this.props.overflowY ? this.props.overflowY : 'visible',
      maxHeight: this.props.maxHeight ? this.props.maxHeight : 'none',
    };
  };

  handleKeyPress = (e) => {
    if (
      [27].includes(e.keyCode) ||
      [27].includes(e.which) ||
      [27].includes(e.code)
    ) {
      if (this.props.cancel) this.props.cancel();
    }
  };

  static getDerivedStateFromProps(props, state) {
    return props.isOpen !== state.isOpen ? { isOpen: props.isOpen } : null;
  }

  render() {
    const {
      isOpen,
      className,
      header,
      label,
      cancel,
      submit,
      isSubmitting,
      bodyOnly,
      t,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.handleKeyPress}
        className={className}
        autoFocus={true}
      >
        {!bodyOnly && <ModalHeader>{header}</ModalHeader>}
        <ModalBody style={this.getBodyStyle()} className="action-modal-body">
          {this.props.children}
        </ModalBody>
        {!bodyOnly && (
          <ModalFooter>
            <Fragment>
              <motion.button
                className="btn btn-link mr-2 animated-scale"
                onClick={cancel}
                disabled={isSubmitting}
                whileHover={{ scale: 1.1 }}
              >
                {t('buttons:cancel')}
              </motion.button>
              {submit && (
                <ActionButton
                  className="btn btn-primary"
                  onClick={submit}
                  isLoading={isSubmitting}
                >
                  {label}
                </ActionButton>
              )}
            </Fragment>
          </ModalFooter>
        )}
      </Modal>
    );
  }
}

ActionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  header: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
  cancel: PropTypes.func,
  submit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default ActionModal;
