import { API_ROOT } from '../config';

const fetchWrapper = async (relativeUrl, options, headers = {}) => {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers,
  };

  const url = `${API_ROOT}${relativeUrl}`;

  const response = await fetch(url, {
    headers: defaultHeaders,
    ...options,
  });

  return response;
};

export default fetchWrapper;
