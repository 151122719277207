import React from 'react';
import { motion } from 'framer-motion';

import { success } from './responseAnimations';
import css from './responseAnimationsCss.scss';

const { variants, actions } = success;

export default function Error(props) {
  return (
    <motion.div {...actions} variants={variants.mainContainer}>
      <div
        className="text-center mb-3 response-header-label"
        style={{ color: css.danger }}
      >
        {props.label}
      </div>
      <div className="animated-checkbox">
        <svg
          className={'cross ' + props.className}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            className="cross__circle"
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />
          <path
            className="cross__x"
            stroke={css.danger}
            strokeDasharray="48"
            fill="none"
            d="M18.5,18.5 L34.5,34.5"
          />
          <path
            className="cross__x"
            stroke={css.danger}
            strokeDasharray="48"
            fill="none"
            d="M34.5,18.5 L18.5,34.5"
          />
        </svg>
      </div>
      {props.children}
    </motion.div>
  );
}
