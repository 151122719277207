import front from '../assets/SampleImages/WITitleFront.png';
import back from '../assets/SampleImages/WITitleBack.png';

const ShownDocuments = [
  'OWNER_SHIPPING_LABEL',
  'POWER_OF_ATTORNEY',
  'SALVAGE_AUTHORIZATION',
];

const SampleDocuments = {
  titleFront: front,
  titleBack: back,
};

export default {
  surrenderState: true,
  shownDocuments: ShownDocuments,
  sampleDocuments: SampleDocuments,
};
