import front from '../assets/SampleImages/TXTitleFront.png';

const ShownDocuments = ['POWER_OF_ATTORNEY', 'OWNER_SHIPPING_LABEL'];

const SampleDocuments = {
  titleFront: front,
};

export default {
  surrenderState: true,
  shownDocuments: ShownDocuments,
  sampleDocuments: SampleDocuments,
};
