import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { Card, CardBody, Col, Row } from 'reactstrap';

import { withTranslation } from 'react-i18next';

import { ResponseAnimations, utils } from '../../../components';

import { ownerStepTransition } from '../pendingRequestsPageAnimations';

const { actions, variants } = ownerStepTransition;

class RequestSubmitted extends Component {
  getValue = (path) => utils.getValue(path, this.props.transferAgreement);

  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const { t } = this.props;

    return (
      <motion.div
        id="requestSubmitted"
        {...actions}
        variants={variants.mainContainer}
      >
        <Row
          noGutters
          className="step-card-container d-flex justify-content-center"
        >
          <Col className="step-card">
            <Card>
              <CardBody>
                <Row>
                  <Col sm={0} lg={1} />
                  <Col>
                    <Row className="submitted-status my-4">
                      <Col className="d-flex">
                        <span className="mr-3">{t('common:status')}:</span>
                        <span className="submitted-checkmark">
                          <ResponseAnimations.Checkmark
                            {...this.props}
                            className="submitted-svg"
                          />
                        </span>
                        <span className="text-primary ml-2">
                          {t('common:completed')}
                        </span>
                      </Col>
                    </Row>
                    <Row className="my-4">
                      <Col>
                        <div>{t('inReviewMsg')}</div>
                      </Col>
                    </Row>
                    <Row className="my-4">
                      <Col>
                        {this.getValue('multiOwner') ? (
                          <div className="print-msg">
                            {t('documentsMsgMultiOwner')}
                          </div>
                        ) : (
                          <div className="print-msg">{t('documentsMsg')}</div>
                        )}
                      </Col>
                    </Row>
                    <Row className="text-right">
                      <Col>
                        <motion.button
                          className="btn btn-secondary px-5"
                          onClick={this.props.cancel}
                        >
                          {t('common:done')}
                        </motion.button>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={0} lg={1} />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </motion.div>
    );
  }
}

RequestSubmitted.propTypes = {
  cancel: PropTypes.func.isRequired,
};

export default withTranslation('requestSubmitted')(RequestSubmitted);
